import { AfterViewInit, Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { DeliveryChallanService } from 'app/_services/delivery-challan.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'app/auth/service';
import { DealersService } from 'app/_services/dealers.service';

@Component({
  selector: 'app-delivery-challan-add',
  templateUrl: './delivery-challan-add.component.html',
  styleUrls: ['./delivery-challan-add.component.scss']
})
export class DeliveryChallanAddComponent implements OnInit {

  @ViewChild('auto') auto: any; 

  public loading = false;
  public error = '';
  public newForm: UntypedFormGroup;
  public submitted = false;
  public keyword = 'name';
  public historyHeading: string = 'Recently selected';
  public isLoading: boolean = false;
  public allDealer: any;
  public dealerId: any = 0;
  public dealerName: any = '';
  public order_details: any = [];
  public orderDeliveryOrder: any = [];
  public dispatchOrder: any = [];
  public dispatchProductsOrder: any = [];
  public lr_form: boolean = false;
  public set_order: boolean = true;
  public dispatchProductsFinalOrder: any = [];
  public dispatchProductsNewFinalOrder: any = [];
  public pusheditems: any = [];
  public dispatchProductsFinalOrder_submit: any = [];
  public dispatchOrder_submit: any = [];
  public dispatchProductsNewFinalOrder_challan: any = [];
  public grand_total: any = 0.00;
  public main_discount: any = 0.00;
  public tax_one_amt: any = 0.00;
  public tax_two_amt: any = 0.00;

  public transport_charge: any = 0.00;
  public transport_charge_amt: any = 0.00;
  public packing_charge: any = 0.00;
  public packing_charge_amt: any = 0.00;
  public extra_charge: any = 0.00;
  public extra_charge_amt: any = 0.00;

  public round_off: any = 0.00;
  public viewDealerName: boolean = false;
  public isAddMode: boolean = true;
  public loadingViewDealerName: boolean = true;
  public transportDropDown: any = [];

  constructor(private _authenticationService: AuthenticationService, private http: HttpClient, private deliveryChallanService: DeliveryChallanService, private _toastrService: ToastrService, private _router: Router, private route: ActivatedRoute, private _formBuilder: UntypedFormBuilder, private dealersService: DealersService) { }

  ngOnInit(): void {

    if (this.route.snapshot.params['id'] !== undefined) {
      this.dealerId = this.route.snapshot.params['id'];
    }
    this.isAddMode = !this.dealerId;
    if (!this.isAddMode) {
      this.getPreCustomer(this.dealerId);
      this.viewDealerName = true;
    }
    
    this.getTransportDropDown();

    this.newForm = this._formBuilder.group({
      lr_no: [''],
      transport_id: [''],
      transport_name: [''],
      mobile_no: [''],
      vehicle_no: [''],
      remarks: [''],
      delivery_details: [''],
      main_discount: [0],
      tax_one: [''],
      tax_one_pre: [0],
      tax_two: [''],
      tax_two_pre: [0],
      transport_charge: [0],
      packing_charge: [0],
      extra_charge: [0],
      transport_charge_label: [''],
      packing_charge_label: [''],
      extra_charge_label: [''],
    });
  }

  onChangeSearch(value: string) {
    this.isLoading = true
    this.deliveryChallanService.dealerSearch(value).subscribe(
      data => {
        if (data.status) {
          this.isLoading = false
          this.allDealer = data.dealer_data;
        }
        else {
          this.isLoading = false;
          //this._toastrService.error(data.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
          this.allDealer = [];
          this.orderDeliveryOrder = [];
          this.dispatchOrder = [];
          this.dispatchProductsOrder = [];
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
        this._toastrService.clear();
        this._toastrService.error(err.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        this.allDealer = [];
        this.orderDeliveryOrder = [];
        this.dispatchOrder = [];
        this.dispatchProductsOrder = [];
      }
    );
  }

  selectEvent(item: any) {
    this.dealerId = item.id;
    this.dealerName = item.name;
    this.getRemainingOrder(item.id);
  }

  onFocused(e: any) {
    // do something when input is focused
  }

  clear(): void {
    this.auto.clear();
  }

  getRemainingOrder(orderId: any) {

    this.order_details = [];
    this.orderDeliveryOrder = [];
    this.dispatchOrder = [];
    this.dispatchProductsOrder = [];
    this.dispatchProductsFinalOrder = [];
    this.dispatchProductsNewFinalOrder = [];
    this.pusheditems = [];
    this.dispatchProductsFinalOrder_submit = [];
    this.dispatchOrder_submit = [];
    this.dispatchProductsNewFinalOrder_challan = [];

    this.deliveryChallanService.getDeliveryOrder(orderId).subscribe(
      data => {
        if (data.status) {
          if (data.order.length > 0) {
            this.orderDeliveryOrder = data.order;
            for (var i = 0; i < data.order.length; i++) {
              for (var j = 0; j < data.order[i].order_details.length; j++) {
                this.dispatchOrder = [];
                this.dispatchOrder['order_id'] = data.order[i].order_details[j].order_id;
                this.dispatchOrder['order_meta_id'] = data.order[i].order_details[j].id;
                this.dispatchOrder['product_id'] = data.order[i].order_details[j].product_id;
                this.dispatchOrder['product_variant_id'] = data.order[i].order_details[j].product_variant_id;
                this.dispatchOrder['product_code'] = data.order[i].order_details[j].product_code;
                this.dispatchOrder['product_name'] = data.order[i].order_details[j].product_name;
                this.dispatchOrder['size'] = data.order[i].order_details[j].size;
                this.dispatchOrder['color'] = data.order[i].order_details[j].color;
                this.dispatchOrder['unit'] = data.order[i].order_details[j].unit;
                this.dispatchOrder['order_qty'] = data.order[i].order_details[j].order_qty;
                this.dispatchOrder['dispatch_qty'] = data.order[i].order_details[j].dispatch_qty;
                if (data.order[i].order_details[j].discount > 0) {
                  this.dispatchOrder['sales_price'] = data.order[i].order_details[j].sales_price - ((data.order[i].order_details[j].sales_price * data.order[i].order_details[j].discount) / 100)
                }
                else {
                  this.dispatchOrder['sales_price'] = data.order[i].order_details[j].sales_price;
                }

                this.dispatchOrder['old_sales_price'] = data.order[i].order_details[j].sales_price;
                this.dispatchOrder['discount'] = data.order[i].order_details[j].discount;
                this.dispatchOrder['sub_total'] = data.order[i].order_details[j].sub_total;
                this.dispatchOrder['invoice_no'] = data.order[i].invoice_no;
                this.dispatchOrder['display_price'] = data.order[i].order_details[j].sales_price - ((data.order[i].order_details[j].sales_price * data.order[i].order_details[j].discount) / 100);

                this.dispatchProductsOrder.push(this.dispatchOrder);
              }
            }
          }
          else {
            this.allDealer = [];
            this.orderDeliveryOrder = [];
            this.dispatchOrder = [];
            this.dispatchProductsOrder = [];
            this._toastrService.clear();
            this._toastrService.warning('No Pending Order!', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
        }
        else {
          this._toastrService.clear();
          this._toastrService.error(data.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
        this._toastrService.clear();
        this._toastrService.error(err.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }

  countChange(qty: any, orderId: any, metaId: any) {

    this.dispatchProductsOrder.forEach((value: any, index: any) => {
      if (value.order_meta_id == metaId) {
        this.dispatchProductsOrder[index]['dispatch_qty'] = parseFloat(qty);
      }
    });

    for (var i = 0; i < this.orderDeliveryOrder.length; i++) {
      for (var j = 0; j < this.orderDeliveryOrder[i].order_details.length; j++) {
        if (this.orderDeliveryOrder[i].order_details[j].id == metaId) {
          this.orderDeliveryOrder[i].order_details[j].dispatch_qty = parseFloat(qty);
          break;
        }
      }
    }
  }

  previewDispatchProducts() {

    this.dispatchProductsFinalOrder = [];
    let totalDispatch_qty = 0;
    this.dispatchProductsOrder.forEach((values: any, index: any) => {
      if (values.dispatch_qty > 0) {
        this.dispatchProductsOrder[index].sub_total = this.dispatchProductsOrder[index].display_price * this.dispatchProductsOrder[index].dispatch_qty;
        this.dispatchProductsFinalOrder.push(this.dispatchProductsOrder[index]);
        totalDispatch_qty += values.dispatch_qty;
      }
    });

    if (totalDispatch_qty == 0) {
      this._toastrService.clear();
      this._toastrService.warning('Select Dispatch Quantity At Least One', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });

    } else {

      this.dispatchProductsNewFinalOrder = [];
      this.pusheditems = [];
      this.dispatchProductsFinalOrder.forEach((value: any, index: any) => {
        if (this.pusheditems[value.product_id] !== undefined) {
          this.pusheditems[value.product_id]['dispatch_qty'] = parseFloat(this.pusheditems[value.product_id]['dispatch_qty']) + parseFloat(value.dispatch_qty);
          this.pusheditems[value.product_id]['sub_total'] = parseFloat(this.pusheditems[value.product_id]['sub_total']) + parseFloat(value.sub_total);
        } else {
          this.pusheditems[value.product_id] = value;
          this.dispatchProductsNewFinalOrder.push(this.pusheditems[value.product_id]);
        }
      });

      this.lr_form = true;
      this.set_order = false;
    }

  }
  backDispatchProducts() {
    this.dispatchProductsOrder = [];
    for (var i = 0; i < this.orderDeliveryOrder.length; i++) {
      for (var j = 0; j < this.orderDeliveryOrder[i].order_details.length; j++) {
        this.dispatchOrder = [];
        this.dispatchOrder['order_id'] = this.orderDeliveryOrder[i].order_details[j].order_id;
        this.dispatchOrder['order_meta_id'] = this.orderDeliveryOrder[i].order_details[j].id;
        this.dispatchOrder['product_id'] = this.orderDeliveryOrder[i].order_details[j].product_id;
        this.dispatchOrder['product_variant_id'] = this.orderDeliveryOrder[i].order_details[j].product_variant_id;
        this.dispatchOrder['product_code'] = this.orderDeliveryOrder[i].order_details[j].product_code;
        this.dispatchOrder['product_name'] = this.orderDeliveryOrder[i].order_details[j].product_name;
        this.dispatchOrder['size'] = this.orderDeliveryOrder[i].order_details[j].size;
        this.dispatchOrder['color'] = this.orderDeliveryOrder[i].order_details[j].color;
        this.dispatchOrder['unit'] = this.orderDeliveryOrder[i].order_details[j].unit;
        this.dispatchOrder['order_qty'] = this.orderDeliveryOrder[i].order_details[j].order_qty;
        this.dispatchOrder['dispatch_qty'] = this.orderDeliveryOrder[i].order_details[j].dispatch_qty;
        this.dispatchOrder['sales_price'] = this.orderDeliveryOrder[i].order_details[j].sales_price;
        this.dispatchOrder['old_sales_price'] = this.orderDeliveryOrder[i].order_details[j].old_sales_price;
        this.dispatchOrder['discount'] = this.orderDeliveryOrder[i].order_details[j].discount;
        this.dispatchOrder['sub_total'] = this.orderDeliveryOrder[i].order_details[j].sub_total;
        this.dispatchOrder['invoice_no'] = this.orderDeliveryOrder[i].invoice_no;
        this.dispatchOrder['display_price'] = this.orderDeliveryOrder[i].order_details[j].sales_price - ((this.orderDeliveryOrder[i].order_details[j].sales_price * this.orderDeliveryOrder[i].order_details[j].discount) / 100);
        this.dispatchProductsOrder.push(this.dispatchOrder);
      }
    }

    this.dispatchProductsFinalOrder = [];
    this.dispatchProductsNewFinalOrder = [];
    this.pusheditems = [];

    this.lr_form = false;
    this.set_order = true;
  }

  get f() {
    return this.newForm.controls;
  }

  /**
  * On Submit
  */
  onSubmit() {
    this.submitted = true;
    this.loading = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {

    }
    else {
      //  this.loading = true;
      this.dispatchProductsFinalOrder_submit = [];
      this.orderDeliveryOrder.forEach((value: any, index: any) => {

        value.order_details.forEach((meta_value: any, meta_index: any) => {
          if (meta_value.dispatch_qty > 0) {
            this.dispatchOrder_submit = [];
            this.dispatchOrder_submit['meta_id'] = meta_value.id;
            this.dispatchOrder_submit['order_id'] = meta_value.order_id;
            this.dispatchOrder_submit['product_id'] = meta_value.product_id;
            this.dispatchOrder_submit['product_variant_id'] = meta_value.product_variant_id;
            this.dispatchOrder_submit['dispatch_qty'] = meta_value.dispatch_qty;
            this.dispatchOrder_submit['sales_price'] = meta_value.sales_price;
            this.dispatchOrder_submit['display_price'] = meta_value.display_price;
            this.dispatchOrder_submit['sub_total'] = meta_value.sub_total;
            this.dispatchProductsFinalOrder_submit.push(this.dispatchOrder_submit);
          }
        });
      });

      this.dispatchProductsNewFinalOrder.forEach((challan_value: any, challan_index: any) => {
        this.dispatchOrder_submit = [];
        this.dispatchOrder_submit['order_id'] = challan_value.order_id;
        this.dispatchOrder_submit['product_id'] = challan_value.product_id;
        this.dispatchOrder_submit['product_variant_id'] = challan_value.product_variant_id;
        this.dispatchOrder_submit['dispatch_qty'] = challan_value.dispatch_qty;
        this.dispatchOrder_submit['dispatch_price'] = challan_value.sales_price;
        this.dispatchOrder_submit['dispatch_discount'] = challan_value.discount;
        this.dispatchOrder_submit['dispatch_sub_total'] = challan_value.sub_total;
        this.dispatchProductsNewFinalOrder_challan.push(this.dispatchOrder_submit);
      });

      let challanProductsArray: any = [];
      this.dispatchProductsNewFinalOrder_challan.forEach((value: any, index: any) => {

        challanProductsArray.push({
          'order_id': value.order_id,
          'meta_id': value.meta_id,
          'product_id': value.product_id,
          'product_variant_id': value.product_variant_id,
          'dispatch_qty': value.dispatch_qty,
          'dispatch_price': value.dispatch_price,
          'dispatch_discount': value.dispatch_discount,
          'dispatch_sub_total': value.dispatch_sub_total
        });

      });

      let orderProductsArray: any = [];
      this.dispatchProductsFinalOrder_submit.forEach((value: any, index: any) => {
        orderProductsArray.push({
          'meta_id': value.meta_id,
          'product_id': value.product_id,
          'product_variant_id': value.product_variant_id,
          'dispatch_qty': value.dispatch_qty,
          'sales_price': value.sales_price,
          'display_price': value.display_price,
          'discount': 0,
          'sub_total': value.sub_total
        });
      });

      const formdata = new FormData();
      formdata.append('lr_no', this.f.lr_no.value);
      formdata.append('transport_id', this.f.transport_id.value);
      formdata.append('transport_name', this.f.transport_name.value);
      formdata.append('mobile_no', this.f.mobile_no.value);
      formdata.append('vehicle_no', this.f.vehicle_no.value);
      formdata.append('remarks', this.f.remarks.value);
      formdata.append('delivery_details', JSON.stringify(challanProductsArray));
      formdata.append('order_details', JSON.stringify(orderProductsArray));
      formdata.append('main_discount', this.f.main_discount.value);
      formdata.append('tax_one', this.f.tax_one.value);
      formdata.append('tax_one_pre', this.f.tax_one_pre.value);
      formdata.append('tax_two', this.f.tax_two.value);
      formdata.append('tax_two_pre', this.f.tax_two_pre.value);
      
      formdata.append('transport_charge', this.f.transport_charge.value);
      formdata.append('packing_charge', this.f.packing_charge.value);
      formdata.append('extra_charge', this.f.extra_charge.value);
      
      formdata.append('transport_charge_label', this.f.transport_charge_label.value);
      formdata.append('packing_charge_label', this.f.packing_charge_label.value);
      formdata.append('extra_charge_label', this.f.extra_charge_label.value);

      this.deliveryChallanService.add(formdata).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.submitted = false;
            this.newForm.reset();
            this._toastrService.clear();
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this._router.navigate(['/delivery-challan']);
          }
          else {
            this.error = data.error;
          }
        },
        err => {
          this.loading = true;
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });

      this.submitted = false;
    }
  }

  priceChange(event: any, id: any, key: any) {
    if (event.target.value < 0) {
      this.dispatchProductsNewFinalOrder[key].sales_price = 0;
      this.dispatchProductsNewFinalOrder[key].sub_total = 0;
      this._toastrService.clear();
      this._toastrService.warning('Rate must be 0 (zero) or more than 0 (zero)', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
    }
    else {
      this.dispatchProductsNewFinalOrder[key].sales_price = event.target.value;
      this.dispatchProductsNewFinalOrder[key].sub_total = event.target.value * this.dispatchProductsNewFinalOrder[key].dispatch_qty;
    }
  }

  getSum(index: any): number {
    let sum = 0;
    for (let i = 0; i < this.dispatchProductsNewFinalOrder.length; i++) {
      sum += this.dispatchProductsNewFinalOrder[i][index];
    }
    return sum;
  }

  getGrandTotal() {
    let sub_total = this.getSum('sub_total');
    this.round_off = Math.round((sub_total - this.main_discount) + this.tax_one_amt + this.tax_two_amt + this.transport_charge_amt + this.packing_charge_amt  + this.extra_charge_amt) - ((sub_total - this.main_discount) + this.tax_one_amt + this.tax_two_amt + this.transport_charge_amt + this.packing_charge_amt  + this.extra_charge_amt);
    return Math.round((sub_total - this.main_discount) + this.tax_one_amt + this.tax_two_amt + this.transport_charge_amt + this.packing_charge_amt  + this.extra_charge_amt);
  }

  totalCalc(e, me: any = '') {

    const charCode = e.which ? e.which : e.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105)) {
      if (charCode != 110) {
        if (me == "main_discount") {
          this.f.main_discount.setValue(0);
        }
        if (me == "tax_one_pre") {
          this.f.tax_one_pre.setValue(0);
        }
        if (me == "tax_two_pre") {
          this.f.tax_two_pre.setValue(0);
        }
        if (me == "transport_charge") {
          this.f.transport_charge_amt.setValue(0);
        }
        if (me == "packing_charge") {
          this.f.packing_charge_amt.setValue(0);
        }
        if (me == "extra_charge") {
          this.f.extra_charge_amt.setValue(0);
        }
        this._toastrService.clear();
        this._toastrService.warning('OOPs! Only numeric values or digits allowed', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    }


    /* Start For Main Dicount */
    if (this.f.main_discount.value > 0) {
      let sub_total = this.getSum('sub_total');
      let dicount_per = this.f.main_discount.value;
      this.main_discount = (sub_total * dicount_per) / 100;
    }
    else if (this.f.main_discount.value < 0) {
      this.f.main_discount.setValue(0);
      this._toastrService.clear();
      this._toastrService.warning('Discount must be 0 (zero) or more than 0 (zero)', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      this.main_discount = 0;
    }
    else {
      this.main_discount = 0;
    }
    /* END For Main Dicount */

    /* Start For Main Tax One */
    if (this.f.tax_one_pre.value > 0) {
      let sub_total = this.getSum('sub_total') - this.main_discount;
      let tax_one_pre = this.f.tax_one_pre.value;
      this.tax_one_amt = ((sub_total * tax_one_pre) / 100);
    }
    else if (this.f.tax_one_pre.value < 0) {
      this.f.tax_one_pre.setValue(0);
      this._toastrService.clear();
      this._toastrService.warning('Tax must be 0 (zero) or more than 0 (zero)', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      this.tax_one_amt = 0;
    }
    else {
      this.tax_one_amt = 0;
    }
    /* END For Main Tax One */

    /* Start For Main Tax Two */
    if (this.f.tax_two_pre.value > 0) {
      let sub_total = this.getSum('sub_total') - this.main_discount;
      let tax_two_pre = this.f.tax_two_pre.value;
      this.tax_two_amt = ((sub_total * tax_two_pre) / 100);
    }
    else if (this.f.tax_one_pre.value < 0) {
      this.f.tax_two_pre.setValue(0);
      this.f.main_discount.setValue(0);
      this._toastrService.clear();
      this._toastrService.warning('Tax must be 0 (zero) or more than 0 (zero)', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      this.tax_two_amt = 0;
    }
    else {
      this.tax_two_amt = 0;
    }
    /* END For Main Tax Two */

    if (this.f.transport_charge.value > 0) {
      this.transport_charge_amt = this.f.transport_charge.value;
    }
    else 
    {
      this.transport_charge_amt = 0;
    }


    if (this.f.packing_charge.value > 0) {
      this.packing_charge_amt = this.f.packing_charge.value;
    }
    else 
    {
      this.packing_charge_amt = 0;
    }

    if (this.f.extra_charge.value > 0) {
      this.extra_charge_amt = this.f.extra_charge.value;
    }
    else 
    {
      this.extra_charge_amt = 0;
    }
  }

  getPreCustomer(dealerId) {
    this.loadingViewDealerName = true;
    this.dealersService.dealerViewDetails(dealerId).subscribe(
      response => {
        this.loadingViewDealerName = false;
        if (response.status) {
          this.dealerId = response.dealer.id;
          if (response.dealer.owner_name !== null) {
            this.dealerName = response.dealer.name + '(' + response.dealer.phone + ') - ' + response.dealer.owner_name;
          }
          else {
            this.dealerName = response.dealer.name + '(' + response.dealer.phone + ')';
          }
          this.getRemainingOrder(response.dealer.id);
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  getTransportDropDown() {
    this.deliveryChallanService.getTransportsDropDown().subscribe(
      data => {
        if (data.status) {
          this.transportDropDown = data.transport;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  changeTransport(transportId: any) {
    this.transportDropDown.forEach((value: any, index: any) => {
      if (value.id == transportId) {
        this.f.transport_name.setValue(value.name);
        this.f.mobile_no.setValue(value.mobile);
      }
    });
  }
}
