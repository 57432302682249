import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

const headers = new HttpHeaders({ 'Content-Type': 'application/json', responseType: 'blob' });

@Injectable({
  providedIn: 'root'
})
export class ChallanReportService {
  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(formdata): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}challan-report`, formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getChallanDetails(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}view-challan-details`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  filter(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}challan-report`, formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

   downloadReportPdf(formdata): Observable<Blob> {
    return this._http
      .post<Blob>(`${environment.apiUrl}get-challan-report-pdf`, formdata, {
        responseType:
          'blob' as 'json'
      })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
