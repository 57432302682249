import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "app/auth/service";
import { Router, ActivatedRoute } from "@angular/router";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PromocodeService } from "../../_services/promocode.service";

@Component({
  selector: "app-promo-code",
  templateUrl: "./promo-code.component.html",
  styleUrls: ["./promo-code.component.scss"],
})
export class PromoCodeComponent implements OnInit {
  public promocodeList: any = [];
  public start: any = 0;
  public page: any = 0;

  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;
  public loading = false;
  public progressMainTable: boolean = false;
  public newFormAdd: FormGroup;
  public submitted = false;
  public isAddMode: boolean = true;
  public loadingFrm: boolean = false;
  public amount: boolean = false;
  public percentage: boolean = true;
  public cashback: boolean = true;
  public instantreward: boolean = true;
  public promocodeId = 0;
  public error = "";
  public somePlaceholder = "Enter Percentage";

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private PromocodeService: PromocodeService,
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private http: HttpClient,
    private _coreSidebarService: CoreSidebarService
  ) {}

  ngOnInit(): void {
    this.promocodesList(this.start, this.lengths);
    this.newFormAdd = this._formBuilder.group({
      type: ["1", Validators.required],
      cashbacktype: ["1", Validators.required],
      name: ["", Validators.required],
      percentage: ["", Validators.required],
      start_date: ["", Validators.required],
      end_date: ["", Validators.required],
      min_order_amount: ["", Validators.required],
      max_use: ["", Validators.required],
    });
  }

  get f() {
    return this.newFormAdd.controls;
  }

  /**
   * On Submit
   */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newFormAdd.invalid) {
      this.newFormAdd.markAllAsTouched();
    } else {
      this.loadingFrm = true;
      this.error = "";
      const { type,cashbacktype, name, percentage, start_date, end_date, min_order_amount,max_use } =
        this.newFormAdd.controls;
      this.PromocodeService.add(
        type.value,
        cashbacktype.value,
        name.value,
        percentage.value,
        start_date.value,
        end_date.value,
        min_order_amount.value,
        max_use.value,
        this.promocodeId
      ).subscribe(
        (data) => {
          this.loadingFrm = false;
          if (data.status) {
            this.promocodeId = 0;
            this.submitted = false;
            this.newFormAdd.reset();
            this.toggleSidebar("new-sidebar");
            this._toastrService.success(data.message, "Success!", {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            });
            this.reloadList();
          } else {
            this.error = data.message;
          }
        },
        (err) => {
          if (err.error.error == "Unauthenticated.") {
            this._authenticationService.logout();
            this._router.navigate(["/login"]);
          }
        }
      );
    }
  }

  /**
   * Toggle the sidebar
   *
   * @param name
   */
  toggleSidebar(name, type: any = 0): void {
    this.error = "";
    this.loadingFrm = false;
    this.submitted = false;

    if (type == 0) {
      this.promocodeId = 0;
    }
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  promocodesList(my_start: any, my_lengths: any) {
    this.PromocodeService.getData(my_start, my_lengths).subscribe(
      (response) => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.promocodeList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }

  reloadList() {
    this.progressMainTable = true;
    this.promocodesList(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.promocodesList(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.promocodesList(this.start, this.lengths);
  }

  typeChange(type) {
    this.amount = false;
    this.percentage = false;
    if (type == 1) {
      this.percentage = true;
      this.somePlaceholder = "Enter Percentage";
    } else {
      this.amount = true;
      this.somePlaceholder = "Enter Amount";
    }
  }

  delete(id: any) {
    const me = this;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7367F0",
      cancelButtonColor: "#E42728",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger ml-1",
      },
    }).then(function (result) {
      if (result.value) {
        me.PromocodeService.delete(id).subscribe(
          (response) => {
            if (response.status) {
              me.progressMainTable = true;
              me.reloadList();
              Swal.fire({
                icon: "success",
                title: "Deleted!",
                text: "Promocode Deleted Successfully",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            }
          },
          (err) => {
            if (err.error.error == "Unauthenticated.") {
              this._authenticationService.logout();
              this._router.navigate(["/login"]);
            }
          }
        );
      }
    });
  }

  edit(index: any) {
    this.promocodeId = this.promocodeList[index].id;
    this.f.name.setValue(this.promocodeList[index].name);
    this.f.type.setValue(this.promocodeList[index].type.toString());    
    this.f.cashbacktype.setValue(this.promocodeList[index].cashbacktype.toString());    
    this.f.percentage.setValue(this.promocodeList[index].percentage);
    this.f.start_date.setValue(this.promocodeList[index].sdate);
    this.f.end_date.setValue(this.promocodeList[index].edate);
    this.f.min_order_amount.setValue(this.promocodeList[index].min_order_amount);
    this.f.max_use.setValue(this.promocodeList[index].max_use);

    this.toggleSidebar("new-sidebar", 1);
  }
}
