import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ManufacturersService } from 'app/_services/manufacturers.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'app/auth/service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-manufacturers',
  templateUrl: './manufacturers.component.html',
  styleUrls: ['./manufacturers.component.scss'],
  providers: [DatePipe]
})
export class ManufacturersComponent implements OnInit {


  manufacturersList: any = [];
  start: any = 0;
  page: any = 0;

  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 15;
  public loading = false;
  public manufacturerDetails: any;
  public myDate: any = new Date();

  constructor(private datePipe: DatePipe, private _router: Router, private _authenticationService: AuthenticationService, private _toastrService: ToastrService, private http: HttpClient, private manufacturersService: ManufacturersService, private _coreSidebarService: CoreSidebarService, private modalService: NgbModal) {
    this.myDate = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
  }

  ngOnInit(): void {
    this.loading = true;
    this.getManufacturers(this.start, this.lengths);
  }

  getManufacturers(my_start: any, my_lengths: any) {
    this.manufacturersService.getData(my_start, my_lengths).subscribe(
      response => {
        this.loading = false;
        if (response.status) {
          this.manufacturersList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }


  delete(id: any) {

    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.manufacturersService.delete(id).subscribe(
          response => {
            if (response.status) {
              me.getManufacturers(me.page, me.lengths);
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Your file has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }

  reloadList() {
    this.getManufacturers(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.page = this.lengths * (event - 1);
    this.getManufacturers(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.lengths = event.target.value;
    this.getManufacturers(this.start, this.lengths);
  }

  // modal Basic
  modalOpen(modalBasic, manufacturerId: any) {
    this.manufacturerDetails = [];
    this.modalService.open(modalBasic, {
      size: 'md'
    });

    this.manufacturersService.manufacturerViewDetails(manufacturerId).subscribe(
      response => {
        if (response.status) {
          this.manufacturerDetails = response.manufacturer;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );

  }

  changeStatus(status: number, id: number) {
    this.manufacturersService.changeStatus(id, status).subscribe(
      response => {
        if (response.status) {
          this.reloadList();
          this._toastrService.success(response.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
        else {
          this._toastrService.error(response.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );

  }

}
