<div class="auth-wrapper auth-v2">
    <div class="auth-inner row m-0">
        <!-- Brand logo-->
        <a class="brand-logo" href="javascript:void(0);">
            <h2 class="brand-text text-primary ml-1"> <img src="assets/images/logo/logo-text.svg" style="width: 150px;height:60px;" alt="brand-logo" /></h2>
        </a>
        <!-- /Brand logo-->
        <!-- Left Text-->
        <div class="d-none d-lg-flex col-lg-6 align-items-center p-5">
            <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                <img class="img-fluid" [src]="
              coreConfig.layout.skin === 'dark'
                ? 'assets/images/pages/register.svg'
                : 'assets/images/pages/register.svg'
            " alt="Register V2" />
            </div>
        </div>
        <!-- /Left Text-->
        <!-- Register-->
        <div class="d-flex col-lg-6 align-items-center auth-bg px-2 p-lg-5 register-mobile">
            <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto" *ngIf="!otp_screen">
                <h2 class="card-title font-weight-bold mb-1">Registration</h2>
                <p class="card-text mb-2">Make your ORDER management easy and fun!</p>
                <form class="auth-register-form mt-2" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label for="register-enterprise_name" class="form-label">Enterprise Name<span class="text-danger">*</span></label>
                        <input type="text" formControlName="enterprise_name" class="form-control" placeholder="Enterprise Name" aria-describedby="register-enterprise_name" tabindex="1" [ngClass]="{ 'is-invalid': submitted && f.enterprise_name.errors }" />
                        <div *ngIf="submitted && f.enterprise_name.errors" class="invalid-feedback">
                            <div *ngIf="f.enterprise_name.errors.required">Enterprise Name is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="register-phone" class="form-label">Mobile No.<span class="text-danger">*</span></label>
                        <input type="text" formControlName="phone" class="form-control" placeholder="Mobile No." aria-describedby="register-phone" tabindex="2" autofocus [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                        <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                            <div *ngIf="f.phone.errors.required">Mobile No. is required</div>
                            <div *ngIf="f.phone.errors.pattern">Please Provide Valid Mobile No.</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="register-email" class="form-label">Email<span class="text-danger">*</span></label>
                        <input type="text" formControlName="email" class="form-control" placeholder="supplier@example.com" aria-describedby="register-email" tabindex="3" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                        </div>
                    </div>

                    <div class="row">

                        <div class="form-group col-6">
                            <label for="register-state_id" class="form-label">Select State<span class="text-danger">*</span></label>

                            <select class="" formControlName="state_id" class="form-control" (change)="getCity($event)" placeholder="Select State" aria-describedby="register-state_id" tabindex="4" [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }">
                                <option value="" selected disabled>Select State</option>
                                <option *ngFor="let state of stateDropDown" [value]="state.id">
                                    {{state.state_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback">
                                <div *ngIf="f.state_id.errors.required">State is required</div>
                            </div>
                        </div>

                        <div class="form-group col-6 ">
                            <label for="register-city_id" class="form-label">Select City<span class="text-danger">*</span></label>
                            <select class="" formControlName="city_id" class="form-control" placeholder="Select City" aria-describedby="register-city_id" tabindex="5" [ngClass]="{ 'is-invalid': submitted && f.city_id.errors }">
                                <option value="" selected >Select City</option>
                                <option  *ngFor="let city of cityDropDown" [value]="city.id">
                                    {{city.city_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.city_id.errors" class="invalid-feedback">
                                <div *ngIf="f.city_id.errors.required">City is required</div>
                            </div>
                        </div>

                    </div>


                    <div class="form-group">
                        <label for="register-password" class="form-label">Password<span class="text-danger">*</span></label>
                        <div class="input-group input-group-merge form-password-toggle">
                            <input [type]="passwordTextType ? 'text' : 'password'" formControlName="password" class="form-control form-control-merge" placeholder="············" aria-describedby="register-password" tabindex="7" [ngClass]="{ 'is-invalid error': submitted && f.password.errors }"
                            />
                            <div class="input-group-append">
                                <span class="input-group-text cursor-pointer"><i class="feather font-small-4" [ngClass]="{'icon-eye-off': passwordTextType,'icon-eye': !passwordTextType }" (click)="togglePasswordTextType(0)" ></i></span>
                            </div>
                        </div>
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback" [ngClass]="{ 'd-block': submitted && f.password.errors }">
                            <div *ngIf="f.password.errors.required">Password is required</div>
                            <div *ngIf="f.password.touched && f.password.errors?.invalidPassword"> Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter and 1 number</div>

                        </div>
                    </div>

                    <div class="form-group">
                        <label for="register-password" class="form-label">Confirm Password<span class="text-danger">*</span></label>
                        <div class="input-group input-group-merge form-password-toggle">
                            <input [type]="passwordTextType1 ? 'text' : 'password'" formControlName="confirmPassword" class="form-control form-control-merge" placeholder="············" aria-describedby="register-confirmPassword" tabindex="8" [ngClass]="{ 'is-invalid error': submitted && f.confirmPassword.errors }"
                            />
                            <div class="input-group-append">
                                <span class="input-group-text cursor-pointer"><i class="feather font-small-4" [ngClass]="{'icon-eye-off': passwordTextType1,'icon-eye': !passwordTextType1 }" (click)="togglePasswordTextType(1)" ></i></span>
                            </div>
                        </div>
                        <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback" [ngClass]="{ 'd-block': submitted && f.confirmPassword.errors }">
                            <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                            <div *ngIf="f.confirmPassword.touched && f.confirmPassword.errors?.passwordMismatch">Passwords does not match</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="custom-control custom-checkbox">
                            <input class="custom-control-input" formControlName="i_agree" aria-describedby="register-i_agree" type="checkbox" id="register-privacy-policy" tabindex="8" [ngClass]="{ 'is-invalid': submitted && f.i_agree.errors }" />
                            <label class="custom-control-label" for="register-privacy-policy">I agree to </label> <a href="https://www.digitalorderbook.com/terms-and-conditions.html" target="_blank"> Terms and conditions</a>
                            <div *ngIf="submitted && f.i_agree.errors" class="invalid-feedback">
                                <div *ngIf="f.i_agree.errors.required">Please Agree our Terms and conditions</div>
                            </div>
                        </div>
                    </div>

                    <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                        <div class="alert-body">
                            <p>{{ error }}</p>
                        </div>
                    </ngb-alert>

                    <button [disabled]="loading" class="btn btn-primary btn-block" tabindex="9" rippleEffect>
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Sign up
                    </button>

                </form>
                <p class="text-center mt-2">
                    <a href="/" class="text-bold"> <span class="badge badge-light-primary" style="height:30px;padding-top: 10px;">
                        Already have an account? <span>&nbsp;Sign in instead</span>
                        </span>
                    </a>
                </p>

            </div>
            <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto" *ngIf="otp_screen">
                <h2 class="card-title font-weight-bold mb-1 text-center">OTP Verification</h2>
                <p class="card-text mb-2 text-center">Please enter the verification code sent to your number</p>
                <form class="auth-register-form mt-2" [formGroup]="registerotpForm" (ngSubmit)="onSubmitotp()">
                    <div class="form-group text-center pb-1 pt-3">
                        <input type="text" style="width: 50px;margin-right: 5px;display: inline;" (keyup)="nextBox($event,'otp_two')" formControlName="otp_one" class="form-control text-center" aria-describedby="register-otp_one" tabindex="1" />
                        <input type="text" style="width: 50px;margin-right: 5px;display: inline;" (keyup)="nextBox($event,'otp_three')" formControlName="otp_two" class="form-control text-center" aria-describedby="register-otp_two" tabindex="2" />
                        <input type="text" style="width: 50px;margin-right: 5px;display: inline;" (keyup)="nextBox($event,'otp_four')" formControlName="otp_three" class="form-control text-center" aria-describedby="register-otp_three" tabindex="3" />
                        <input type="text" style="width: 50px;margin-right: 5px;display: inline;" (keyup)="nextBox($event,'otp_five')" formControlName="otp_four" class="form-control text-center" aria-describedby="register-otp_four" tabindex="4" />
                        <input type="text" style="width: 50px;margin-right: 5px;display: inline;" (keyup)="nextBox($event,'otp_six')" formControlName="otp_five" class="form-control text-center" aria-describedby="register-otp_five" tabindex="5" />
                        <input type="text" style="width: 50px;margin-right: 5px;display: inline;" (keyup)="nextBox($event,6)" formControlName="otp_six" class="form-control text-center" aria-describedby="register-otp_six" tabindex="6" />
                    </div>
                    <div class="form-group text-center">
                        <p class="text-center">
                            Don't Receive the OTP?
                            <a class="text-primary" style="font-weight: 900;" (click)="resendotp()">Resend OTP</a>
                        </p>
                    </div>
                    <center>
                        <button [disabled]="loadingOtp" class="btn btn-primary btn-block" style="max-width:300px;" tabindex="6" rippleEffect>
                        <span *ngIf="loadingOtp" class="spinner-border spinner-border-sm mr-1"></span>VERIFY
                    </button>
                    </center>
                    <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                        <div class="alert-body">
                            <p>{{ error }}</p>
                        </div>
                    </ngb-alert>
                </form>
                <p class="text-center mt-2">
                    <a href="/" class="text-bold"> <span class="badge badge-light-primary" style="height:30px;padding-top: 10px;">
                        Already have an account? <span>&nbsp;Sign in instead</span>
                        </span>
                    </a>
                </p>
            </div>

        </div>
        <!-- /Register-->
    </div>
</div>