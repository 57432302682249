import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLoginV2Component } from 'app/main/pages/authentication/auth-login-v2/auth-login-v2.component';
import { RegisterComponent } from 'app/main/pages/register/register.component';
import { AuthGuard } from './auth/helpers';
import { CategoryComponent } from './component/category/category.component';
import { HomeComponent } from './main/sample/home.component';
import { DealersComponent } from './component/dealers/dealers.component';
import { DealersAddComponent } from './component/dealers-add/dealers-add.component';
import { ProductsComponent } from './component/products/products.component';
import { ProductsAddComponent } from './component/products-add/products-add.component';
import { OrdersComponent } from './component/orders/orders.component';
import { OrdersAddComponent } from './component/orders-add/orders-add.component';
import { DeliveryChallanComponent } from './component/delivery-challan/delivery-challan.component';
import { DeliveryChallanAddComponent } from './component/delivery-challan-add/delivery-challan-add.component';
import { PricePerCustomerAddComponent } from './component/price-per-customer-add/price-per-customer-add.component';
import { PricePerCustomerComponent } from './component/price-per-customer/price-per-customer.component';
import { PricePerCustomerProductComponent } from './component/price-per-customer-product/price-per-customer-product.component';
import { ProfileComponent } from './component/profile/profile.component';
import { ForgotPasswordComponent } from 'app/main/pages/authentication/forgot-password/forgot-password.component';
import { ReportsComponent } from './component/reports/reports.component';
import { Role } from 'app/auth/models'
import { ManufacturersComponent } from './component/manufacturers/manufacturers.component';
import { EmployeeComponent } from './component/employee/employee.component';
import { EmployeeAddComponent } from './component/employee-add/employee-add.component';
import { ChallanReportComponent } from './component/challan-report/challan-report.component';
import { HelpComponent } from './component/help/help.component';
import { CustomerGuideComponent } from './component/customer-guide/customer-guide.component';
import { UnitsComponent } from './component/units/units.component';
import { OutstandingReportComponent } from './component/outstanding-report/outstanding-report.component';
import { PricingComponent } from './component/pricing/pricing.component';
import { SettingsComponent } from './component/settings/settings.component';
import { ProductInwardComponent } from './component/product-inward/product-inward.component';
import { ProductInwardAddComponent } from './component/product-inward/product-inward-add/product-inward-add.component';
import { ExpenseManageComponent } from './component/expense-manage/expense-manage.component';
import { ExpenseCategoryComponent } from './component/expense-category/expense-category.component';
import { ExpenseReportComponent } from './component/expense-report/expense-report.component';
import { DeliveryChallanReachedComponent } from './component/delivery-challan-reached/delivery-challan-reached.component';
import { PromoCodeComponent } from './component/promo-code/promo-code.component';
import { WarehouseComponent } from './component/warehouse/warehouse.component';
import { TransportMasterComponent } from './component/transport-master/transport-master.component';
import { SliderComponent } from './component/slider/slider.component';


const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: AuthLoginV2Component,
    data: { animation: 'auth' }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: { animation: 'auth' }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: { animation: 'auth' }
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client,Role.Admin] }

  },
  {
    path: 'categories',
    component: CategoryComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'dealers',
    component: DealersComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'dealers/add',
    component: DealersAddComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'dealers/edit/:id',
    component: DealersAddComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'products',
    component: ProductsComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'products/add',
    component: ProductsAddComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'products/edit/:id',
    component: ProductsAddComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'products-inward',
    component: ProductInwardComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'products-inward/add',
    component: ProductInwardAddComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'products-inward/edit/:id',
    component: ProductInwardAddComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'orders',
    component: OrdersComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client,Role.User] }
  },
  {
    path: 'orders/add',
    component: OrdersAddComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client,Role.User] }
  },
  {
    path: 'delivery-challan',
    component: DeliveryChallanComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'delivery-challan/add',
    component: DeliveryChallanAddComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'delivery-challan/add/:id',
    component: DeliveryChallanAddComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  // {
  //   path: 'price-per-customer',
  //   component: PricePerCustomerComponent,
  //   canActivate: [AuthGuard],
  //   data: { animation: 'auth', roles: [Role.Client] }
  // },
  // {
  //   path: 'price-per-customer/add',
  //   component: PricePerCustomerAddComponent,
  //   canActivate: [AuthGuard],
  //   data: { animation: 'auth', roles: [Role.Client] }
  // },
  // {
  //   path: 'price-per-customer/product/:id',
  //   component: PricePerCustomerProductComponent,
  //   canActivate: [AuthGuard],
  //   data: { animation: 'auth', roles: [Role.Client] }
  // },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'manufacturers',
    component: ManufacturersComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Admin] }
  },
  // {
  //   path: 'employees',
  //   component: EmployeeComponent,
  //   canActivate: [AuthGuard],
  //   data: { animation: 'auth', roles: [Role.Client] }
  // },
  // {
  //   path: 'employees/add',
  //   component: EmployeeAddComponent,
  //   canActivate: [AuthGuard],
  //   data: { animation: 'auth', roles: [Role.Client] }
  // },
  // {
  //   path: 'employees/edit/:id',
  //   component: EmployeeAddComponent,
  //   canActivate: [AuthGuard],
  //   data: { animation: 'auth', roles: [Role.Client] }
  // },
  {
    path: 'challan-reports',
    component: ChallanReportComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  // {
  //   path: 'supplier-guide',
  //   component: HelpComponent,
  //   canActivate: [AuthGuard],
  //   data: { animation: 'auth', roles: [Role.Client] }
  // },
  // {
  //   path: 'customer-guide',
  //   component: CustomerGuideComponent,
  //   canActivate: [AuthGuard],
  //   data: { animation: 'auth', roles: [Role.Client] }
  // },
  {
    path: 'units',
    component: UnitsComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Admin] }
  },
  {
    path: 'outstanding-report',
    component: OutstandingReportComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'pricing',
    component: PricingComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'setting',
    component: SettingsComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'expenses',
    component: ExpenseManageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'expense-category',
    component: ExpenseCategoryComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'expense-report',
    component: ExpenseReportComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'delivery-challan-reached',
    component: DeliveryChallanReachedComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'promocode',
    component: PromoCodeComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'warehouse',
    component: WarehouseComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'transport',
    component: TransportMasterComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'slider',
    component: SliderComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: '**',
    redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    scrollPositionRestoration: 'enabled', // Add options right here
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }