import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  /**
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getState(): Observable<any> {
    const userDetail = JSON.parse(localStorage.getItem("currentUser"));
    return this._http
      .get(`${environment.apiUrl}get-state-dropdown`)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getCity(stateId: any): Observable<any> {
    const userDetail = JSON.parse(localStorage.getItem("currentUser"));
    return this._http
      .get(`${environment.apiUrl}get-cities-dropdown/` + stateId)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  add(enterprise_name: any, phone: any, email: any, state_id: any, city_id: any, password: any): Observable<any> {
    const userDetail = JSON.parse(localStorage.getItem("currentUser"));
    return this._http
      .post(`${environment.apiUrl}save-manufacturer`, {
        enterprise_name,
        phone,
        email,
        state_id,
        city_id,
        password
      })
      .pipe(
        map(data => {
          return data;
        })
      );

  }

  sentOtp(email): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}forgot-password`, { email })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  verifyotp(mobile: any, otp: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}verify-otp`, { mobile, otp })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  resendotp(mobile): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}resend-otp`, { mobile })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  changePassword(mobile: any, password: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}new-password`, { mobile, password })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

}
