<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Promo Code List</h4>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-6 col-6">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                        <select class="form-control mx-25" [(ngModel)]="lengths" (change)="loadPageLenghs($event)" >
                                            <option value="30">30</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                      </label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-6 d-flex justify-content-start justify-content-md-end">
                                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0 w-100">
                                        <button class="btn btn-primary ml-1" rippleEffect (click)="toggleSidebar('new-sidebar')">
                                        <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i
                                        ><span class="d-none d-sm-inline-block">Add New</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 table-responsive">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Type</th>
                                            <th>Cashback Type</th>
                                            <th>Name</th>
                                            <th>Percentage / Amount</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Min Order Amount</th>
                                            <th>Current use</th>
                                            <th>Max use</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody *ngIf="promocodeList?.length != 0">
                                        <tr *ngFor="let promo of promocodeList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td>
                                                <span class="badge badge-pill badge-light-warning mr-1" *ngIf="promo.type == 1">Percentage</span>
                                                <span class="badge badge-pill badge-light-success mr-1" *ngIf="promo.type == 2">Amount</span>
                                            </td>
                                            <td>
                                                <span class="badge badge-pill badge-light-warning mr-1" *ngIf="promo.cashbacktype == 1">Cashback</span>
                                                <span class="badge badge-pill badge-light-success mr-1" *ngIf="promo.cashbacktype == 2">Instant Reward</span>
                                            </td>
                                            <td>{{ promo.name }}</td>
                                            <td>{{ promo.percentage }}</td>
                                            <td>{{ promo.start_date }}</td>
                                            <td>{{ promo.end_date }}</td>
                                            <td>{{ promo.min_order_amount }}</td>
                                            <td>{{ promo.current_use }}</td>
                                            <td>{{ promo.max_use }}</td>
                                            <td>
                                                <div ngbDropdown container="body">
                                                    <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                                                      <i data-feather="more-vertical"></i>
                                                    </button>
                                                    <div ngbDropdownMenu>
                                                        <a ngbDropdownItem href="javascript:void(0);" (click)="edit(ndx)"><i data-feather="edit" class="mr-50"></i><span>Edit</span></a>
                                                        <a ngbDropdownItem href="javascript:void(0);" (click)="delete(promo.id)"><i data-feather="trash" class="mr-50"></i><span>Delete</span></a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="promocodeList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="3" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSize > 0" [collectionSize]="collectionSize" [pageSize]="lengths" [(page)]="pageBasicText" (pageChange)="loadPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>

<!-- New User Sidebar -->
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="new-sidebar" overlayClass="modal-backdrop">
    <div class="slideout-content">
        <!-- Modal to add new starts-->
        <div class="modalsd modal-slide-in sdfade new-modal" id="modals-slide-in">
            <div class="modal-dialog">
                <form class="add-new modal-content pt-0" [formGroup]="newFormAdd" (ngSubmit)="onSubmit()">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="toggleSidebar('new-sidebar')">
                      ×
                    </button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title" id="exampleModalLabel">Add Promo Code</h5>
                    </div>
                    <div class="modal-body flex-grow-1 ">
                        <div class="row m-0">
                            <div class="col">
                                <div class="form-group row">
                                    <div class="custom-control custom-radio col-6">
                                        <input type="radio" id="customRadio1" name="customRadio" formControlName="type" (change)="typeChange(1)" value="1" class="custom-control-input" checked />
                                        <label class="custom-control-label" for="customRadio1">Percentage</label>
                                      </div>
                                      <div class="custom-control custom-radio col-6 pl-2">
                                        <input type="radio" id="customRadio2" name="customRadio" formControlName="type" (change)="typeChange(2)" value="2" class="custom-control-input" />
                                        <label class="custom-control-label" for="customRadio2">Amount</label>
                                      </div>
                                </div>
                                <div class="form-group row">
                                    <div class="custom-control custom-radio col-6">
                                        <input type="radio" id="cashbackRadio1" name="cashbackRadio" formControlName="cashbacktype" value="1" class="custom-control-input" checked />
                                        <label class="custom-control-label" for="cashbackRadio1">Cashback</label>
                                      </div>
                                      <div class="custom-control custom-radio col-6 pl-2">
                                        <input type="radio" id="cashbackRadio2" name="cashbackRadio" formControlName="cashbacktype" value="2" class="custom-control-input" />
                                        <label class="custom-control-label" for="cashbackRadio2">Instant reward</label>
                                      </div>
                                </div>
                                <div class="form-group">
                                    <label for="register-name" class="form-label">Name<span class="text-danger">*</span> </label>
                                    <input type="text" formControlName="name" class="form-control" placeholder="Enter Name" aria-describedby="register-name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                        <div *ngIf="f.name.errors.required">Name is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="register-percentage" [ngClass]="{ 'd-none': amount }" class="form-label">Percentage<span class="text-danger">*</span> </label>
                                    <label for="register-amount" [ngClass]="{ 'd-none': percentage }"   class="form-label">Amount<span class="text-danger">*</span> </label>
                                    <input type="number" formControlName="percentage" class="form-control"   placeholder="{{somePlaceholder }}" aria-describedby="register-percentage" [ngClass]="{ 'is-invalid': submitted && f.percentage.errors }" />
                                    <div *ngIf="submitted && f.percentage.errors" class="invalid-feedback">
                                        <div *ngIf="f.percentage.errors.required">This field is required</div>
                                    </div>
                                </div>
                               
                                <div class="form-group">
                                    <label for="register-start_date" class="form-label">Start Date<span class="text-danger">*</span> </label>
                                    <input type="date" class="form-control" formControlName="start_date" placeholder="dd-mm-yyyy" aria-describedby="register-start_date" [ngClass]="{ 'is-invalid': submitted && f.start_date.errors }" />
                                    <div *ngIf="submitted && f.start_date.errors" class="invalid-feedback">
                                        <div *ngIf="f.start_date.errors.required">Start Date is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="register-end_date" class="form-label">End Date<span class="text-danger">*</span> </label>
                                    <input type="date" class="form-control" formControlName="end_date" placeholder="dd-mm-yyyy" aria-describedby="register-end_date" [ngClass]="{ 'is-invalid': submitted && f.end_date.errors }" />
                                    <div *ngIf="submitted && f.end_date.errors" class="invalid-feedback">
                                        <div *ngIf="f.end_date.errors.required">End Date is required</div>
                                    </div>
                                </div>
                                
                                <div class="form-group">
                                    <label for="register-min_order_amount" class="form-label">Min Order Amount<span class="text-danger">*</span> </label>
                                    <input type="number" formControlName="min_order_amount" class="form-control" placeholder="Enter Min Order Amount" aria-describedby="register-min_order_amount" [ngClass]="{ 'is-invalid': submitted && f.min_order_amount.errors }" />
                                    <div *ngIf="submitted && f.min_order_amount.errors" class="invalid-feedback">
                                        <div *ngIf="f.min_order_amount.errors.required">Min Order Amount is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="register-max_use" class="form-label">Max use<span class="text-danger">*</span> </label>
                                    <input type="number" formControlName="max_use" class="form-control" placeholder="Enter Max use" aria-describedby="register-max_use" [ngClass]="{ 'is-invalid': submitted && f.max_use.errors }" />
                                    <div *ngIf="submitted && f.max_use.errors" class="invalid-feedback">
                                        <div *ngIf="f.max_use.errors.required">Max use is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-lg-12 p-0">
                                        <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                                            <div class="alert-body">
                                                <p>{{ error }}</p>
                                            </div>
                                        </ngb-alert>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-lg-12 p-0">
                                        <button type="reset" class="btn btn-secondary" (click)="toggleSidebar('new-sidebar')" [disabled]="loadingFrm" rippleEffect>
                                            <span *ngIf="loadingFrm" class="spinner-border spinner-border-sm mr-1"></span>
                                            <i data-feather="x" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Cancel </span>                                    
                                        </button>
                                        <button type="submit" class="btn btn-primary ml-1 " rippleEffect [disabled]="loadingFrm"> <span *ngIf="loadingFrm" class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- Modal to add new Ends-->
    </div>
</core-sidebar>
<!--/ New User Sidebar -->