import { AfterViewInit, Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { OrdersService } from 'app/_services/orders.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'app/auth/service';

@Component({
  selector: 'app-orders-add',
  templateUrl: './orders-add.component.html',
  styleUrls: ['./orders-add.component.scss']
})
export class OrdersAddComponent implements OnInit {

  @ViewChild('auto') auto: any;

  public keyword = 'name';
  public keywordProduct = 'full_name';
  public historyHeading: string = 'Recently selected';
  public isLoading: boolean = false;
  public isLoadingProduct: boolean = false;
  public allDealer: any;
  public dealerId: any = 0;
  public allProduct: any;
  public order_details: any = [];
  public total_qty: any = 0;
  public newForm: UntypedFormGroup;
  public loading: boolean = false;
  public dealerSelected: boolean = true;
  public isSelectedColor: '';
  public isLoadingColorList: boolean = false;
  public isSelectedSize: '';
  public isLoadingSizeList: boolean = false;
  public isSelectedUnit: '';
  public isLoadingUnitList: boolean = false;
  public isCheckedColor = false;
  public isCheckedSize = false;
  public isCheckedUnit = false;

  constructor(private _authenticationService: AuthenticationService, private http: HttpClient, private ordersService: OrdersService, private _toastrService: ToastrService, private _router: Router, private route: ActivatedRoute, private _formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.newForm = this._formBuilder.group({
      poNumber: [''],
      remarks: [''],
    });
  }

  get f() {
    return this.newForm.controls;
  }

  onChangeSearch(value: string) {
    this.isLoading = true
    this.dealerSelected = true;
    this.ordersService.dealerSearch(value).subscribe(
      data => {
        if (data.status) {
          this.isLoading = false
          this.allDealer = data.dealer_data;
          this.allProduct = [];
          this.order_details = [];
        }
        else {
          this._toastrService.clear();
          this._toastrService.error(data.message, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
        this._toastrService.clear();
        this._toastrService.error(err.message, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }

  selectEvent(item: any) {
    this.dealerId = item.id;
    this.dealerSelected = false;

  }

  onFocused(e: any) {
    // do something when input is focused
  }

  clear(): void {
    this.auto.clear();
  }

  onChangeSearchProduct(value: string) {
    if (value.length >= 3) {
      this.isLoadingProduct = true
      this.ordersService.productSearch(this.dealerId, value).subscribe(
        data => {
          this.isLoadingProduct = false
          if (data.status) {
            for (var i = 0; i < data.product_data.length; i++) {
              if (data.product_data[i].discount > 0) {
                data.product_data[i].discount = data.product_data[i].sales_price - ((data.product_data[i].sales_price * data.product_data[i].discount) / 100)
              }
            }
            this.allProduct = data.product_data;
          }
          else {
            this._toastrService.clear();
            this._toastrService.error(data.message, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
          this._toastrService.clear();
          this._toastrService.error(err.message, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      );
    }
  }

  selectEventProduct(item: any) {
    if (this.order_details.length > 0 && this.order_details.some((detail: { id: any; }) => detail.id === item.id)) {
      this._toastrService.clear();
      this._toastrService.warning('Product already added', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
    }else {
      item.qty = item.min_order_qty;
      item.total = item.sales_price;
      this.total_qty += item.min_order_qty;
      if(item.colorList != ''){
        this.isCheckedColor = true;
        this.isSelectedColor = item.colorList[0].id;
      }
      if(item.sizeList != ''){
        this.isCheckedSize = true;
        this.isSelectedSize = item.sizeList[0].id;
      }
      if(item.unitList != ''){
        this.isCheckedUnit = true;
        this.isSelectedUnit = item.unitList[0].id;
      }
     
      this.order_details.push(item);
      this.order_details.reverse();
    }
    this.clear();
  }

  onFocusedProduct(e: any) {
    // do something when input is focused
  }

  countChange(qty: any, product_id: any) {
    this.total_qty = 0;
    for (var i = 0; i < this.order_details.length; i++) {
      if (this.order_details[i].id === product_id) {
        this.order_details[i].qty = parseFloat(qty);
        this.order_details[i].total = this.order_details[i].sales_price * parseFloat(qty);
      }
      this.total_qty += this.order_details[i].qty;
    }
  }

  productDelete(product_id: any) {
    for (var i = 0; i < this.order_details.length; i++) {
      if (this.order_details[i].id == product_id) {
        this.total_qty -= this.order_details[i].qty;
        this.order_details.splice(i, 1);
      }
    }
  }

  onChangeColorVariant(event:any, product_id:any){
    this.isLoadingSizeList = true;
    this.isLoadingUnitList = true;
    this.isSelectedColor = event.id;
    const type = 1;
    this.ordersService.productVariantDropDown(event.id, product_id, type, this.isSelectedSize, this.isSelectedUnit).subscribe(
        data => {
        this.isLoadingSizeList = false;
        this.isLoadingUnitList = false;
        if (data.status) {
          for (var i = 0; i < this.order_details.length; i++) {
            if (this.order_details[i].id === product_id) {
              this.order_details[i].product_variant_id = data.variant_data.id;
              this.order_details[i].sales_price = data.variant_data.sales_price;
              this.order_details[i].packing_qty = data.variant_data.packing_qty;
              this.order_details[i].master_packing_qty = data.variant_data.master_packing_qty;
              this.order_details[i].min_order_qty = data.variant_data.min_order_qty;
              this.order_details[i].color =  event.id;

              if(data.variant_data.sizeList != ''){
                this.order_details[i].size = data.variant_data.sizeList[0].id;
                this.isSelectedSize = data.variant_data.sizeList[0].id;
              }

              if(data.variant_data.unitList != ''){
                this.order_details[i].unit = data.variant_data.unitList[0].id;
                this.isSelectedUnit = data.variant_data.unitList[0].id;
              }

              if (data.variant_data.discount > 0) {
                this.order_details[i].discount = data.variant_data.sales_price - ((data.variant_data.sales_price * data.variant_data.discount) / 100)
              }
            }
            
            // this.order_details[i].sizeList = data.variant_data.sizeList;
            // this.order_details[i].unitList = data.variant_data.unitList;
          }
        }
        else {
          this._toastrService.clear();
          this._toastrService.error(data.message, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
        this._toastrService.clear();
        this._toastrService.error(err.message, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }

  onChangeSizeVariant(event:any, product_id:any){
    this.isLoadingColorList = true;
    this.isLoadingUnitList = true;
    this.isSelectedSize = event.id;
    const type = 2;
    this.ordersService.productVariantDropDown(event.id, product_id, type, this.isSelectedColor, this.isSelectedUnit).subscribe(
        data => {
        this.isLoadingColorList = false;
        this.isLoadingUnitList = false;
        if (data.status) {
          for (var i = 0; i < this.order_details.length; i++) {
            if (this.order_details[i].id === product_id) {
              this.order_details[i].product_variant_id = data.variant_data.id;
              this.order_details[i].sales_price = data.variant_data.sales_price;
              this.order_details[i].packing_qty = data.variant_data.packing_qty;
              this.order_details[i].master_packing_qty = data.variant_data.master_packing_qty;
              this.order_details[i].min_order_qty = data.variant_data.min_order_qty;
              if(data.variant_data.colorList != ''){
                this.order_details[i].color = data.variant_data.colorList[0].id;
                this.isSelectedColor = data.variant_data.colorList[0].id;
              }
              if(data.variant_data.colorList != ''){
                this.isSelectedUnit = data.variant_data.unitList[0].id;
                this.order_details[i].unit = data.variant_data.unitList[0].id;
              }
              this.order_details[i].size = event.id;

              if (data.variant_data.discount > 0) {
                this.order_details[i].discount = data.variant_data.sales_price - ((data.variant_data.sales_price * data.variant_data.discount) / 100)
              }
            }
           
           
          //  this.order_details[i].colorList = data.variant_data.colorList;
          //  this.order_details[i].unitList = data.variant_data.unitList;
          }
        }
        else {
          this._toastrService.clear();
          this._toastrService.error(data.message, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
        this._toastrService.clear();
        this._toastrService.error(err.message, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }

  onChangeUnitVariant(event:any, product_id:any){
    this.isLoadingColorList = true;
    this.isLoadingSizeList = true;
    this.isSelectedUnit = event.id;
    const type = 3;
    this.ordersService.productVariantDropDown(event.id, product_id, type, this.isSelectedColor, this.isSelectedUnit).subscribe(
        data => {
        this.isLoadingColorList = false;
        this.isLoadingSizeList = false;
        if (data.status) {
          for (var i = 0; i < this.order_details.length; i++) {
            if (this.order_details[i].id === product_id) {
              this.order_details[i].product_variant_id = data.variant_data.id;
              this.order_details[i].sales_price = data.variant_data.sales_price;
              this.order_details[i].packing_qty = data.variant_data.packing_qty;
              this.order_details[i].master_packing_qty = data.variant_data.master_packing_qty;
              this.order_details[i].min_order_qty = data.variant_data.min_order_qty;
              if(data.variant_data.colorList != ''){
                this.order_details[i].color =  data.variant_data.colorList[0].id;
                this.isSelectedColor = data.variant_data.colorList[0].id;
              }
              if(data.variant_data.sizeList != ''){
                this.order_details[i].size = data.variant_data.sizeList[0].id;
                this.isSelectedColor = data.variant_data.colorList[0].id;
              }
              this.order_details[i].unit = event.id;
              if (data.variant_data.discount > 0) {
                this.order_details[i].discount = data.variant_data.sales_price - ((data.variant_data.sales_price * data.variant_data.discount) / 100)
              }
            }
            
            //this.isLoadingSizeList = data.variant_data.size_list[0].id;
         //   this.order_details[i].colorList = data.variant_data.colorList;
         //   this.order_details[i].size_list = data.variant_data.size_list;
          }
        }
        else {
          this._toastrService.clear();
          this._toastrService.error(data.message, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
        this._toastrService.clear();
        this._toastrService.error(err.message, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }

  storeDealerOrder() {
    this.loading = true;
   // console.log(this.order_details);
    this.ordersService.storeOrder(this.dealerId, this.order_details, this.f.remarks.value,this.f.poNumber.value).subscribe(
      data => {
        this.loading = false;
        if (data.status) {
          this._toastrService.clear();
          this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
          this.order_details = [];
          this._router.navigate(['/orders']);
        }
        else {
          this._toastrService.clear();
          this._toastrService.error(data.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      },
      err => {
        this.loading = false;
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
        this._toastrService.clear();
        this._toastrService.error(err.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );

  }

  removeAll() {
    this.order_details = [];
    this.total_qty = 0;
  }

  closedStatic() {
    this.allProduct = [];
    this.order_details = [];
    this.dealerId = 0;
    this.total_qty = 0;
    this.dealerSelected = true;
  }
}

