import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ProductsService } from 'app/_services/products.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'app/auth/service';

@Component({
  selector: 'app-products-add',
  templateUrl: './products-add.component.html',
  styleUrls: ['./products-add.component.scss']
})
export class ProductsAddComponent implements OnInit {
  @ViewChild('auto') auto: any;

  public keyword = 'name';
  public historyHeading: string = 'Recently add color';
  public isLoading: boolean = false;
  public allColor: any;

  public keywordSize = 'name';
  public historyHeadingSize: string = 'Recently add size';
  public isLoadingSize: boolean = false;
  public allSize: any;

  public keywordUnit = 'name';
  public historyHeadingUnit: string = 'Recently add unit';
  public isLoadingUnit: boolean = false;
  public allUnit: any;

  public productId: any = 0;
  public productName: '';
  public loading = false;
  public error = '';
  public newForm: UntypedFormGroup;
  public submitted = false;
  public categoryDropDown: any;
  public unitDropDown: any;
  public selectBasicLoading = true;

  public isAddMode: boolean = true;
  public product_selected_photo_one: any = '';
  public product_selected_photo_two: any = '';
  public product_selected_photo_three: any = '';
  public product_selected_photo_four: any = '';

  public product_selected_photo_oneAvatarImage: string = 'assets/images/upload_placeholder.png';
  public product_selected_photo_twoAvatarImage: string = 'assets/images/upload_placeholder.png';
  public product_selected_photo_threeAvatarImage: string = 'assets/images/upload_placeholder.png';
  public product_selected_photo_fourAvatarImage: string = 'assets/images/upload_placeholder.png';
  public readerImg: string = '';

  /*vartion start*/
  public variantItems: any = [];
  public sectionVariantItems: boolean = false;
  public variantItemsImages: any = [];

  public isCheckedColor = false;
  public colorArray = [{ colorName: '',colorCode: '' }];
  public isCheckedSize = false;
  public sizeArray = [{ sizeName: '' }];
  public isCheckedUnit = false;
  public unitArray = [{ unitName: '' }];
  /*vartion end*/

  public isChecked = false;
  public isCheckednew = false;

  constructor(private elementRef: ElementRef, private _authenticationService: AuthenticationService, private productsService: ProductsService, private _toastrService: ToastrService, private _router: Router, private route: ActivatedRoute, private _formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.productName = '';
    this.getCategoryDropDown();
    // this.getUnitDropDown();
    if (this.route.snapshot.params['id'] !== undefined) {
      this.productId = this.route.snapshot.params['id'];
    }
    this.isAddMode = !this.productId;
    if (!this.isAddMode) {
      this.edit(this.productId);
    }

    this.newForm = this._formBuilder.group({
      category_id: [null],
      product_code: [''],
      is_trending: [''],
      is_new_arrival: [''],
      product_description: [''],
      product_name: [null, [Validators.required]],
      is_color: [''],
      is_size: [''],
      is_unit: [''],
      is_active: [''],
      product_photo_one: [''],
      product_photo_two: [''],
      product_photo_three: [''],
      product_photo_four: ['']
    });

    this.f.is_active.setValue(1);
  }

  get f() {
    return this.newForm.controls;
  }

  /*Autocomplete color, size and unit start*/
  onChangeColor(value: string) {
    this.isLoading = true
    this.productsService.getSearchColor(value).subscribe(
      data => {
        if (data.status) {
          this.isLoading = false
          this.allColor = data.color_data;
        } else {
          this._toastrService.clear();
          this._toastrService.error(data.message, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
        this._toastrService.clear();
        this._toastrService.error(err.message, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }

  selectEventColor(item: any, index: any) {
    for (let i = 0; i < this.colorArray.length; i++) {
      if (this.colorArray.indexOf(this.colorArray[i]) === index) {
        this.colorArray[i].colorName = item.name;
        this.colorArray[i].colorCode = item.color_code;
      }
    }
    console.log(this.colorArray);
    this.auto.clear();
  }

  onChangeSize(value: string) {
    this.isLoadingSize = true
    this.productsService.getSearchSize(value).subscribe(
      data => {
        if (data.status) {
          this.isLoadingSize = false
          this.allSize = data.size_data;
        } else {
          this._toastrService.clear();
          this._toastrService.error(data.message, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
        this._toastrService.clear();
        this._toastrService.error(err.message, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }

  selectEventSize(item: any, index: any) {
    for (let i = 0; i < this.sizeArray.length; i++) {
      if (this.sizeArray.indexOf(this.sizeArray[i]) === index) {
        this.sizeArray[i].sizeName = item.name
      }
    }
    this.auto.clear();
  }

  onChangeUnit(value: string) {
    this.isLoadingUnit = true
    this.productsService.getSearchUnit(value).subscribe(
      data => {
        if (data.status) {
          this.isLoadingUnit = false
          this.allUnit = data.unit_data;
        } else {
          this._toastrService.clear();
          this._toastrService.error(data.message, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
        this._toastrService.clear();
        this._toastrService.error(err.message, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }

  selectEventUint(item: any, index: any) {
    for (let i = 0; i < this.unitArray.length; i++) {
      if (this.unitArray.indexOf(this.unitArray[i]) === index) {
        this.unitArray[i].unitName = item.name
      }
    }
    this.auto.clear();
  }

  /*Autocomplete color, size and unit start*/

  getCategoryDropDown() {
    this.productsService.getCategoriesDropDown().subscribe(
      data => {
        if (data.status) {
          this.selectBasicLoading = false;
          this.categoryDropDown = data.categories;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  // getUnitDropDown() {
  //   this.productsService.getUnitDropDown().subscribe(
  //     data => {
  //       if (data.status) {
  //         this.unitDropDown = data.units;
  //       }
  //     },
  //     err => {
  //       if (err.error.error == 'Unauthenticated.') {
  //         this._authenticationService.logout();
  //         this._router.navigate(['/login']);
  //       }
  //     });
  // }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.elementRef.nativeElement.querySelector(
      ".is-invalid"
    );

    firstInvalidControl.focus(); //without smooth behavior
  }
  /**
  * On Submit
  */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.verifiedForm()) {
      if (this.newForm.invalid) {
        this.newForm.markAllAsTouched();
        setTimeout(() => {
          this.scrollToFirstInvalidControl();
        }, 100);
      } else {
        this.loading = true;
        let isColors = '0';
        let isSizes = '0';
        let isUnits = '0';
        let is_trending = '0';
        let is_new_arrival = '0';
        const isColorArray = [];
        const isSizeArray = [];
        const isUnitArray = [];
        const image = [];
        //const DetailsArray =  JSON.stringify(this.variantItems);
        const DetailsArray = [];
        const formData = new FormData();
        if (this.f.is_color.value == false) {
          isColors = '0';
        } else {
          isColors = '1';
        }
        if (this.f.is_size.value == false) {
          isSizes = '0';
        } else {
          isSizes = '1';
        }
        if (this.f.is_unit.value == false) {
          isUnits = '0';
        } else {
          isUnits = '1';
        }
        if (this.f.is_trending.value == false) {
          is_trending = '0';
        } else {
          is_trending = '1';
        }
        if (this.f.is_new_arrival.value == false) {
          is_new_arrival = '0';
        } else {
          is_new_arrival = '1';
        }
        this.f.category_id.value
        for (let k = 0; k < this.variantItems.length; k++) {
          isColorArray.push(this.variantItems[k].color);
          isSizeArray.push(this.variantItems[k].size);
          isUnitArray.push(this.variantItems[k].unit);
          DetailsArray.push({
            'id': this.variantItems[k].id,
            'product_name': this.variantItems[k].product_name,
            'product_sku': this.variantItems[k].product_sku,
            'mrp': this.variantItems[k].mrp,
            'sales_price': this.variantItems[k].sales_price,
            'purchase_price': this.variantItems[k].purchase_price,
            'discount': this.variantItems[k].discount,
            'color': this.variantItems[k].color,
            'colorCode': this.variantItems[k].colorCode,
            'size': this.variantItems[k].size,
            'unit': this.variantItems[k].unit,
            'packing_qty': this.variantItems[k].packing_qty,
            'master_packing_qty': this.variantItems[k].master_packing_qty,
            'stock_alert_qty': this.variantItems[k].stock_alert_qty,
            'min_order_qty': this.variantItems[k].min_order_qty,
            'quantity': this.variantItems[k].quantity,
            'is_active': this.variantItems[k].is_active,
            'product_one': this.variantItems[k].product_one,
            'product_two': this.variantItems[k].product_two,
          });
          if (this.variantItems[k].product_one != undefined) {
            formData.append("product_one[" + this.variantItems.indexOf(this.variantItems[k]) + "]", this.variantItems[k].product_one);
          }
          if (this.variantItems[k].product_two != undefined) {
            formData.append("product_two[" + this.variantItems.indexOf(this.variantItems[k]) + "]", this.variantItems[k].product_two);
          }
          //  image.push(formData.append("product_one", this.variantItems[k].product_one),formData.append("product_two", this.variantItems[k].product_two));
        }
        formData.append('product_photo_one', this.product_selected_photo_one);
        formData.append('product_photo_two', this.product_selected_photo_two);
        formData.append('product_photo_three', this.product_selected_photo_three);
        formData.append('product_photo_four', this.product_selected_photo_four);
        formData.append('category_id', this.f.category_id.value);
        formData.append('product_code', this.f.product_code.value);
        formData.append('product_name', this.f.product_name.value);
        formData.append('product_description', this.f.product_description.value);
        formData.append('is_trending', is_trending);
        formData.append('is_new_arrival', is_new_arrival);
        formData.append('is_active', this.f.is_active.value);
        formData.append('is_color', isColors);
        formData.append('is_size', isSizes);
        formData.append('is_unit', isUnits);
        formData.append('is_active', this.f.is_active.value);
        formData.append('productData', JSON.stringify(DetailsArray));
        formData.append('color_list', isColorArray.reduce((acc, value) => !acc.includes(value) ? acc.concat(value) : acc, []).join(','));
        formData.append('size_list', isSizeArray.reduce((scc, value) => !scc.includes(value) ? scc.concat(value) : scc, []).join(','));
        formData.append('unit_list', isUnitArray.reduce((ucc, value) => !ucc.includes(value) ? ucc.concat(value) : ucc, []).join(','));
        formData.append('id', this.productId);
        // console.log(this.variantItems);
        this.productsService.add(formData, this.productId).subscribe(
          data => {
            this.loading = false;
            if (data.status) {
              this.productId = 0
              this.submitted = false;
              this.variantItems = [];
              this.newForm.reset();
              this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
              this._router.navigate(['/products']);
            }
            else {
              this.error = data.error;
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          });
      }
    }
  }

  edit(id: any) {
    this.productsService.edit(id).subscribe(
      response => {
        if (response.status) {

          this.productId = response.product.id;
          this.f.product_code.setValue(response.product.product_code);
          this.f.product_name.setValue(response.product.product_name);
          this.f.product_description.setValue(response.product.product_description);
          if (response.product.sub_category_id > 0) {
            this.f.category_id.setValue(response.product.sub_category_id);
          } else {
            this.f.category_id.setValue(response.product.category_id);
          }
          this.isCheckedColor = response.product.is_color,
            this.isCheckedSize = response.product.is_size,
            this.isCheckedUnit = response.product.is_unit,
            this.isChecked = response.product.is_trending,
            this.isCheckednew = response.product.is_new_arrival,
            this.f.is_active.setValue(response.product.is_active);
          this.variantItems = response.product.variant;
          this.sectionVariantItems = true;
          response.product.productImage.forEach((value: any, index: any) => {

            if (index == 0) {
              this.product_selected_photo_oneAvatarImage = value.product_photo;
            }
            if (index == 1) {
              this.product_selected_photo_twoAvatarImage = value.product_photo;
            }
            if (index == 2) {
              this.product_selected_photo_threeAvatarImage = value.product_photo;
            }
            if (index == 3) {
              this.product_selected_photo_fourAvatarImage = value.product_photo;
            }
          });
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  uploadFile(event: any, type: any) {
    if (type == 1) {
      this.product_selected_photo_one = event.target.files[0];
      if (event.target.files && event.target.files[0]) {
        let reader = new FileReader();
        reader.onload = (event: any) => {
          this.product_selected_photo_oneAvatarImage = event.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    } else if (type == 2) {
      this.product_selected_photo_two = event.target.files[0];
      if (event.target.files && event.target.files[0]) {
        let reader = new FileReader();
        reader.onload = (event: any) => {
          this.product_selected_photo_twoAvatarImage = event.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    } else if (type == 3) {
      this.product_selected_photo_three = event.target.files[0];
      if (event.target.files && event.target.files[0]) {
        let reader = new FileReader();
        reader.onload = (event: any) => {
          this.product_selected_photo_threeAvatarImage = event.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    } else {
      this.product_selected_photo_four = event.target.files[0];
      if (event.target.files && event.target.files[0]) {
        let reader = new FileReader();
        reader.onload = (event: any) => {
          this.product_selected_photo_fourAvatarImage = event.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    }
  }

  uploadFileVariant(event: any, id: any) {
    for (let i = 0; i < this.variantItems.length; i++) {
      if (this.variantItems.indexOf(this.variantItems[i]) === id) {
        this.variantItems[i].product_one = event.target.files[0];
        //this.variantItems[i].product_one_view =  event.target.result;
      }
    }
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (event: any) => {
        for (let i = 0; i < this.variantItems.length; i++) {
          if (this.variantItems.indexOf(this.variantItems[i]) === id) {
            // this.variantItems[i].product_one = event.target.files[0];
            this.variantItems[i].product_one_view = event.target.result;
          }
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  uploadFileVariantTwo(event: any, id: any) {
    for (let i = 0; i < this.variantItems.length; i++) {
      if (this.variantItems.indexOf(this.variantItems[i]) === id) {
        this.variantItems[i].product_two = event.target.files[0];
      }
    }
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (event: any) => {
        for (let i = 0; i < this.variantItems.length; i++) {
          if (this.variantItems.indexOf(this.variantItems[i]) === id) {
            this.variantItems[i].product_two_view = event.target.result;
          }
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  getProductName(event: any) {
    this.productName = event.target.value;
  }

  checkNumber(event: any) {
    if (event.keyCode != 16 && event.keyCode != 107 && event.keyCode != 189 && event.keyCode != 109 && event.keyCode != 69) {
      //Do nothing 
    }
    else {
      event.target.value = 0;
    }
  }

  isNumberKey(event: KeyboardEvent) {
    const pattern = /^[0-9]+(\.[0-9]{1,2})?$/;
    const inputChar = String.fromCharCode(event.charCode);
    // console.log(inputChar);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      this._toastrService.error('Enter Number And Decimal Only', 'Error!', { toastClass: 'toast ngx-toastr', closeButton: true });
      event.preventDefault();
    }
    // var charCode = (evt.which) ? evt.which : evt.keyCode;
    // if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)){
    //   return false;
    // }
    // return true;
  }

  activeChange(id: any, data: any) {
    for (var i = 0; i < this.variantItems.length; i++) {
      if (this.variantItems.indexOf(this.colorArray[i]) === id) {
        this.variantItems[i].is_active = data.target.value;
      }
    }
  }


  /*CheckBox checked start*/
  checkedColor(event: any) {
    if (event == 1) {
      this.f.is_color.setValue(event);
      this.colorArray = [];
      this.addColorItem();
    }
  }
  /* Add Item */
  addColorItem() {
    this.colorArray.push({
      colorName: '',
      colorCode:'',
    });
    console.log('addColorItem 535');
    console.log(this.colorArray);
    console.log('/ addColorItem 535');
  }

  /* DeleteItem */
  deleteColorItem(id) {
    for (let i = 0; i < this.colorArray.length; i++) {
      if (this.colorArray.indexOf(this.colorArray[i]) === id) {
        this.colorArray.splice(i, 1);
        break;
      }
    }
  }

  // onChangeColorName(event:any,index:any){
  //   for (let i = 0; i < this.colorArray.length; i++) {
  //     if (this.colorArray.indexOf(this.colorArray[i]) === index) {
  //       this.colorArray[i].colorName = event.target.value
  //     }
  //   }
  // }

  checkedSize(event: any) {
    if (event == 1) {
      this.f.is_size.setValue(event);
      this.sizeArray = [];
      this.addSizeItem();
    }
  }

  /* Add Item */
  addSizeItem() {
    this.sizeArray.push({
      sizeName: ''
    });
  }

  /* DeleteItem */
  deleteSizeItem(id) {
    for (let i = 0; i < this.sizeArray.length; i++) {
      if (this.sizeArray.indexOf(this.sizeArray[i]) === id) {
        this.sizeArray.splice(i, 1);
        break;
      }
    }
  }

  // onChangeSizeName(event:any,index:any){
  //   for (let i = 0; i < this.sizeArray.length; i++) {
  //     if (this.sizeArray.indexOf(this.sizeArray[i]) === index) {
  //       this.sizeArray[i].sizeName = event.target.value
  //     }
  //   }
  // }

  checkedUnit(event: any) {
    if (event == 1) {
      this.f.is_unit.setValue(event);
      this.unitArray = [];
      this.addUnitItem();
    }
  }

  /* Add Item */
  addUnitItem() {
    this.unitArray.push({
      unitName: ''
    });
  }

  /* DeleteItem */
  deleteUnitItem(id) {
    for (let i = 0; i < this.unitArray.length; i++) {
      if (this.unitArray.indexOf(this.unitArray[i]) === id) {
        this.unitArray.splice(i, 1);
        break;
      }
    }
  }

  // onChangeUnitName(event:any,index:any){
  //   for (let i = 0; i < this.unitArray.length; i++) {
  //     if (this.unitArray.indexOf(this.unitArray[i]) === index) {
  //       this.unitArray[i].unitName = event.target.value
  //     }
  //   }
  // }

  addVariant() {
    if (this.productName == '') {
      this._toastrService.error('Please Enter Product Name!', 'Opps!');
      // this.newForm.markAllAsTouched();
      // setTimeout(() => {
      //   this.scrollToFirstInvalidControl();
      // }, 100);
    } else {

      this.sectionVariantItems = true;
      let temporaryColorArray = [];
      let temporarySizeArray = [];
      let temporaryUnitArray = [];
      let sizeVariantArray = [];
      let colorVariantArray = [];
      let unitVariantArray = [];
      
      if (this.colorArray.length > 0) {
        this.colorArray.forEach(function (element: any) {
          if (element.colorName.length == undefined) {
            temporaryColorArray.push({ 'colorName': element.colorName.name,'colorCode': element.colorCode });
            // temporaryColorArray.push({ 'colorCode': element.colorName.colorCode });
          } else if (element.colorName !== '') {
            temporaryColorArray.push({ 'colorName': element.colorName,'colorCode': element.colorCode });
            // temporaryColorArray.push({ 'colorCode': element.colorCode });
          }
        });
      }
      if (this.sizeArray.length > 0) {
        this.sizeArray.forEach(function (element: any) {
          if (element.sizeName.length == undefined) {
            temporarySizeArray.push({ 'sizeName': element.sizeName.name });
          } else if (element.sizeName !== '') {
            temporarySizeArray.push({ 'sizeName': element.sizeName });
          }
        });
      }

      if (this.unitArray.length > 0) {
        this.unitArray.forEach(function (element: any) {
          if (element.unitName.length == undefined) {
            temporaryUnitArray.push({ 'unitName': element.unitName.name });
          } else if (element.unitName !== '') {
            temporaryUnitArray.push({ 'unitName': element.unitName });
          }
        });
      }

      if (temporaryColorArray.length > 0) {
        if (this.isAddMode == true) {
          for (let h = 0; h < temporaryColorArray.length; h++) {
            if(temporaryColorArray[h].colorName){
              colorVariantArray.push({
                'id': '',
                'product_name': this.productName + ' ' + temporaryColorArray[h].colorName,
                'product_sku': '',
                'mrp': '',
                'sales_price': '',
                'purchase_price': '',
                'discount': 0,
                'color': temporaryColorArray[h].colorName,
                'colorCode': temporaryColorArray[h].colorCode,
                'size': '',
                'unit': '',
                'packing_qty': '',
                'master_packing_qty': '',
                'stock_alert_qty': 0,
                'min_order_qty': 1,
                'quantity': '',
                'is_active': 1,
                'product_one': '',
                'product_one_view': '',
                'product_two': '',
                'product_two_view': '',
              });
            }
          }
          this.variantItems = colorVariantArray;
        } else {
          for (let i = 0; i < this.variantItems.length; i++) {
            for (let k = 0; k < temporaryColorArray.length; k++) {
              colorVariantArray.push({
                'id': temporaryColorArray[k].colorName ? '' : this.variantItems[i].id,
                'product_name': this.variantItems[i].product_name ? this.variantItems[i].product_name + ' ' + temporaryColorArray[k].colorName : '',
                'product_sku': '',
                'mrp': this.variantItems[i].mrp ? this.variantItems[i].mrp : '',
                'sales_price': this.variantItems[i].sales_price ? this.variantItems[i].sales_price : '',
                'purchase_price': this.variantItems[i].purchase_price ? this.variantItems[i].purchase_price : '',
                'discount': this.variantItems[i].discount ? this.variantItems[i].discount : 0,
                'color': temporaryColorArray[k].colorName,
                'colorCode': temporaryColorArray[k].colorCode,
                'size': this.variantItems[i].size ? this.variantItems[i].size : '',
                'unit': this.variantItems[i].unit ? this.variantItems[i].unit : '',
                'packing_qty': this.variantItems[i].packing_qty ? this.variantItems[i].packing_qty : '',
                'master_packing_qty': this.variantItems[i].master_packing_qty ? this.variantItems[i].master_packing_qty : '',
                'stock_alert_qty': this.variantItems[i].stock_alert_qty ? this.variantItems[i].stock_alert_qty : 0,
                'min_order_qty': this.variantItems[i].min_order_qty ? this.variantItems[i].min_order_qty : 1,
                'quantity': this.variantItems[i].quantity ? this.variantItems[i].quantity : 0,
                'is_active': this.variantItems[i].is_active ? this.variantItems[i].is_active : 1,
                'product_one': this.variantItems[i].product_one ? this.variantItems[i].product_one : '',
                'product_two': this.variantItems[i].product_two ? this.variantItems[i].product_two : '',
                'product_one_view': this.variantItems[i].product_one_view ? this.variantItems[i].product_one_view : '',
                'product_two_view': this.variantItems[i].product_two_view ? this.variantItems[i].product_two_view : ''
              });
            }
          }
          this.variantItems = this.variantItems.concat(colorVariantArray);
        }
      }
      if (temporarySizeArray.length > 0) {
        if (this.variantItems.length > 0) {
          for (let i = 0; i < this.variantItems.length; i++) {
            for (let j = 0; j < temporarySizeArray.length; j++) {
              sizeVariantArray.push({
                'id': temporarySizeArray[j].sizeName ? '' : this.variantItems[i].id,
                'product_name': this.variantItems[i].product_name ? this.variantItems[i].product_name + ' ' + temporarySizeArray[j].sizeName : '',
                'product_sku': '',
                'mrp': this.variantItems[i].mrp ? this.variantItems[i].mrp : '',
                'sales_price': this.variantItems[i].sales_price ? this.variantItems[i].sales_price : '',
                'purchase_price': this.variantItems[i].purchase_price ? this.variantItems[i].purchase_price : '',
                'discount': this.variantItems[i].discount ? this.variantItems[i].discount : 0,
                'color': this.variantItems[i].color ? this.variantItems[i].color : '',
                'colorCode': this.variantItems[i].colorCode ? this.variantItems[i].colorCode : '',
                'size': temporarySizeArray[j].sizeName,
                'unit': this.variantItems[i].unit ? this.variantItems[i].unit : '',
                'packing_qty': this.variantItems[i].packing_qty ? this.variantItems[i].packing_qty : '',
                'master_packing_qty': this.variantItems[i].master_packing_qty ? this.variantItems[i].master_packing_qty : '',
                'stock_alert_qty': this.variantItems[i].stock_alert_qty ? this.variantItems[i].stock_alert_qty : 0,
                'min_order_qty': this.variantItems[i].min_order_qty ? this.variantItems[i].min_order_qty : 1,
                'quantity': this.variantItems[i].quantity ? this.variantItems[i].quantity : 0,
                'is_active': this.variantItems[i].is_active ? this.variantItems[i].is_active : 1,
                'product_one': this.variantItems[i].product_one ? this.variantItems[i].product_one : '',
                'product_two': this.variantItems[i].product_two ? this.variantItems[i].product_two : '',
                'product_one_view': this.variantItems[i].product_one_view ? this.variantItems[i].product_one_view : '',
                'product_two_view': this.variantItems[i].product_two_view ? this.variantItems[i].product_two_view : ''
              });
            }
          }

          if (this.isAddMode == true) {
            this.variantItems = [];
            this.variantItems = sizeVariantArray;
          } else {
            this.variantItems = this.variantItems.concat(sizeVariantArray);
          }

        } else {
          for (let j = 0; j < temporarySizeArray.length; j++) {
            sizeVariantArray.push({
              'id': '',
              'product_name': this.productName + ' ' + temporarySizeArray[j].sizeName,
              'product_sku': '',
              'mrp': '',
              'sales_price': '',
              'purchase_price': '',
              'discount': 0,
              'color': '',
              'colorCode': '',
              'size': temporarySizeArray[j].sizeName,
              'unit': '',
              'packing_qty': '',
              'master_packing_qty': '',
              'stock_alert_qty': 0,
              'min_order_qty': 1,
              'quantity': '',
              'is_active': 1,
              'product_one': '',
              'product_one_view': '',
              'product_two': '',
              'product_two_view': '',
            });
          }
          this.variantItems = sizeVariantArray;
        }
      }
      if (temporaryUnitArray.length > 0) {
        if (this.variantItems.length > 0) {
          for (let i = 0; i < this.variantItems.length; i++) {
            for (let k = 0; k < temporaryUnitArray.length; k++) {
              unitVariantArray.push({
                'id': temporaryUnitArray[k].unitName ? '' : this.variantItems[i].id,
                'product_name': this.variantItems[i].product_name ? this.variantItems[i].product_name + ' ' + temporaryUnitArray[k].unitName : '',
                'product_sku': '',
                'mrp': this.variantItems[i].mrp ? this.variantItems[i].mrp : '',
                'sales_price': this.variantItems[i].sales_price ? this.variantItems[i].sales_price : '',
                'purchase_price': this.variantItems[i].purchase_price ? this.variantItems[i].purchase_price : '',
                'discount': this.variantItems[i].discount ? this.variantItems[i].discount : 0,
                'color': this.variantItems[i].color ? this.variantItems[i].color : '',
                'colorCode': this.variantItems[i].colorCode ? this.variantItems[i].colorCode : '',
                'size': this.variantItems[i].size ? this.variantItems[i].size : '',
                'unit': temporaryUnitArray[k].unitName,
                'packing_qty': this.variantItems[i].packing_qty ? this.variantItems[i].packing_qty : 1,
                'master_packing_qty': this.variantItems[i].master_packing_qty ? this.variantItems[i].master_packing_qty : 1,
                'stock_alert_qty': this.variantItems[i].stock_alert_qty ? this.variantItems[i].stock_alert_qty : 0,
                'min_order_qty': this.variantItems[i].min_order_qty ? this.variantItems[i].min_order_qty : 1,
                'quantity': this.variantItems[i].quantity ? this.variantItems[i].quantity : 1,
                'is_active': this.variantItems[i].is_active ? this.variantItems[i].is_active : 1,
                'product_one': this.variantItems[i].product_one ? this.variantItems[i].product_one : '',
                'product_two': this.variantItems[i].product_two ? this.variantItems[i].product_two : '',
                'product_one_view': this.variantItems[i].product_one_view ? this.variantItems[i].product_one_view : '',
                'product_two_view': this.variantItems[i].product_two_view ? this.variantItems[i].product_two_view : ''
              });
            }
          }
          if (this.isAddMode == true) {
            this.variantItems = [];
            this.variantItems = unitVariantArray;
          } else {
            this.variantItems = this.variantItems.concat(unitVariantArray);
          }
        } else {
          for (let k = 0; k < temporaryUnitArray.length; k++) {
            unitVariantArray.push({
              'id': '',
              'product_name': this.productName + ' ' + temporaryUnitArray[k].unitName,
              'product_sku': '',
              'mrp': '',
              'sales_price': '',
              'purchase_price': '',
              'discount': 0,
              'color': '',
              'colorCode': '',
              'size': '',
              'unit': temporaryUnitArray[k].unitName,
              'packing_qty': '',
              'master_packing_qty': '',
              'stock_alert_qty': 0,
              'min_order_qty': 1,
              'quantity': '',
              'is_active': 1,
              'product_one': '',
              'product_one_view': '',
              'product_two': '',
              'product_two_view': '',
            });
          }
          this.variantItems = unitVariantArray;
        }
      }

      /*Array Filter Start*/
      var arrayUnique = [];
      this.variantItems.filter(function (item) {
        var i = arrayUnique.findIndex(x => (x.color == item.color && x.size == item.size && x.unit == item.unit));
        if (i <= -1) { arrayUnique.push(item); }
        return null;
      });
      this.variantItems = arrayUnique;
      /*Array Filter End*/

      this.colorArray = [];
      this.sizeArray = [];
      this.unitArray = [];
    }
  }

  deleteVariantDetail(id) {
    for (let i = 0; i < this.variantItems.length; i++) {
      if (this.variantItems.indexOf(this.variantItems[i]) === id) {
        this.variantItems.splice(i, 1);
        break;
      }
    }
  }

  addMrpVariant(event: any) {
    for (let i = 0; i < this.variantItems.length; i++) {
      this.variantItems[i].mrp = event.target.value;
    }
  }

  addSalesVariant(event: any) {
    for (let i = 0; i < this.variantItems.length; i++) {
      this.variantItems[i].sales_price = event.target.value;
    }
  }

  addPurchaseVariant(event: any) {
    for (let i = 0; i < this.variantItems.length; i++) {
      this.variantItems[i].purchase_price = event.target.value;
    }
  }

  addDiscountVariant(event: any) {
    for (let i = 0; i < this.variantItems.length; i++) {
      this.variantItems[i].discount = event.target.value;
    }
  }

  addPackingQtyVariant(event: any) {
    for (let i = 0; i < this.variantItems.length; i++) {
      this.variantItems[i].packing_qty = event.target.value;
    }
  }

  addMasterPackingQtyVariant(event: any) {
    for (let i = 0; i < this.variantItems.length; i++) {
      this.variantItems[i].master_packing_qty = event.target.value;
    }
  }

  addStockQtyVariant(event: any) {
    for (let i = 0; i < this.variantItems.length; i++) {
      this.variantItems[i].quantity = event.target.value;
    }
  }

  addStockAlertQtyVariant(event: any) {
    for (let i = 0; i < this.variantItems.length; i++) {
      this.variantItems[i].stock_alert_qty = event.target.value;
    }
  }

  addMinOrderQtyVariant(event: any) {
    for (let i = 0; i < this.variantItems.length; i++) {
      this.variantItems[i].min_order_qty = event.target.value;
    }
  }
  /*CheckBox checked end*/

  verifiedForm() {
    if (this.variantItems.length <= 0) {
      this._toastrService.error('Please Add Variant To List !!', 'Opps!');
      return false;
    } else {
      var count = 0;
      for (var i = 0; i < this.variantItems.length; i++) {
        if (this.variantItems[i].mrp == "" || this.variantItems[i].sales_price == "" || this.variantItems[i].purchase_price == "" ||
          this.variantItems[i].packing_qty == "" || this.variantItems[i].master_packing_qty == "" ||
          this.variantItems[i].quantity == "" || this.variantItems[i].stock_alert_qty == "" || this.variantItems[i].min_order_qty == "") {
          count = i
        }
      }
      if (count > 0) {
        this._toastrService.warning('Please Fill All Variant Value', 'Warning!')
        return false;
      } else {
        return true;
      }
    }
  }

  setTrending(event){
    if(event == 1){
      this.f.is_trending.setValue(event);
    }else{
      this.f.is_trending.setValue(0);
    }
  }
  
  setNew(event){
    if(event == 1){
      this.f.is_new_arrival.setValue(event);
    }else{
      this.f.is_new_arrival.setValue(0);
    }
  }

  
  public quillEditorContent = `
  <h1 class="ql-align-center">Quill Rich Text Editor</h1>
  <p><br></p>
  <p>Quill is a free, <a href="https://github.com/quilljs/quill/">open source</a> editor
    built for the modern web. With its <a href="http://quilljs.com/docs/modules/">modular
      architecture</a> and expressive <a href="http://quilljs.com/docs/api/">API</a>, it is
    completely customizable to fit any need.</p>
  <p><br></p>
  <iframe class="ql-video ql-align-center"
    src="https://www.youtube.com/embed/QHH3iSeDBLo?showinfo=0" width="560" height="238"></iframe>
  <p><br></p>
  <p><br></p>
  <h2 class="ql-align-center">Getting Started is Easy</h2>
  <p><br></p>
  <pre>// &lt;link href="https://cdn.quilljs.com/1.0.5/quill.snow.css" rel="stylesheet"&gt;
    // &lt;script src="https://cdn.quilljs.com/1.0.5/quill.min.js" type="text/javascript"&gt;&lt;/script&gt;
    var quill = new Quill('#editor', &#123;
    modules: &#123;
    toolbar: '#toolbar'
    &#125;,
    theme: 'snow'
    &#125;);
    // Open your browser's developer console to try out the API!
  </pre>
  <p><br></p>
  <p><br></p>
  <p class="ql-align-center"><strong>Built with</strong></p>
  <p class="ql-align-center">&nbsp;<span class="ql-formula"
      data-value="x^2 + (y - sqrt[3]&#123; x^2&#125;)^2 = 1"></span>&nbsp;</p>
  <p><br></p>
  `;
  
}