
import { AfterViewInit, Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { PricePerCustomerService } from 'app/_services/price-per-customer.service';
import { ProductsService } from 'app/_services/products.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'app/auth/service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-price-per-customer-add',
  templateUrl: './price-per-customer-add.component.html',
  styleUrls: ['./price-per-customer-add.component.scss']
})
export class PricePerCustomerAddComponent implements OnInit {
  @ViewChild('auto') auto: any;

  public keyword = 'name';
  public historyHeading: string = 'Recently selected';
  public isLoading: boolean = false;
  public loading: boolean = false;
  public allDealer: any;
  public dealer_details: any = [];
  public productsList: any = [];
  public productsPriceList: any = [];
  public dealersList: any = [];
  public customer_id: any = 0;
  public progressMainTable: boolean = false;

  constructor(private http: HttpClient, private productsService: ProductsService, private pricePerCustomerService: PricePerCustomerService, private _toastrService: ToastrService, private _router: Router, private _authenticationService: AuthenticationService, private route: ActivatedRoute) { }

  ngOnInit(): void {

  }

  onChangeSearch(value: string) {
    this.isLoading = true
    this.pricePerCustomerService.dealerSearch(value).subscribe(
      data => {
        this.isLoading = false;
        if (data.status) {
          this.allDealer = data.dealer_data;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
        this._toastrService.clear();
        this._toastrService.error(err.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        this.allDealer = [];
      }
    );
  }

  selectEvent(item: any) {
    if (this.dealer_details.length > 0 && this.dealer_details.some((detail: { id: any; }) => detail.id === item.id)) {
      this._toastrService.clear();
      this._toastrService.warning('Customer already added', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
    } else {
      this.dealer_details.push(item);
      this.dealer_details.reverse();
      this.progressMainTable = true;
      this.productList();
    }
    this.clear();
  }

  onFocused(e: any) {
    // do something when input is focused
  }

  clear(): void {
    this.auto.clear();
  }

  removeDealer(index: any) {
    this.dealer_details.splice(index, 1);
  }

  productList() {
    this.loading = true;
    if (this.dealer_details && this.dealer_details[0] !== undefined && this.dealer_details[0].id !== undefined) {
      this.customer_id = this.dealer_details[0].id;
    }
    else {
      this.customer_id = 0;
    }

    this.productsService.getDataPrice(0, 500, this.customer_id).subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.productsList = response.data;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }
  setNewPrice(event: any, index: any) {
    if (event.keyCode != 16 && event.keyCode != 107 && event.keyCode != 189 && event.keyCode != 109 && event.keyCode != 69) {
      this.productsList[index].newPrice = event.target.value;
    }
    else {
      event.target.value = 0;
    }
  }
  storeDealerPrice() {
    this.productsPriceList = [];
    for (var i = 0; i < this.productsList.length; i++) {
      if (this.productsList[i].newPrice !== undefined && this.productsList[i].newPrice > 0) {
        this.productsPriceList.push({
          'id': this.productsList[i].id,
          'product_variant_id': this.productsList[i].product_variant_id,
          'sales_price': this.productsList[i].newPrice
        });
      }
    }

    this.dealer_details
    for (var i = 0; i < this.dealer_details.length; i++) {
      this.dealersList.push(this.dealer_details[i].id);
    }
    this.dealersList = this.dealersList.filter((n, i) => this.dealersList.indexOf(n) === i);
    //this.dealersList = this.dealersList.map(item => item.id).filter((value, index, self) => self.indexOf(value) === index)
    const formdata = new FormData();
    formdata.append('dealers', this.dealersList);
    formdata.append('products', JSON.stringify(this.productsPriceList));

    this.isLoading = true
    this.pricePerCustomerService.storePrices(formdata).subscribe(
      data => {
        this.isLoading = false
        if (data.status) {
          this._toastrService.clear();
          this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
          this.dealersList = [];
          this.productsPriceList = [];
          this._router.navigate(['/price-per-customer']);
        }
        else {
          this._toastrService.clear();
          this._toastrService.error(data.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
        this._toastrService.clear();
        this._toastrService.error(err.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );

  }
}
