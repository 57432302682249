import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class PromocodeService {

  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(start, length): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-promocode`, { start, length })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  
  add(type: any,cashbacktype: any,name:any,percentage:any,start_date:any, end_date:any, min_order_amount:any,max_use: any, id: any): Observable<any> {
    if (id == 0) {
      return this._http
        .post(`${environment.apiUrl}save-promocode`, { type,cashbacktype,name,percentage,start_date, end_date, min_order_amount,max_use })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
    else {
      return this._http
        .post(`${environment.apiUrl}update-promocode`, { type,cashbacktype,name,percentage,start_date, end_date, min_order_amount,max_use,id })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
  }

  delete(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-promocode`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
