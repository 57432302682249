import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { DealersService } from 'app/_services/dealers.service';
import { EmployeeService } from 'app/_services/employee.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'app/auth/service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent implements OnInit {

  usersList: any = [];
  start: any = 0;
  page: any = 0;

  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 15;
  public loading = false;
  public userDetails: any;
  public progressMainTable:boolean = false;
  public addMode:boolean = false;

  constructor(private _router: Router, private _authenticationService: AuthenticationService, private employeeService: EmployeeService, private _toastrService: ToastrService, private http: HttpClient, private dealersService: DealersService, private _coreSidebarService: CoreSidebarService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.loading = true;
    this.usersLists(this.start, this.lengths);
  }

  usersLists(my_start: any, my_lengths: any) {
    this.employeeService.getData(my_start, my_lengths, '').subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.usersList = response.data;
          this.collectionSize = response.recordsTotal;
          if(response.recordsTotal >= 2){
            this.addMode = true;
          }
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  delete(id: any) {
    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.employeeService.delete(id).subscribe(
          response => {
            if (response.status) {
              me.progressMainTable = true;
              me.usersLists(me.page, me.lengths);
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Sales Executive Deleted Successfully',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }

  reloadList() {
    this.progressMainTable = true;
    this.usersLists(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.usersLists(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.usersLists(this.start, this.lengths);
  }

  /**
  * Toggle the sidebar
  *
  * @param name
  */
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  // modal Basic
  modalOpen(modalBasic, userId: any) {
    this.userDetails = [];
    this.modalService.open(modalBasic, {
      size: 'sm'
    });

    this.employeeService.dealerViewDetails(userId).subscribe(
      response => {
        if (response.status) {
          this.userDetails = response.employee;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }
}
