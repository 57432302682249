<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
      <!-- content-header component -->
      <!-- <app-content-header [contentHeader]="contentHeader"></app-content-header> -->
      <section id="basic-vertical-layouts">
        <div class="row match-height">
            <div class="col-md-12 col-12">
                <div class="card">
                    <div class="overlay" *ngIf="isLoading">
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <div class="card-header">
                        <h4 class="card-title" *ngIf="isAddMode">Add Stock Inward</h4>
                        <h4 class="card-title" *ngIf="!isAddMode">Edit Stock Inward</h4>
                    </div>
                    <div class="card-body">
                        <form class="form form-vertical " [formGroup]="addInwardForm">
                            <div class="row">
                                <div class="col-md-7 col-12">
                                    <label>Products</label>
                                    <span class="required" aria-required="true"> * </span>
                                    <ng-autocomplete  #auto #ngAutoCompleteStatic
                                    [data]="allProduct"
                                    [initialValue]=""
                                    [minQueryLength] ="2"
                                    placeholder ="Search Product by Name"
                                    [searchKeyword]="keyword"
                                    notFoundText="Not found"
                                    (selected)='selectEvent($event)'
                                    (inputChanged)='onChangeSearch($event)'
                                    (inputFocused)='onFocused($event)'
                                    [itemTemplate]="itemTemplateStatic"
                                    [isLoading]="AisLoading"
                                    [notFoundTemplate]="notFoundTemplate">
                                    </ng-autocomplete>
                                    <ng-template #itemTemplateStatic let-item>
                                        
                                    <a [innerHTML]="item.name"></a>
                                    </ng-template>
                                    <ng-template #notFoundTemplate let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                    </ng-template>
                                </div>
                                <div class="col-md-3 col-12">
                                    <label>Quantity </label> <span class="required" aria-required="true"> * </span><br>
                                    <input type="number" autocomplete="off" oninput="this.value = Math.abs(this.value)" min="0" max="1000" class="form-control text-center"  formControlName="quantity" [ngClass]="{ 'is-invalid': addInwardFormSubmit && addInwardFormControl.quantity.errors }" />
                                    <!-- <ng-container *ngIf="setQuantity == '0';else second">
                                        <core-touchspin [numberValue]="0" size="lm" [stepValue]="1" [minValue]="0" [maxValue]="1000" (onChange)="countChange($event)"></core-touchspin>
                                    </ng-container>
                                    <ng-template #second>
                                        <core-touchspin [numberValue]="0" size="lm" [stepValue]="1" [minValue]="0" [maxValue]="1000" (onChange)="countChange($event)"></core-touchspin>
                                    </ng-template> -->
                                </div>
                                <div class="col-md-2 col-12 mt-2">
                                    <button type="button" (click)="addInwardSubmit()" rippleEffect class="btn btn-success mr-1 d-block w-100" > Add</button>
                                </div>
                            </div>
                        </form>
                        <form class="form form-vertical mt-3" [formGroup]="inwardForm">
                            <div class="row">
                                <div class="col-lg-4 pt-1">
                                    <div class="form-group">
                                        <label>Select Warehouse<span class="text-danger">*</span></label>
                                        <ng-select formControlName="warehouse_id" aria-describedby="register-warehouse_id" [ngClass]="{ 'is-invalid': inwardFormSubmit && inwardFormControl.warehouse_id.errors }">
                                            <ng-option disabled selected value="0">Select Warehouse</ng-option>
                                            <ng-option *ngFor="let expCat of warehouseDropDown" [value]="expCat.id">{{expCat.warehouse_name}}</ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="col-lg-4 pt-1">
                                    <div class="form-group">
                                        <label for="date" class="form-label">Date </label> <span class="required" aria-required="true"> * </span>
                                        <div class="input-group"  >
                                          <input class="form-control" placeholder="dd-mm-yyyy" formControlName="date" id="date" [(ngModel)]="basicDPdata"
                                            ngbDatepicker #basicDP="ngbDatepicker" [ngClass]="{ 'is-invalid': inwardFormSubmit && inwardFormControl.date.errors }" [maxDate]="{year: maxYear, month: maxMonth, day: maxDate}">
                                          <div class="input-group-append">
                                            <button class="btn btn-outline-secondary feather icon-calendar" (click)="basicDP.toggle()"
                                              type="button" rippleEffect></button>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-lg-4 ">
                                    <label for="register-remarks" class="form-label">Remarks</label>
                                    <textarea rows="3" formControlName="remarks" class="form-control" placeholder="Enter Remarks" aria-describedby="register-remarks" tabindex="1" [ngClass]="{ 'is-invalid': inwardFormSubmit && inwardFormControl.remarks.errors }"></textarea>
                                </div>
                                <div class="col-lg-12 pt-1">
                                    <h4>items *</h4>
                                    <div class="table-responsive">
                                        <table class="table table-striped table-hover">
                                            <thead class="bg-gray-300">
                                                <tr class="text-center">
                                                <th width="5%">#</th>
                                                <th width="20%">Image</th>
                                                <th width="55%">Product</th>
                                                <th width="15%">Qty</th>
                                                <th width="5%" class="text-center">
                                                    <span [data-feather]="'trash-2'"></span> 
                                                </th>
                                                </tr>
                                            </thead>
                                            <tbody class="text-center">
                                                <tr *ngIf="inward_details?.length == 0" >
                                                    <td colspan="10">No data Available</td>
                                                </tr>
                                                
                                                <tr *ngFor="let detail of inward_details">
                                                    <td >{{detail.detail_id}}</td>
                                                    <td>
                                                        <img [src]="detail.product_photo" *ngIf="detail.product_photo != ''" height="40" width="40" alt="IMG" class="rounded mr-75">
                                                        <img src="assets/images/no-img.png" *ngIf="detail.product_photo == ''" height="40" width="40" alt="IMG" class="rounded mr-75">
                                                    </td>
                                                    <td>
                                                        <h6><small class="font-weight-bolder text-success"> {{detail.name}}</small> </h6>
                                                    </td>
                                                    <td>
                                                        <core-touchspin [minValue]="1" [numberValue]="detail.quantity" [size]="'lg'" (onChange)="countSubChange(detail.detail_id, $event)"></core-touchspin>
                                                    </td>
                                                    <td>
                                                        <button type="button" (click)="delete_Product_Detail(detail.detail_id)" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Delete" class="btn btn-icon btn-icon rounded-circle btn-danger" rippleEffect>
                                                            <span [data-feather]="'x-circle'"></span> 
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="pull-right pt-1">
                                        <div class="col-12">
                                            <button type="button" (click)="inwardSubmit()" rippleEffect class="btn btn-primary mr-1" [disabled]="isLoadingSubmit"> <span *ngIf="isLoadingSubmit" class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
                                            <button type="button" routerLink="/products-inward"  rippleEffect class="btn btn-outline-secondary">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
      </section>
    </div>
</div>
  