import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { SliderService } from 'app/_services/slider.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup,UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  public loadingFrm: boolean = false;
  public error = '';
  public newFormAdd: UntypedFormGroup;
  public progressMainTable: boolean = false;
  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;
  public loading: boolean = false;
  public submitted: boolean = false;
  public loadingForm: boolean = false;
  public addMode: boolean = false;
  public slidersList: any = [];
  public start: any = 0;
  public page: any = 0;
  public sliderId: any = 0;

  public image: any = '';
  public imageAvatarImage: string = 'assets/images/upload_placeholder.png';
  public readerImg: string = '';

  constructor(private _router: Router, private sliderService: SliderService, private _authenticationService: AuthenticationService, private _toastrService: ToastrService, private http: HttpClient, private _coreSidebarService: CoreSidebarService, private modalService: NgbModal, private _formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.getTransportLists(this.start, this.lengths);
    this.newFormAdd = this._formBuilder.group({
      image: ['',Validators.required]
    });
  }
  get f() {
    return this.newFormAdd.controls;
  }

  toggleSidebar(name, id: number = 0, index: any = null): void {
    this.submitted = false;
    this.error = '';
    this.newFormAdd.reset();
    this.loadingFrm = false;
    this.loadingForm = false;
    this.imageAvatarImage = 'assets/images/upload_placeholder.png';

    if (id != 0 && index != null) {
      this.sliderId = id;
      // this.f.image.setValue(this.slidersList[index].image);
      this.imageAvatarImage = this.slidersList[index].image;

    }
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  /**
  * On Submit
  */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newFormAdd.invalid) {
      console.log('error');
    }
    else {
      this.loadingFrm = true;
      const formdata = new FormData();
      formdata.append('image', this.image);
      formdata.append('id', this.sliderId);

      this.sliderService.add(formdata, this.sliderId).subscribe(
        data => {
          this.loadingForm = false;
          if (data.status) {
            this.submitted = false;
            this.toggleSidebar('new-sidebar');
            this.imageAvatarImage = 'assets/images/upload_placeholder.png';
            this.newFormAdd.reset();
            this._toastrService.clear();
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this.progressMainTable = true;
            this.getTransportLists(this.page, this.lengths);
          }
          else {
            this.error = data.error;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }

  getTransportLists(my_start: any, my_lengths: any) {
    this.sliderService.getData(my_start, my_lengths).subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.slidersList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }
  reloadList() {
    this.progressMainTable = true;
    this.getTransportLists(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.getTransportLists(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.getTransportLists(this.start, this.lengths);
  }

  // edit(index: any) {
  //   this.sliderId = this.slidersList[index].id;
  //   this.f.image.setValue(this.slidersList[index].image);

  //   this.toggleSidebar("new-sidebar", 1);
  // }

  delete(id: any) {
    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.sliderService.delete(id).subscribe(
          response => {
            if (response.status) {
              me.progressMainTable = true;
              me.getTransportLists(me.page, me.lengths);
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Transport Deleted Successfully',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            } else {
              Swal.fire({
                icon: 'warning',
                title: 'Opps!',
                text: response.message,
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }

  uploadFile(event: any, type: any) {
    if (type == 1) {
      this.image = event.target.files[0];
      if (event.target.files && event.target.files[0]) {
        let reader = new FileReader();
        reader.onload = (event: any) => {
          this.imageAvatarImage = event.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    }
  }
}
