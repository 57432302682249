import { Component, OnInit } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CategoriesService } from 'app/_services/categories.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CategoryComponent } from 'app/component/category/category.component';

@Component({
  selector: 'app-new-sidebar',
  templateUrl: './new-sidebar.component.html'
})
export class NewSidebarComponent implements OnInit {
  
  public categoryId = 0; 
  public loading = false;
  public error = '';
  public newForm: UntypedFormGroup;
  public submitted = false;
  public categoryDropDown: any;
  public selectBasicLoading = true;
  public isChecked = false;
  public parentDisabled:boolean = true;
  /**
   * Constructor
   *
   * @param {CoreSidebarService} _coreSidebarService
   */
  constructor(private categoryComponent: CategoryComponent, private _coreSidebarService: CoreSidebarService, private _toastrService: ToastrService, private categoryService: CategoriesService, private _formBuilder: UntypedFormBuilder) { }

  /**
   * Toggle the sidebar
   *
   * @param name
   */
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  /**
   * Submit
   *
   * @param form
   */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {

    }
    else {
      this.error = '';
      let p_parent = 0;
      this.loading = true;
      const { categoryName } = this.newForm.controls;
      const { is_parent } = this.newForm.controls;
      const { parent_id } = this.newForm.controls;

      if(is_parent.value == false){
        p_parent = 0;
      }else{
        p_parent = 1;
      }

      this.categoryService.add(categoryName.value, p_parent, parent_id.value,this.categoryId).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.categoryId = 0
            this.submitted = false;
            this.parentDisabled = true;
            this.newForm.reset();
            this.f.is_parent.setValue(0);
            this.f.parent_id.setValue(0);
            this.toggleSidebar('new-sidebar');
            this._toastrService.clear();
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this.categoryComponent.reloadList();
          }
          else {
            if(data.message)
            {
              this.error = data.message;
            }
            else {
              this.error = data.error;
            }
          }
        });
    }
  }

  ngOnInit(): void {
    this.parentDisabled = true;
    this.selectBasicLoading = false;
    this.newForm = this._formBuilder.group({
      categoryName: ['', [Validators.required]],
      is_parent: [''],
      parent_id: [null],
    });
    this.f.is_parent.setValue(0);
  }

  get f() {
    return this.newForm.controls;
  }

  setParentCategory(event){
    if(event == 1){
      this.f.is_parent.setValue(event);
      this.parentDisabled = false;
    }else{
      this.parentDisabled = true;
      this.f.is_parent.setValue(0);
      this.f.parent_id.setValue(0);
    }
  }
}
