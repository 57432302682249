import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { JwtInterceptorProviders } from './auth/helpers/jwt.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import { coreConfig } from 'app/app-config';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { CoreTouchspinModule } from '@core/components/core-touchspin/core-touchspin.module';

import { PdfViewerModule } from 'ng2-pdf-viewer';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { AppRoutingModule } from './app-routing.module';
import { CategoryComponent } from './component/category/category.component';
import { NewSidebarComponent } from './component/category/new-sidebar/new-sidebar.component';
import { DealersComponent } from './component/dealers/dealers.component';
import { DealersAddComponent } from './component/dealers-add/dealers-add.component';
import { ProductsComponent } from './component/products/products.component';
import { ProductsAddComponent } from './component/products-add/products-add.component';
import { OrdersComponent } from './component/orders/orders.component';
import { OrdersAddComponent } from './component/orders-add/orders-add.component';
import { DeliveryChallanComponent } from './component/delivery-challan/delivery-challan.component';
import { DeliveryChallanAddComponent } from './component/delivery-challan-add/delivery-challan-add.component';
import { ClipboardModule } from 'ngx-clipboard';
import { PricePerCustomerAddComponent } from './component/price-per-customer-add/price-per-customer-add.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPrintModule } from 'ngx-print';
import { PricePerCustomerComponent } from './component/price-per-customer/price-per-customer.component';
import { PricePerCustomerProductComponent } from './component/price-per-customer-product/price-per-customer-product.component';
import { ProfileComponent } from './component/profile/profile.component';
import { ReportsComponent } from './component/reports/reports.component';
import { ManufacturersComponent } from './component/manufacturers/manufacturers.component';
import { EmployeeComponent } from './component/employee/employee.component';
import { EmployeeAddComponent } from './component/employee-add/employee-add.component';
import { ChallanReportComponent } from './component/challan-report/challan-report.component';
import { HelpComponent } from './component/help/help.component';
import { CustomerGuideComponent } from './component/customer-guide/customer-guide.component';
import { UnitsComponent } from './component/units/units.component';
import { NewUnitComponent } from './component/units/new-unit/new-unit.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { OutstandingReportComponent } from './component/outstanding-report/outstanding-report.component';
import { ChartsModule } from 'ng2-charts';
import { UserIdleModule } from 'angular-user-idle';
import { PricingComponent } from './component/pricing/pricing.component';
import { SettingsComponent } from './component/settings/settings.component';
import { ProductInwardComponent } from './component/product-inward/product-inward.component';
import { ProductInwardAddComponent } from './component/product-inward/product-inward-add/product-inward-add.component';
import { ExpenseManageComponent } from './component/expense-manage/expense-manage.component';
import { ExpenseCategoryComponent } from './component/expense-category/expense-category.component';
import { ExpenseReportComponent } from './component/expense-report/expense-report.component';
import { DeliveryChallanReachedComponent } from './component/delivery-challan-reached/delivery-challan-reached.component';
import { PromoCodeComponent } from './component/promo-code/promo-code.component';
import { WarehouseComponent } from './component/warehouse/warehouse.component';
import { TransportMasterComponent } from './component/transport-master/transport-master.component';
import { SliderComponent } from './component/slider/slider.component';


@NgModule({
  declarations: [AppComponent, CategoryComponent, NewSidebarComponent, DealersComponent, DealersAddComponent, ProductsComponent, ProductsAddComponent, OrdersComponent, OrdersAddComponent, DeliveryChallanComponent, DeliveryChallanAddComponent, PricePerCustomerAddComponent, PricePerCustomerComponent, PricePerCustomerProductComponent, ProfileComponent, ReportsComponent, ManufacturersComponent, EmployeeComponent, EmployeeAddComponent, ChallanReportComponent, HelpComponent, CustomerGuideComponent, UnitsComponent, NewUnitComponent, OutstandingReportComponent, PricingComponent, SettingsComponent, ProductInwardComponent, ProductInwardAddComponent, ExpenseManageComponent, ExpenseCategoryComponent, ExpenseReportComponent, DeliveryChallanReachedComponent, PromoCodeComponent, WarehouseComponent, TransportMasterComponent, SliderComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot(),


    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),
    NgSelectModule,

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,

    // App modules
    LayoutModule,
    SampleModule,

    ReactiveFormsModule,
    FormsModule,
    AutocompleteLibModule,
    CoreTouchspinModule,
    ClipboardModule,
    NgxPrintModule,
    PdfViewerModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    UserIdleModule.forRoot({idle: 1200, timeout: 1200, ping: 120})

  ],
  providers: [JwtInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }
