<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="col p-0">

                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Delivery Challan Add</h4>
                    </div>
                    <div class="card-body p-0 pb-1">
                        <div class="row m-0">
                            <div class="form-group col-lg-6 " *ngIf="!viewDealerName">
                                <label class="form-label">Customer Name<span class="text-danger">*</span></label>
                                <ng-autocomplete #ngAutoCompleteStatic [data]="allDealer" name="dealers"
                                    [initialValue]="" [minQueryLength]="3"
                                    placeholder="Search Customers Min 3 Characters " [searchKeyword]="keyword"
                                    historyIdentifier="allDealer" [historyHeading]="historyHeading"
                                    [historyListMaxNumber]="3" notFoundText="Not found" (selected)='selectEvent($event)'
                                    (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)'
                                    [itemTemplate]="itemTemplateStatic" [isLoading]="isLoading"
                                    [notFoundTemplate]="notFoundTemplate">
                                </ng-autocomplete>
                                <ng-template #itemTemplateStatic let-item>
                                    <a [innerHTML]="item.name"></a>
                                </ng-template>
                                <ng-template #notFoundTemplate let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                </ng-template>
                            </div>
                            <div class="form-group col-lg-6 " *ngIf="!loadingViewDealerName && viewDealerName">
                                <label class="form-label">Customer Name<span class="text-danger">*</span></label>
                                <h4 class="text-bold">{{ dealerName }}</h4>
                            </div>
                            <div class="form-group col-lg-6 text-right pt-2" *ngIf="lr_form">
                                <button type="button" [disabled]="loading" class="btn btn-outline-dark"
                                    (click)="backDispatchProducts()"><span *ngIf="loading"
                                        class="spinner-border spinner-border-sm mr-1"></span>Back</button>
                            </div>
                            <div class="form-group col-lg-6 text-right pt-2"
                                *ngIf="set_order && orderDeliveryOrder?.length != 0">
                                <button name="submit" type="button" class="btn btn-success"
                                    (click)="previewDispatchProducts()">Save & Next</button>
                            </div>

                            <div class="form-group col-lg-12" *ngIf="viewDealerName && loadingViewDealerName">
                                <div class="text-center">
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div>
                                        Please Wait...
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card card-topline-dark order-details-table"
                    *ngIf="set_order && orderDeliveryOrder?.length != 0">
                    <div class="card-body  p-0 pb-1">
                        <div class="col">
                            <div class="row mt-2" *ngFor="let orderdetail of orderDeliveryOrder; let key = index">
                                <div class="col-6">
                                    Order ID : #<b>{{ orderdetail.invoice_no }}</b>
                                </div>
                                <div class="col-6 text-right">
                                    Date : <b>{{ orderdetail.order_date}}</b>
                                </div>
                                <div class="col mt-2 table-responsive">
                                    <table class="table table-bordered ">
                                        <tr>
                                            <th style="width:50%"> Description</th>
                                            <th style="width:10%">Qty</th>
                                            <th style="width:25%">Dispatch Qty</th>
                                            <th style="width:15%">Remaining Qty</th>
                                        </tr>
                                        <tr *ngFor="let orderProducts of orderdetail.order_details; let key = index">
                                            <td>
                                                {{ orderProducts.product_code }} {{ orderProducts.product_name }} <br>
                                                <span *ngIf="orderProducts.is_color == 1"><b>Color:</b> {{
                                                    orderProducts.color }}</span> &nbsp;
                                                <span *ngIf="orderProducts.is_size == 1"><b>Size:</b> {{
                                                    orderProducts.size }}</span> &nbsp;
                                                <span *ngIf="orderProducts.is_unit == 1"><b>Unit:</b> {{
                                                    orderProducts.unit }}</span>
                                            </td>
                                            <td>{{ orderProducts.order_qty }} </td>
                                            <td>
                                                <core-touchspin [numberValue]="orderProducts.dispatch_qty" size="lg"
                                                    [stepValue]="1" [minValue]="0" [maxValue]="1000"
                                                    (onChange)="countChange($event,orderdetail.id,orderProducts.id)"></core-touchspin>
                                            </td>
                                            <td>{{ orderProducts.remaining_qty }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="col text-right">
                            <button name="submit" type="button" class="btn btn-success"
                                (click)="previewDispatchProducts()">Save & Next</button>
                        </div>
                    </div>
                </div>

                <div class="card card-topline-dark order-details-table" *ngIf="lr_form">
                    <div class="card-body p-0 pb-1">
                        <div class="col">
                            <form name="form" [formGroup]="newForm" (ngSubmit)="onSubmit()" autocomplete="off">
                                <div class="row mt-2">
                                    <div class="form-group col-lg-6 ">
                                        <label for="register-lr_no" class="form-label">LR No</label>
                                        <input type="text" formControlName="lr_no" class="form-control"
                                            placeholder="Enter LR No" aria-describedby="register-lr_no" tabindex="1"
                                            [ngClass]="{ 'is-invalid': submitted && f.lr_no.errors }" />
                                    </div>
                                    <div class="form-group col-lg-6 ">
                                        <label for="register-lr_no" class="form-label">Transport</label>
                                        <ng-select aria-describedby="register-transport_id"
                                            formControlName="transport_id" (change)="changeTransport($event)"
                                            tabindex="1">
                                            <ng-option [value]="" selected disabled>Select Transport</ng-option>
                                            <ng-option *ngFor="let transport of transportDropDown"
                                                [value]="transport.id">{{transport.name}}</ng-option>
                                        </ng-select>
                                    </div>
                                    <div class="form-group col-lg-6 ">
                                        <label for="register-transport_name" class="form-label">Transport Name</label>
                                        <input type="text" formControlName="transport_name" class="form-control"
                                            placeholder="Enter Transport Name"
                                            aria-describedby="register-transport_name" tabindex="1"
                                            [ngClass]="{ 'is-invalid': submitted && f.transport_name.errors }" />
                                    </div>
                                    <div class="form-group col-lg-6 ">
                                        <label for="register-mobile_no" class="form-label">Mobile</label>
                                        <input type="text" formControlName="mobile_no" class="form-control"
                                            placeholder="Enter Mobile" aria-describedby="register-mobile_no"
                                            tabindex="1"
                                            [ngClass]="{ 'is-invalid': submitted && f.mobile_no.errors }" />
                                    </div>
                                    <div class="form-group col-lg-6 ">
                                        <label for="register-vehicle_no" class="form-label">Vehicle No</label>
                                        <input type="text" formControlName="vehicle_no" class="form-control"
                                            placeholder="Enter Vehicle No" aria-describedby="register-vehicle_no"
                                            tabindex="1"
                                            [ngClass]="{ 'is-invalid': submitted && f.vehicle_no.errors }" />
                                    </div>
                                    <div class="form-group col-lg-12 ">
                                        <label for="register-remarks" class="form-label">Remarks</label>
                                        <textarea rows="3" formControlName="remarks" class="form-control"
                                            placeholder="Enter Remarks" aria-describedby="register-remarks" tabindex="1"
                                            [ngClass]="{ 'is-invalid': submitted && f.remarks.errors }"></textarea>
                                    </div>

                                    <div class="form-group col-lg-12 table-responsive">
                                        <table class="table table-bordered ">
                                            <tr>
                                                <th style="width:45%">Description</th>
                                                <th style="width:20%">Rate</th>
                                                <th style="width:15%">Qty</th>
                                                <th style="width:20%">Total</th>
                                            </tr>
                                            <tr
                                                *ngFor="let dispatchProducts of dispatchProductsNewFinalOrder; let key = index">
                                                <td>
                                                    {{ dispatchProducts.product_code }} {{ dispatchProducts.product_name
                                                    }} <br>
                                                    <span
                                                        *ngIf="dispatchProducts.color != ''  && dispatchProducts.color !=  null"><b>Color:</b>
                                                        {{ dispatchProducts.color }}</span> &nbsp;
                                                    <span
                                                        *ngIf="dispatchProducts.size != '' && dispatchProducts.size !=  null"><b>Size:</b>
                                                        {{ dispatchProducts.size }}</span> &nbsp;
                                                    <span
                                                        *ngIf="dispatchProducts.unit != '' && dispatchProducts.unit !=  null"><b>Unit:</b>
                                                        {{ dispatchProducts.unit }}</span>
                                                </td>
                                                <td>
                                                    <input type="number"
                                                        value="{{ dispatchProducts.display_price.toFixed(2)  }}" min="0"
                                                        name="new_sales_price" class="form-control"
                                                        (keyup)="priceChange($event,dispatchProducts.product_id,key)" />
                                                </td>
                                                <td>
                                                    {{ dispatchProducts.dispatch_qty }}
                                                </td>
                                                <td>{{ dispatchProducts.sub_total.toFixed(2) }}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class="p-0">
                                                    <table class="table">
                                                        <tr>
                                                            <td colspan="2" class="text-right">Sub Total</td>
                                                            <td style="width:150px;"> ₹ {{
                                                                getSum('sub_total').toFixed(2) }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-right">Discount</td>
                                                            <td> <input type="number" min="0" max="100"
                                                                    class="form-control" formControlName="main_discount"
                                                                    placeholder="Enter %"
                                                                    (keyup)="totalCalc($event,'main_discount')" /> </td>
                                                            <td> ₹ <span class="main_discount"> {{
                                                                    main_discount.toFixed(2) }}</span> </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-right"><input type="text"
                                                                    class="form-control" formControlName="tax_one"
                                                                    placeholder="CGST (Tax One)" /></td>
                                                            <td> <input type="number" min="0" max="100"
                                                                    class="form-control" formControlName="tax_one_pre"
                                                                    placeholder="Tax One %"
                                                                    (keyup)="totalCalc($event,'tax_one_pre')" /> </td>
                                                            <td> ₹ <span class="tax_one_amt">{{ tax_one_amt.toFixed(2)
                                                                    }}</span> </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-right"><input type="text"
                                                                    class="form-control" formControlName="tax_two"
                                                                    placeholder="SGST (Tax Two)" /></td>
                                                            <td> <input type="number" min="0" max="100"
                                                                    class="form-control" formControlName="tax_two_pre"
                                                                    placeholder="Tax Two %"
                                                                    (keyup)="totalCalc($event,'tax_two_pre')" /> </td>
                                                            <td> ₹ <span class="tax_two_amt">{{ tax_two_amt.toFixed(2)
                                                                    }}</span> </td>
                                                        </tr>
                                                        <!-- New Added Fields -->
                                                        <tr>
                                                            <td class="text-right"><input type="text"
                                                                    class="form-control"
                                                                    formControlName="transport_charge_label"
                                                                    placeholder="Eg. Transport Charge" /></td>
                                                            <td> <input type="number" min="0" max="100"
                                                                    class="form-control"
                                                                    formControlName="transport_charge"
                                                                    placeholder="Transport Charge"
                                                                    (keyup)="totalCalc($event,'transport_charge')" />
                                                            </td>
                                                            <td> ₹ <span class="transport_charge">{{
                                                                    transport_charge_amt.toFixed(2) }}</span> </td>
                                                        </tr>

                                                        <tr>
                                                            <td class="text-right"><input type="text"
                                                                    class="form-control"
                                                                    formControlName="packing_charge_label"
                                                                    placeholder="Eg. Packing Charge" /></td>
                                                            <td> <input type="number" min="0" max="100"
                                                                    class="form-control"
                                                                    formControlName="packing_charge"
                                                                    placeholder="Packing Charge"
                                                                    (keyup)="totalCalc($event,'packing_charge')" />
                                                            </td>
                                                            <td> ₹ <span class="packing_charge">{{
                                                                    packing_charge_amt.toFixed(2) }}</span> </td>
                                                        </tr>

                                                        <tr>
                                                            <td class="text-right"><input type="text"
                                                                    class="form-control"
                                                                    formControlName="extra_charge_label"
                                                                    placeholder="Eg. Extra Charge" /></td>
                                                            <td> <input type="number" min="0" max="100"
                                                                    class="form-control" formControlName="extra_charge"
                                                                    placeholder="Extra Charge"
                                                                    (keyup)="totalCalc($event,'extra_charge')" /> </td>
                                                            <td> ₹ <span class="extra_charge">{{
                                                                    extra_charge_amt.toFixed(2) }}</span> </td>
                                                        </tr>
                                                        <!-- / New Added Fields -->

                                                        <tr>
                                                            <td colspan="2" class="text-right">Round Off</td>
                                                            <td> ₹ <span class="grand_total">{{ round_off.toFixed(2)
                                                                    }}</span> </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2" class="text-right">Grand Total</td>
                                                            <td> ₹ <span class="grand_total">{{
                                                                    getGrandTotal().toFixed(2) }}</span> </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="col col-sm-4 col-xs-4 col-lg-2 pt-1">
                                        <button type="button" [disabled]="loading"
                                            class="btn btn-outline-dark btn-block"
                                            (click)="backDispatchProducts()"><span *ngIf="loading"
                                                class="spinner-border spinner-border-sm mr-1"></span>Back</button>
                                    </div>
                                    <div class="col col-sm-12 col-xs-12 col-lg-6">
                                        <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                                            <div class="alert-body">
                                                <p>{{ error }}</p>
                                            </div>
                                        </ngb-alert>
                                    </div>



                                    <div class="col col-sm-4 col-xs-4 col-lg-2 pt-1">
                                        <button [disabled]="loading" type="button" routerLink="/delivery-challan"
                                            class="btn btn-secondary btn-block" tabindex="15" rippleEffect>
                                            <span *ngIf="loading"
                                                class="spinner-border spinner-border-sm mr-1"></span>Cancel
                                        </button>
                                    </div>
                                    <div class="col col-sm-4 col-xs-4 col-lg-2 pt-1">
                                        <button [disabled]="loading" class="btn btn-primary btn-block" tabindex="14"
                                            rippleEffect>
                                            <span *ngIf="loading"
                                                class="spinner-border spinner-border-sm mr-1"></span>Save
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>