<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title" *ngIf="isAddMode">Add Products</h4>
                            <h4 class="card-title" *ngIf="!isAddMode">Edit Products</h4>
                        </div>
                        <div class="card-body pb-1">
                            <form name="form" [formGroup]="newForm" autocomplete="off">
                                <div class="row">
                                    <div class="form-group col-lg-3">
                                        <label for="register-category_id" class="form-label">Select Category</label>
                                        <ng-select [items]="categoryDropDown" id="product_id" [clearable]="false"
                                            [loading]="selectBasicLoading" bindValue="id" groupBy="parent_name"
                                            bindLabel="category_name" tabindex="1" formControlName="category_id"
                                            placeholder="Select Category"
                                            [ngClass]="{ 'is-invalid': submitted && f.category_id.errors }">
                                            <ng-template ng-optgroup-tmp let-item="item">
                                                {{item.parent_name || 'Other'}}
                                            </ng-template>
                                        </ng-select>
                                        <div *ngIf="submitted && f.category_id.errors" class="invalid-feedback">
                                            <div *ngIf="f.category_id.errors.required">Category is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-3 ">
                                        <label for="register-product_code" class="form-label">Product Code</label>
                                        <input type="text" formControlName="product_code" class="form-control"
                                            placeholder="Product Code" aria-describedby="register-product_code"
                                            tabindex="2"
                                            [ngClass]="{ 'is-invalid': submitted && f.product_code.errors }" />
                                    </div>
                                    <div class="form-group col-lg-3 ">
                                        <label for="register-product_name" class="form-label">Product Name<span
                                                class="text-danger">*</span></label>
                                        <input type="text" formControlName="product_name" class="form-control"
                                            (change)="getProductName($event)" id="product_name"
                                            placeholder="Product Name" aria-describedby="register-product_name"
                                            tabindex="3"
                                            [ngClass]="{ 'is-invalid': submitted && f.product_name.errors }" />
                                        <div *ngIf="submitted && f.product_name.errors" class="invalid-feedback">
                                            <div *ngIf="f.product_name.errors.required">Product Name is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-3 ">
                                        <label for="register-product_description" class="form-label">Product Description<span
                                                class="text-danger"></span></label>
                                        <input type="text" formControlName="product_description" class="form-control"
                                            (change)="getProductName($event)" id="product_description"
                                            placeholder="Product Description" aria-describedby="register-product_description"
                                            tabindex="3"
                                            [ngClass]="{ 'is-invalid': submitted && f.product_description.errors }" />
                                        <div *ngIf="submitted && f.product_description.errors" class="invalid-feedback">
                                            <div *ngIf="f.product_description.errors.required">Product Description is required</div>
                                        </div>
                                    </div>

                                    <div class="form-group col-lg-4">
                                        <label for="register-is_active" class="form-label">Status<span
                                                class="text-danger">*</span></label>
                                        <select formControlName="is_active" class="form-control"
                                            aria-describedby="register-is_active" tabindex="17"
                                            [ngClass]="{ 'is-invalid': submitted && f.is_active.errors }">
                                            <option value="1" selected>Active</option>
                                            <option value="0">Deactivate</option>
                                        </select>
                                        <span class="text-mute mt-1"><b>Note : </b>Please upload square image for better
                                            resolution, like 200*200, 400*400, 550*550</span>
                                    </div>
                                    <div class="form-group col-lg-2">
                                        <div class="card card-upload">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-12 text-center">
                                                        <img [src]="product_selected_photo_oneAvatarImage" alt="Upload"
                                                            class="img-fluid">
                                                    </div>
                                                    <div class="col-12">
                                                        <label for="register-product_photo_one"
                                                            class="form-label">Product Photo 1</label>
                                                        <input class="form-control" type="file" id="product_photo_one"
                                                            (change)="uploadFile($event,1)" name="product_photo_one"
                                                            formControlName="product_photo_one" tabindex="13"
                                                            [ngClass]="{ 'is-invalid': submitted && f.product_photo_one.errors }">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-2">
                                        <div class="card card-upload">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-12 text-center">
                                                        <img [src]="product_selected_photo_twoAvatarImage" alt="Upload"
                                                            class="img-fluid">
                                                    </div>
                                                    <div class="col-12">
                                                        <label for="register-product_photo_two"
                                                            class="form-label">Product Photo 2</label>
                                                        <input class="form-control" type="file" id="product_photo_two"
                                                            (change)="uploadFile($event,2)" name="product_photo_two"
                                                            formControlName="product_photo_two" tabindex="14"
                                                            [ngClass]="{ 'is-invalid': submitted && f.product_photo_two.errors }">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-2">
                                        <div class="card card-upload">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-12 text-center">
                                                        <img [src]="product_selected_photo_threeAvatarImage"
                                                            alt="Upload" class="img-fluid">
                                                    </div>
                                                    <div class="col-12">
                                                        <label for="register-product_photo_three"
                                                            class="form-label">Product Photo 3 </label>
                                                        <input class="form-control" type="file" id="product_photo_three"
                                                            (change)="uploadFile($event,3)" name="product_photo_three"
                                                            formControlName="product_photo_three" tabindex="15"
                                                            [ngClass]="{ 'is-invalid': submitted && f.product_photo_three.errors }">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-2">
                                        <div class="card card-upload">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-12 text-center">
                                                        <img [src]="product_selected_photo_fourAvatarImage" alt="Upload"
                                                            class="img-fluid">
                                                    </div>
                                                    <div class="col-12">
                                                        <label for="register-product_photo_four"
                                                            class="form-label">Product Photo 4</label>
                                                        <input class="form-control" type="file" id="product_photo_four"
                                                            (change)="uploadFile($event,4)" name="product_photo_four"
                                                            formControlName="product_photo_four" tabindex="16"
                                                            [ngClass]="{ 'is-invalid': submitted && f.product_photo_four.errors }">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-6">
                                        <label for="register-category_id" class="form-label">Check Variant <span
                                                class="text-danger">*</span></label>
                                        <div class="d-sm-flex">
                                            <div class="custom-control custom-control-primary custom-checkbox mr-2">
                                                <input type="checkbox" class="custom-control-input"
                                                    formControlName="is_color" id="is_color"
                                                    [(ngModel)]="isCheckedColor"
                                                    (change)="checkedColor(isCheckedColor?'1':'0')" />
                                                <label class="custom-control-label" for="is_color">Color Name</label>
                                            </div>

                                            <div class="custom-control custom-control-primary custom-checkbox mr-2">
                                                <input type="checkbox" class="custom-control-input"
                                                    formControlName="is_size" id="is_size" [(ngModel)]="isCheckedSize"
                                                    (change)="checkedSize(isCheckedSize?'1':'0')" />
                                                <label class="custom-control-label" for="is_size">Size Name</label>
                                            </div>

                                            <div class="custom-control custom-control-primary custom-checkbox mr-2">
                                                <input type="checkbox" class="custom-control-input"
                                                    formControlName="is_unit" id="is_unit" [(ngModel)]="isCheckedUnit"
                                                    (change)="checkedUnit(isCheckedUnit?'1':'0')" />
                                                <label class="custom-control-label" for="is_unit">Unit Name</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-6">
                                        <label for="register-category_id" class="form-label">Trending / Arrival <span
                                                class="text-danger">*</span>
                                        </label>
                                        <div class="d-sm-flex">
                                            <div class="custom-control custom-control-primary custom-checkbox mr-2">
                                                <input type="checkbox" class="custom-control-input"
                                                    formControlName="is_trending" [(ngModel)]="isChecked"
                                                    (change)="setTrending(isChecked?'1':'0');" id="is_trending" />
                                                <label class="custom-control-label" for="is_trending">Trending</label>
                                            </div>
                                            <div class="custom-control custom-control-primary custom-checkbox mr-2">
                                                <input type="checkbox" class="custom-control-input"
                                                    formControlName="is_new_arrival" [(ngModel)]="isCheckednew"
                                                    (change)="setNew(isCheckednew?'1':'0');" id="is_new_arrival" />
                                                <label class="custom-control-label" for="is_new_arrival">New
                                                    arrival</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <!-- Variant Clone start-->
                            <div class="form-group col-lg-12">
                                <div class="divider divider-primary" *ngIf="isCheckedColor">
                                    <div class="divider-text">Color Wise</div>
                                </div>
                                <div class="form-group col-lg-12" *ngIf="isCheckedColor">
                                    <div class="row d-flex align-items-center">
                                        <div *ngFor="let co of colorArray; let i = index">
                                            <div class="col-12 col-md-12 col-lg-12 mb-1 mb-md-2">
                                                <label for="itemName{{ i }}">Color Name</label>
                                                <div class="input-group">
                                                    <ng-autocomplete #ngAutoCompleteStatic [data]="allColor" name="name"
                                                        [initialValue]="" [minQueryLength]="1"
                                                        placeholder="Search Color " [searchKeyword]="keyword"
                                                        historyIdentifier="allColor" [historyHeading]="historyHeading"
                                                        [historyListMaxNumber]="0" [(ngModel)]="co.colorName"
                                                        (selected)='selectEventColor($event, i)' [clearable]="false"
                                                        (inputChanged)='onChangeColor($event)'
                                                        [itemTemplate]="itemTemplateStatic" [isLoading]="isLoading"
                                                        class="w-75" disableClearable>
                                                    </ng-autocomplete>
                                                    <ng-template #itemTemplateStatic let-item>
                                                        <a [innerHTML]="item.name"></a>
                                                    </ng-template>
                                                    <!-- <ng-template #notFoundTemplate let-notFound>
                                                        <div [innerHTML]="notFound"></div>
                                                    </ng-template> -->
                                                    <!-- <input type="text" class="form-control" [(ngModel)]="co.colorName"  name="colorName{{ i }}" id="colorName{{ i }}" value="{{co.colorName}}" (change)="onChangeColorName($event,i)" placeholder="Enter Like Blue" /> -->
                                                    <div class="input-group-append" *ngIf="i !== 0">
                                                        <span
                                                            class="input-group-text cursor-pointer bg-danger bg-darken-4"
                                                            (click)="deleteColorItem(i)">
                                                            <i data-feather="trash-2" [class]="'text-white'"
                                                                [size]="20"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <input type="color" [(ngModel)]="co.colorCode" class="form-control"
                                                    placeholder="Color Code" aria-describedby="register-colorCode"
                                                    value="#000000"
                                                    [ngClass]="{ 'is-invalid': submitted && f.colorCode.errors }" />
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-2 col-lg-2">
                                            <button class="btn btn-icon btn-primary" type="button"
                                                (click)="addColorItem()" rippleEffect>
                                                <i data-feather="plus"></i>
                                                <span>Add Color</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="divider divider-primary" *ngIf="isCheckedSize">
                                    <div class="divider-text">Size Wise</div>
                                </div>
                                <div class="form-group col-lg-12" *ngIf="isCheckedSize">
                                    <div class="row d-flex align-items-center">
                                        <div *ngFor="let si of sizeArray; let s = index">
                                            <div class="col-12 col-md-12 col-lg-12 mb-1 mb-md-2">
                                                <label for="itemName{{ s }}">Size Name</label>
                                                <div class="input-group">
                                                    <ng-autocomplete #ngAutoCompleteStatic [data]="allSize" name="name"
                                                        [initialValue]="" [minQueryLength]="1"
                                                        placeholder="Enter Like L" [searchKeyword]="keywordSize"
                                                        historyIdentifier="allSize"
                                                        [historyHeading]="historyHeadingSize" [historyListMaxNumber]="0"
                                                        [(ngModel)]="si.sizeName"
                                                        (selected)='selectEventSize($event, s)'
                                                        (inputChanged)='onChangeSize($event)'
                                                        [itemTemplate]="itemTemplateStatic" [isLoading]="isLoadingSize"
                                                        class="w-100" disableClearable>
                                                    </ng-autocomplete>
                                                    <ng-template #itemTemplateStatic let-item>
                                                        <a [innerHTML]="item.name"></a>
                                                    </ng-template>
                                                    <!-- <input type="text" class="form-control" [(ngModel)]="si.sizeName"  name="sizeName{{ s }}" id="sizeName{{ s }}" value="{{si.sizeName}}" (change)="onChangeSizeName($event,s)" placeholder="Enter Like L" /> -->
                                                    <div class="input-group-append" *ngIf="s !== 0">
                                                        <span
                                                            class="input-group-text cursor-pointer bg-danger bg-darken-4"
                                                            (click)="deleteSizeItem(s)">
                                                            <i data-feather="trash-2" [class]="'text-white'"
                                                                [size]="20"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-2 col-lg-2 ">
                                            <button class="btn btn-icon btn-primary" type="button"
                                                (click)="addSizeItem()" rippleEffect>
                                                <i data-feather="plus"></i>
                                                <span>Add Size</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="divider divider-primary" *ngIf="isCheckedUnit">
                                    <div class="divider-text">Unit Wise</div>
                                </div>
                                <div class="form-group col-lg-12" *ngIf="isCheckedUnit">
                                    <div class="row d-flex align-items-end">
                                        <div *ngFor="let ui of unitArray; let u = index">
                                            <div class="col-12 col-md-12 col-lg-12">
                                                <label for="itemName{{ ui }}">Unit Name</label>
                                                <div class="input-group">
                                                    <ng-autocomplete #ngAutoCompleteStatic [data]="allUnit" name="name"
                                                        [initialValue]="" [minQueryLength]="1"
                                                        placeholder="Enter Like Kg" [searchKeyword]="keywordUnit"
                                                        historyIdentifier="allUnit"
                                                        [historyHeading]="historyHeadingUnit" [historyListMaxNumber]="0"
                                                        [(ngModel)]="ui.unitName"
                                                        (selected)='selectEventUint($event, u)'
                                                        (inputChanged)='onChangeUnit($event)'
                                                        [itemTemplate]="itemTemplateStatic" [isLoading]="isLoadingUnit"
                                                        class="w-75" disableClearable>
                                                    </ng-autocomplete>
                                                    <ng-template #itemTemplateStatic let-item>
                                                        <a [innerHTML]="item.name"></a>
                                                    </ng-template>
                                                    <!-- <input type="text" class="form-control" [(ngModel)]="ui.unitName"  name="unitName{{ u }}" id="unitName{{ u }}" value="{{ui.unitName}}" (change)="onChangeUnitName($event,u)" placeholder="Enter Like Kg" /> -->
                                                    <div class="input-group-append" *ngIf="u !== 0">
                                                        <span
                                                            class="input-group-text cursor-pointer bg-danger bg-darken-4"
                                                            (click)="deleteUnitItem(u)">
                                                            <i data-feather="trash-2" [class]="'text-white'"
                                                                [size]="20"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-2 col-lg-2 mb-1 mb-sm-0">
                                            <button class="btn btn-icon btn-primary" type="button"
                                                (click)="addUnitItem()" rippleEffect>
                                                <i data-feather="plus"></i>
                                                <span>Add Unit</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="isCheckedColor || isCheckedSize || isCheckedUnit">
                                    <div class="col-12 text-right">
                                        <button class="btn btn-icon btn-primary" type="button" (click)="addVariant()"
                                            rippleEffect>
                                            <i data-feather="plus" class="mr-25"></i>
                                            <span>Add Variant</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 pt-1" *ngIf="sectionVariantItems">
                                <h4>Variant Items *</h4>
                                <div class="table-responsive">
                                    <table class="table table-striped table-hover variant">
                                        <thead class="bg-gray-300">
                                            <tr class="text-center">
                                                <th>Name</th>
                                                <th>Sku</th>
                                                <th *ngIf="isCheckedColor">Color</th>
                                                <th *ngIf="isCheckedSize">Size</th>
                                                <th *ngIf="isCheckedUnit">Unit</th>
                                                <th>Mrp</th>
                                                <th>Sales <br>Price</th>
                                                <th>Purchase <br>Price</th>
                                                <th>Dis<br>count</th>
                                                <th>Packing <br>Qty</th>
                                                <th>Master <br>P. Qty</th>
                                                <th>Stock <br>Qty</th>
                                                <th>Stock <br>Alert Qty</th>
                                                <th>Min <br>Order Qty</th>
                                                <th>Active</th>
                                                <th>Photos</th>
                                                <th><i data-feather="trash-2" [size]="20"></i></th>
                                            </tr>
                                        </thead>
                                        <tbody class="text-center">
                                            <tr *ngIf="variantItems?.length == 0">
                                                <td colspan="18">No data Available</td>
                                            </tr>
                                            <tr *ngIf="variantItems?.length != 0">
                                                <td></td>
                                                <td></td>
                                                <td *ngIf="isCheckedColor"></td>
                                                <td *ngIf="isCheckedSize"></td>
                                                <td *ngIf="isCheckedUnit"></td>
                                                <td class="py-2 text-center">
                                                    <input type="text" class="form-control text-center"
                                                        placeholder="For all variant"
                                                        (change)="addMrpVariant($event)" />
                                                </td>
                                                <td class="py-2 text-center">
                                                    <input type="text" class="form-control text-center"
                                                        placeholder="For all variant"
                                                        (change)="addSalesVariant($event)" />
                                                </td>
                                                <td class="py-2 text-center">
                                                    <input type="text" class="form-control text-center"
                                                        placeholder="For all variant"
                                                        (change)="addPurchaseVariant($event)" />
                                                </td>
                                                <td class="py-2 text-center">
                                                    <input type="text" class="form-control text-center width-40"
                                                        placeholder="For all variant"
                                                        (change)="addDiscountVariant($event)" />
                                                </td>
                                                <td class="py-2 text-center">
                                                    <input type="text" class="form-control text-center width-40"
                                                        placeholder="For all variant"
                                                        (change)="addPackingQtyVariant($event)" />
                                                </td>
                                                <td class="py-2 text-center">
                                                    <input type="text" class="form-control text-center width-40"
                                                        placeholder="For all variant"
                                                        (change)="addMasterPackingQtyVariant($event)" />
                                                </td>
                                                <td class="py-2 text-center">
                                                    <input type="text" class="form-control text-center"
                                                        placeholder="For all variant"
                                                        (change)="addStockQtyVariant($event)" />
                                                </td>
                                                <td class="py-2 text-center">
                                                    <input type="text" class="form-control text-center"
                                                        placeholder="For all variant"
                                                        (change)="addStockAlertQtyVariant($event)" />
                                                </td>
                                                <td class="py-2 text-center">
                                                    <input type="text" class="form-control text-center width-40"
                                                        placeholder="For all variant"
                                                        (change)="addMinOrderQtyVariant($event)" />
                                                </td>
                                                <td colspan="3"></td>
                                            </tr>
                                            <tr class="text-center" *ngFor="let vi of variantItems, let a = index">
                                                <td class="py-2 text-center">
                                                    <input type="text" class="form-control text-center width-200"
                                                        [(ngModel)]="vi.product_name" name="product_name{{ a }}"
                                                        id="product_name{{ a }}" />
                                                </td>
                                                <td class="py-2 text-center">
                                                    <input type="text" class="form-control text-center width-80"
                                                        [(ngModel)]="vi.product_sku" name="product_sku{{ a }}"
                                                        id="product_sku{{ a }}" />
                                                </td>
                                                <td class="py-2 text-center" *ngIf="isCheckedColor">
                                                    <input type="text" class="form-control text-center width-80"
                                                        [(ngModel)]="vi.color" name="color{{ a }}" [readOnly]="true"
                                                        id="color{{ a }}" />
                                                    <input type="hidden" class="form-control text-center width-80"
                                                        [(ngModel)]="vi.colorCode" name="colorCode{{ a }}"
                                                        [readOnly]="true" id="colorCode{{ a }}" />
                                                </td>
                                                <td class="py-2 text-center" *ngIf="isCheckedSize">
                                                    <input type="text" class="form-control text-center width-80"
                                                        [(ngModel)]="vi.size" name="size{{ a }}" [readOnly]="true"
                                                        id="size{{ a }}" />
                                                </td>
                                                <td class="py-2 text-center" *ngIf="isCheckedUnit">
                                                    <input type="text" class="form-control text-center width-80"
                                                        [(ngModel)]="vi.unit" name="unit{{ a }}" [readOnly]="true"
                                                        id="unit{{ a }}" />
                                                </td>
                                                <td class="py-2 text-center">
                                                    <input type="text" #mrp class="form-control text-center width-80"
                                                        [(ngModel)]="vi.mrp" name="mrp{{ a }}" id="mrp{{ a }}"
                                                        (keypress)="isNumberKey($event)" />
                                                </td>
                                                <td class="py-2 text-center">
                                                    <input type="text" class="form-control text-center width-80"
                                                        [(ngModel)]="vi.sales_price" name="sales_price{{ a }}"
                                                        id="sales_price{{ a }}" (keypress)="isNumberKey($event)" />
                                                </td>
                                                <td class="py-2 text-center">
                                                    <input type="text" class="form-control text-center width-80"
                                                        [(ngModel)]="vi.purchase_price" name="purchase_price{{ a }}"
                                                        id="purchase_price{{ a }}" (keypress)="isNumberKey($event)" />
                                                </td>
                                                <td class="py-2 text-center">
                                                    <input type="text" class="form-control text-center width-40"
                                                        [(ngModel)]="vi.discount" name="discount{{ a }}"
                                                        id="discount{{ a }}" (keypress)="isNumberKey($event)" />
                                                </td>
                                                <td class="py-2 text-center">
                                                    <input type="text" class="form-control text-center width-40"
                                                        [(ngModel)]="vi.packing_qty" name="packing_qty{{ a }}"
                                                        id="packing_qty{{ a }}" (keypress)="isNumberKey($event)" />
                                                </td>
                                                <td class="py-2 text-center">
                                                    <input type="text" class="form-control text-center width-40"
                                                        [(ngModel)]="vi.master_packing_qty"
                                                        name="master_packing_qty{{ a }}" id="master_packing_qty{{ a }}"
                                                        (keypress)="isNumberKey($event)" />
                                                </td>
                                                <td class="py-2 text-center">
                                                    <input type="text" class="form-control text-center width-80"
                                                        [(ngModel)]="vi.quantity" name="quantity{{ a }}"
                                                        id="quantity{{ a }}" (keypress)="isNumberKey($event)" />
                                                </td>
                                                <td class="py-2 text-center">
                                                    <input type="text" class="form-control text-center width-80"
                                                        [(ngModel)]="vi.stock_alert_qty" name="stock_alert_qty{{ a }}"
                                                        id="stock_alert_qty{{ a }}" (keypress)="isNumberKey($event)" />
                                                </td>
                                                <td class="py-2 text-center">
                                                    <input type="text" class="form-control text-center width-40"
                                                        [(ngModel)]="vi.min_order_qty" name="min_order_qty{{ a }}"
                                                        id="min_order_qty{{ a }}" (keypress)="isNumberKey($event)" />
                                                </td>
                                                <td class="py-2 text-center">
                                                    <select class="form-control width-80"
                                                        (change)="activeChange(a, $event)">
                                                        <option [selected]="1 === vi.is_active" [value]="1">Active
                                                        </option>
                                                        <option [selected]="0 === vi.is_active" [value]="0">Deactivate
                                                        </option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <div class="d-flex mt-1">
                                                        <div class="width-75">
                                                            <div class="custom-file">
                                                                <input type="file" class="custom-file-input w-auto"
                                                                    type="file" id="product_one{{a}}"
                                                                    name="product_one{{ a }}"
                                                                    (change)="uploadFileVariant($event,a)" />
                                                                <label class="custom-file-label"
                                                                    for="product_one{{a}}"></label>
                                                            </div>
                                                        </div>
                                                        <div class="text-center width-75 ml-1">
                                                            <img *ngIf="vi.product_one_view != ''"
                                                                [src]="vi.product_one_view" alt="Upload" width="80"
                                                                class="img-fluid">
                                                        </div>
                                                    </div>
                                                    <div class="d-flex mt-1">
                                                        <div class="width-75">
                                                            <div class="custom-file">
                                                                <input type="file" class="custom-file-input w-auto"
                                                                    type="file" id="product_two{{a}}"
                                                                    name="product_two{{ a }}"
                                                                    (change)="uploadFileVariantTwo($event,a)" />
                                                                <label class="custom-file-label"
                                                                    for="product_two{{a}}"></label>
                                                            </div>
                                                        </div>
                                                        <div class="text-center width-75 ml-1">
                                                            <img *ngIf="vi.product_two_view != ''"
                                                                [src]="vi.product_two_view" alt="Upload" width="80"
                                                                class="img-fluid">
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="py-2 text-center">
                                                    <button type="button" (click)="deleteVariantDetail(a)"
                                                        data-toggle="tooltip" data-popup="tooltip-custom"
                                                        placement="top" container="body" ngbTooltip="Delete"
                                                        class="btn btn-icon btn-icon rounded-circle btn-danger"
                                                        rippleEffect>
                                                        <span [data-feather]="'x-circle'"></span>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- Variant Clone End-->

                            <div class="row" *ngIf="sectionVariantItems">
                                <div class="col col-sm-6 col-xs-6 col-lg-2 pt-1">
                                    <button [disabled]="loading" type="button" routerLink="/products"
                                        class="btn btn-secondary btn-block" tabindex="19" rippleEffect>
                                        <span *ngIf="loading"
                                            class="spinner-border spinner-border-sm mr-1"></span>Cancel
                                    </button>
                                </div>
                                <div class="col col-sm-6 col-xs-6 offset-lg-8 col-lg-2 pt-1">
                                    <button [disabled]="loading" type="button" (click)="onSubmit()"
                                        class="btn btn-primary btn-block" *ngIf="isAddMode" tabindex="18" rippleEffect>
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Save
                                    </button>
                                    <button [disabled]="loading" type="button" (click)="onSubmit()"
                                        class="btn btn-primary btn-block" *ngIf="!isAddMode" tabindex="18" rippleEffect>
                                        <span *ngIf="loading"
                                            class="spinner-border spinner-border-sm mr-1"></span>Update
                                    </button>
                                </div>
                                <div class="clearfix"></div>
                                <div class="col col-sm-12 col-xs-12 col-lg-8">
                                    <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                                        <div class="alert-body">
                                            <p>{{ error }}</p>
                                        </div>
                                    </ngb-alert>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>