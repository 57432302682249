<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-3 d-none d-sm-inline-block">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Pending Delivery Challan</h4>
                        </div>
                        <div class="card-body p-50 pb-1" *ngIf="!loadingPaddingOrderWiseDealer">
                            <div class="d-flex align-items-center  p-50 border-bottom" *ngFor="let paddingOrderDealer of paddingOrderWiseDealerData; let ndx = index">
                                <div class="avatar mr-1 ml-0 bg-light-success">
                                    <div class="avatar-content text-uppercase ">{{ paddingOrderDealer.enterprise_name | initials }}</div>
                                </div>
                                <div class="cell-line-height w-100 line-height" routerLink="/delivery-challan/add/{{ paddingOrderDealer.id }}">
                                    <div class="row">
                                        <div class="col">
                                            <a routerLink="/delivery-challan/add/{{ paddingOrderDealer.id }}" class="font-medium-1 d-block"><span class="font-weight-bold text-capitalize">{{ paddingOrderDealer.enterprise_name }}</span></a>
                                            <span class="font-small-2 text-capitalize"> {{ paddingOrderDealer.owner_name }} {{ paddingOrderDealer.mobile }}</span>
                                        </div>
                                        <div class="col-2">
                                            <span class="btn btn-primary btn-sm btn-plush" routerLink="/delivery-challan/add/{{ paddingOrderDealer.id }}"><i data-feather="plus" [class]="'font-weight-bolder'"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-50 pb-1" *ngIf="!loadingPaddingOrderWiseDealer && paddingOrderWiseDealerData?.length == 0">
                            <div class="d-flex align-items-center  p-50 border-bottom">
                                No Data!
                            </div>
                        </div>
                        <div class="card-body p-50 pb-1" *ngIf="loadingPaddingOrderWiseDealer">
                            <div class="text-center">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div>
                                    Please Wait...
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col col-md-9">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Delivery Challan List</h4>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-6 col-6">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                      <select class="form-control mx-25" [(ngModel)]="lengths" (change)="loadPageLenghs($event)">
                                        <option value="15">15</option>
                                        <option value="30">30</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                      </select>
                                      </label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-6 d-flex justify-content-start justify-content-md-end">
                                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0 w-100">
                                        <button class="btn btn-primary ml-1" rippleEffect routerLink="/delivery-challan/add">
                                            <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Add New</span>
                                        </button>
                                        <button class="btn btn-success ml-1 d-sm-none d-inline-block" rippleEffect (click)="toggleSidebar('new-sidebar')">
                                            <i data-feather="list" class="mr-0 mr-sm-1"></i>
                                          </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 table-responsive ">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th> Customer Details</th>
                                            <th> Challan No</th>
                                            <!-- <th> Amount</th> -->
                                            <th> Date</th>
                                            <th> Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="challansList?.length != 0">
                                        <tr *ngFor="let challan of challansList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td>{{ challan.customer_name }} <br/> {{ challan.phone }} </td>
                                            <td>{{ challan.invoice_no }}</td>
                                            <!-- <td>₹ {{ challan.delivery_amount | number:'1.2-2' }}</td> -->
                                            <td>{{ challan.delivery_date }}</td>
                                            <td>
                                                <div ngbDropdown *ngIf="challan.status == 0">
                                                    <button ngbDropdownToggle class="btn btn-gradient-danger btn-sm" type="button" id="dropdownMenuButton101">
                                                        Not Reached
                                                  </button>
                                                    <div ngbDropdownMenu aria-labelledby="dropdownMenuButton101">
                                                        <a ngbDropdownItem (click)="changeStatus(challan.id,1,myModalLabelremark)">Reached</a>
                                                    </div>
                                                </div>
                                                <div ngbDropdown *ngIf="challan.status == 1">
                                                    <button ngbDropdownToggle class="btn btn-gradient-success btn-sm" type="button" id="dropdownMenuButton101">
                                                       Reached
                                                  </button>
                                                    <div ngbDropdownMenu aria-labelledby="dropdownMenuButton101">
                                                        <a ngbDropdownItem (click)="changeStatus(challan.id,0,myModalLabelremark)"> Not  Reached</a>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div ngbDropdown container="body">
                                                    <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                                                      <i data-feather="more-vertical"></i>
                                                    </button>
                                                    <div ngbDropdownMenu>
                                                        <a ngbDropdownItem href="javascript:void(0);" (click)="editTransport(challan.id,ndx,modalTransport)"><i data-feather="truck" class="mr-50"></i><span>Edit Transport</span></a>
                                                        <a ngbDropdownItem href="javascript:void(0);" (click)="download(challan.id,ndx)"><i data-feather="download" class="mr-50"></i><span>Download</span></a>
                                                        <a ngbDropdownItem href="javascript:void(0);" (click)="modalOpen(modalBasic,challan.id)"><i data-feather="eye" class="mr-50"></i><span>View Details</span></a>
                                                        <a ngbDropdownItem href="javascript:void(0);" (click)="delete(challan.id)"><i data-feather="trash" class="mr-50"></i><span>Delete</span></a>
                                                    </div>
                                                </div>

                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="challansList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="6" class="no-data-available text-center">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="6" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <!-- <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSize > 0" [collectionSize]="collectionSize" [pageSize]="lengths" [(page)]="pageBasicText" (pageChange)="loadPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination> -->

                                <ngb-pagination class="mr-1 pull-right" [collectionSize]="collectionSize" *ngIf="collectionSize > 0" [(page)]="pageAdvancedEllipses" [maxSize]="5" (pageChange)="loadPage($event)" [pageSize]="lengths" [rotate]="true">

                                </ngb-pagination>

                                <!-- Transport Modal -->
                                <ng-template #modalTransport let-modal>
                                    <div class="modal-header">
                                        <h4 class="modal-title">Edit Transport Details</h4>
                                        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                    </div>
                                    <div class="modal-body" tabindex="0" ngbAutofocus>
                                        <div class="col">

                                            <form name="form" [formGroup]="transportform" autocomplete="off">
                                                <div class="row">
                                                    <div class="form-group col-lg-6 ">
                                                        <label for="register-lr_no" class="form-label">Transport</label>
                                                        <ng-select aria-describedby="register-transport_id"
                                                            formControlName="transport_id" (change)="changeTransport($event)"
                                                            tabindex="1">
                                                            <ng-option [value]="" selected disabled>Select Transport</ng-option>
                                                            <ng-option *ngFor="let transport of transportDropDown"
                                                                [value]="transport.id">{{transport.name}}</ng-option>
                                                        </ng-select>
                                                    </div>
                                                    <div class="form-group col-lg-6">
                                                        <label for="register-transport_name" class="form-label">Transport Name</label>
                                                        <input type="text" formControlName="transport_name" class="form-control" placeholder="Enter Transport Name" aria-describedby="register-transport_name" tabindex="2" [ngClass]="{ 'is-invalid': submitted && f.transport_name.errors }" />
                                                    </div>
                                                    <div class="form-group col-lg-6">
                                                        <label for="register-mobile_no" class="form-label">Mobile</label>
                                                        <input type="text" formControlName="mobile_no" class="form-control" placeholder="Enter Mobile" aria-describedby="register-mobile_no" tabindex="3" [ngClass]="{ 'is-invalid': submitted && f.mobile_no.errors }" />
                                                    </div>
                                                    <div class="form-group col-lg-6">
                                                        <label for="register-lr_no" class="form-label">LR No</label>
                                                        <input type="text" formControlName="lr_no" class="form-control" placeholder="Enter LR No" aria-describedby="register-lr_no" tabindex="4" [ngClass]="{ 'is-invalid': submitted && f.lr_no.errors }" />
                                                    </div>
                                                    <div class="form-group col-lg-6">
                                                        <label for="register-vehicle_no" class="form-label">Vehicle No</label>
                                                        <input type="text" formControlName="vehicle_no" class="form-control" placeholder="Enter Vehicle No" aria-describedby="register-vehicle_no" tabindex="5" [ngClass]="{ 'is-invalid': submitted && f.vehicle_no.errors }" />
                                                    </div>
                                                    <div class="form-group col-lg-12">
                                                        <label for="register-remarks" class="form-label">Remarks</label>
                                                        <textarea rows="3" formControlName="remarks" class="form-control" placeholder="Enter Remarks" aria-describedby="register-remarks" tabindex="6" [ngClass]="{ 'is-invalid': submitted && f.remarks.errors }"></textarea>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" (click)="modal.close('Accept click')" rippleEffect>
                                                    Close
                                                </button>
                                        <button type="button" class="btn btn-success" [disabled]="loading" (click)="updateTransportDetails()" rippleEffect>
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                             Update</button>
                                    </div>
                                </ng-template>
                                <!-- / Transport Modal -->

                                <!-- Modal -->
                                <ng-template #myModalLabelremark let-modal>
                                    <div class="modal-header">
                                        <h4 class="modal-title" id="myModalLabelremark">Change Status</h4>
                                        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                    </div>
                                    <div class="modal-body" tabindex="0" ngbAutofocus>
                                        <div class="col">

                                            <form name="form" [formGroup]="newForm" autocomplete="off">
                                                <textarea formControlName="remarks" class="form-control" rows="4" id="remarks" placeholder="Remark If Any"></textarea>
                                            </form>

                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" (click)="modal.close('Accept click')" rippleEffect>
                                                    Close
                                                </button>
                                        <button type="button" class="btn btn-success" [disabled]="loading" (click)="changeChallanStatus()" rippleEffect><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Change</button>
                                    </div>
                                </ng-template>
                                <!-- / Modal -->

                                <!-- Modal -->
                                <ng-template #modalBasic let-modal>
                                    <div class="modal-header">
                                        <h4 class="modal-title" id="myModalLabel1" *ngIf="challanDetails?.length != 0">#{{ challanDetails.invoice_no }} </h4>
                                        <h4 class="modal-title" id="myModalLabel1" *ngIf="challanDetails?.length == 0">Delivery Challan Details</h4>
                                        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body" tabindex="0" ngbAutofocus>
                                        <div class="col" *ngIf="challanDetails?.length == 0">
                                            <div class="text-center">
                                                <div class="spinner-border" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <div>
                                                    Please Wait...
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="challanDetails?.length != 0" id="print-section">

                                            <html>

                                            <head>
                                                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                                                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                                                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                                                <title>Delivery Challan </title>
                                                <style>
                                                    @page {
                                                        margin: 15px 15px 60px 15px;
                                                    }
                                                    
                                                    body {
                                                        font-size: 14px;
                                                        margin: 0 auto;
                                                        padding: 0 auto;
                                                        line-height: 1.5;
                                                        font-family: "Montserrat", Helvetica, Arial, serif;
                                                    }
                                                    
                                                    footer {
                                                        position: fixed;
                                                        left: 0px;
                                                        right: 0px;
                                                        height: 120px;
                                                    }
                                                    
                                                    .text-white {
                                                        color: #fff;
                                                    }
                                                    
                                                    .company-details {
                                                        text-align: right;
                                                    }
                                                    
                                                    .heading {
                                                        margin-bottom: 5px;
                                                    }
                                                    
                                                    .sub-heading {
                                                        margin-bottom: 5px;
                                                    }
                                                    
                                                    .invoice-details {
                                                        text-align: right;
                                                    }
                                                    
                                                    .table-body table {
                                                        background-color: #ffffff !important;
                                                        width: 100%;
                                                        padding: 0;
                                                        margin: 0 auto;
                                                        border-collapse: collapse;
                                                        border: 1px #000000 solid;
                                                    }
                                                    
                                                    .table-body table td {
                                                        vertical-align: middle !important;
                                                        text-align: center;
                                                    }
                                                    
                                                    .table-striped tbody tr:nth-of-type(odd) {
                                                        background-color: #fafafc;
                                                    }
                                                    
                                                    .float-right {
                                                        float: right;
                                                    }
                                                    
                                                    .float-right {
                                                        float: right;
                                                    }
                                                    
                                                    .float-left {
                                                        float: left;
                                                    }
                                                    
                                                    .w-5 {
                                                        width: 5%;
                                                    }
                                                    
                                                    .border-1 {
                                                        border: 1px solid white;
                                                    }
                                                    
                                                    .table-bordered td {
                                                        padding: 0px !important;
                                                    }
                                                </style>
                                            </head>

                                            <body>


                                                <main>
                                                    <div class="body-section-footer table-body">
                                                        <p class="heading" style="text-align: center;margin:0 !important;padding:0 !important"><b>Delivery Challan</b></p>
                                                        <table class="table-bordered table-striped">
                                                            <tbody>
                                                                <tr style="background-color: #eaeaea;">
                                                                    <td style="text-align: left;padding-left: 10px !important; vertical-align:top !important;">
                                                                        <span style="font-size:15px;padding-bottom: 10px">Supplier :</span>
                                                                    </td>
                                                                    <td style="text-align: left;padding-left: 10px !important; vertical-align: top !important;">
                                                                        <span style="font-size:15px;padding-bottom: 10px">Buyer :</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="text-align: left;padding-left: 10px !important; vertical-align:top !important;">
                                                                        <span style="font-size:15px"><b>{{ currentUser.user.name }}</b></span> <br />
                                                                        <span>Email: {{ currentUser.user.email }}</span><br>
                                                                        <span> Mobile: +91 {{ currentUser.user.mobile }}</span>
                                                                        <span *ngIf="currentUser.user.is_gst_print == 1"> <br/>GSTIN: {{ currentUser.user.gst_no | uppercase }}</span>
                                                                    </td>

                                                                    <td style="text-align: left;padding-left: 10px !important; vertical-align: top !important;">
                                                                        <span style="font-size:15px"><b>{{ challanDetails.name }}</b></span> <br />
                                                                        <span> Mobile: +91 {{ challanDetails.phone }}</span>
                                                                        <br /><br />
                                                                        <span class="heading"><b>Challan No: {{ challanDetails.invoice_no }} </b></span><br />
                                                                        <span class="sub-heading">Challan Date: {{ challanDetails.delivery_date }}</span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>


                                                        <table class="table-bordered table-striped product-table" style="margin-top:5px;">
                                                            <thead>
                                                                <tr style="background-color: #eaeaea;">
                                                                    <th class="w-5">#</th>
                                                                    <th style="width:50%;text-align:left;">Product</th>
                                                                    <th *ngIf="currentUser.user.is_show_price == 1">Price</th>
                                                                    <th>Quantity</th>
                                                                    <th *ngIf="currentUser.user.is_show_price == 1">SubTotal</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                <tr *ngFor="let challanProduct of challanDetails.delivery_challan_details; let sr = index">
                                                                    <td>{{sr+1}}</td>
                                                                    <td style="text-align:left;">
                                                                        {{ challanProduct.product_code }} {{ challanProduct.product_name }}<br>
                                                                        <small>
                                                                            <span *ngIf="challanProduct.color != '' && challanProduct.color != null"><b>Color: </b> {{challanProduct.color}}</span> &nbsp;
                                                                            <span *ngIf="challanProduct.size != '' && challanProduct.size != null"><b>Size: </b> {{challanProduct.size}}</span> &nbsp;
                                                                            <span *ngIf="challanProduct.unit != '' && challanProduct.unit != null"><b>Unit: </b> {{challanProduct.unit}}</span>
                                                                        </small>
                                                                    </td>
                                                                    <td *ngIf="currentUser.user.is_show_price == 1">₹ {{challanProduct.dispatch_price | number:'1.2-2' }}</td>
                                                                    <td>{{challanProduct.dispatch_qty}}</td>
                                                                    <td style="text-align:right;padding-right: 5px !important;" *ngIf="currentUser.user.is_show_price == 1">₹ {{challanProduct.dispatch_sub_total | number:'1.2-2'}}</td>
                                                                </tr>

                                                                <tr style="border-top: 1px #000000 solid;">
                                                                    <td *ngIf="currentUser.user.is_show_price == 1" colspan="2"></td>
                                                                    <td *ngIf="currentUser.user.is_show_price == 0"></td>
                                                                    <td style="text-align: end"><b>Total</b></td>
                                                                    <td><b> {{ getSum('dispatch_qty') }}</b></td>
                                                                    <td style="text-align:right;padding-right: 5px !important"> <b>₹ {{ getSum('dispatch_sub_total') | number:'1.2-2' }}</b></td>
                                                                </tr>
                                                                <tr>
                                                                    <td *ngIf="currentUser.user.is_show_price == 1" colspan="2"></td>
                                                                    <td *ngIf="currentUser.user.is_show_price == 0"></td>
                                                                    <td class="text-right"><b>Discount</b></td>
                                                                    <td><b>{{ challanDetails.discount_pre }}% </b></td>
                                                                    <td style="text-align:right;padding-right: 5px !important"> <b> {{ challanDetails.discount_rs | number:'1.2-2' }}</b></td>
                                                                </tr>
                                                                <tr *ngIf="currentUser.user.is_show_price == 1 && challanDetails.tax_one_pre > 0">

                                                                    <td colspan="2"></td>
                                                                    <td class="text-right"><b>{{ challanDetails.tax_one }}</b></td>
                                                                    <td><b>{{ challanDetails.tax_one_pre }} % </b></td>
                                                                    <td style="text-align:right;padding-right: 5px !important"><b>{{ challanDetails.tax_one_rs | number:'1.2-2' }} </b></td>
                                                                </tr>

                                                                <tr *ngIf="currentUser.user.is_show_price == 1 && challanDetails.tax_two_pre > 0">

                                                                    <td colspan="2"></td>
                                                                    <td class="text-right"><b>{{ challanDetails.tax_two }}</b></td>
                                                                    <td><b>{{ challanDetails.tax_two_pre }}% </b></td>
                                                                    <td style="text-align:right;padding-right: 5px !important"><b> {{ challanDetails.tax_two_rs | number:'1.2-2' }} </b></td>
                                                                </tr>

                                                                <tr *ngIf="currentUser.user.is_show_price == 1 && challanDetails.transport_charge > 0">
                                                                    <td colspan="2"></td>
                                                                    <td colspan="2"><b>{{ challanDetails.transport_charge_label }} </b></td>
                                                                    <td style="text-align:right;padding-right: 5px !important"><b> {{ challanDetails.transport_charge | number:'1.2-2' }} </b></td>
                                                                </tr>

                                                                <tr *ngIf="currentUser.user.is_show_price == 1 && challanDetails.packing_charge > 0">
                                                                    <td colspan="2"></td>
                                                                    <td colspan="2"><b>{{ challanDetails.packing_charge_label }} </b></td>
                                                                    <td style="text-align:right;padding-right: 5px !important"><b> {{ challanDetails.packing_charge | number:'1.2-2' }} </b></td>
                                                                </tr>

                                                                <tr *ngIf="currentUser.user.is_show_price == 1 && challanDetails.extra_charge > 0">
                                                                    <td colspan="2"></td>
                                                                    <td colspan="2"><b>{{ challanDetails.extra_charge_label }} </b></td>
                                                                    <td style="text-align:right;padding-right: 5px !important"><b> {{ challanDetails.extra_charge | number:'1.2-2' }} </b></td>
                                                                </tr>

                                                                <tr *ngIf="currentUser.user.is_show_price == 1 && challanDetails.round_off != 0">
                                                                    <td colspan="2"></td>
                                                                    <td class="text-right" colspan="2"><b>Round Off</b></td>
                                                                    <td style="text-align:right;padding-right: 5px !important"><b> {{ challanDetails.round_off }}</b> </td>
                                                                </tr>

                                                                <tr *ngIf="currentUser.user.is_show_price == 1">
                                                                    <td colspan="2"></td>
                                                                    <td class="text-right" colspan="2"><b>Grand Total</b></td>
                                                                    <td style="text-align:right;padding-right: 5px !important"> <b> {{ challanDetails.delivery_amount | number:'1.2-2' }} </b></td>
                                                                </tr>

                                                            </tbody>
                                                        </table>

                                                        <div>
                                                            <h4 class="heading">Transport Details</h4>
                                                            <table class="table-bordered table-striped">
                                                                <thead>
                                                                    <tr style="background-color: #eaeaea;">
                                                                        <td>LR No</td>
                                                                        <td>Transport Name</td>
                                                                        <td>Mobile</td>
                                                                        <td>Vehicle No</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td *ngIf="challanDetails.lr_no != 'null' && challanDetails.lr_no != '' ">{{ challanDetails.lr_no }}</td>
                                                                        <td *ngIf="challanDetails.lr_no == 'null' || challanDetails.lr_no == '' "><br/></td>

                                                                        <td *ngIf="challanDetails.transport_name != 'null' && challanDetails.transport_name != '' ">{{ challanDetails.transport_name }}</td>
                                                                        <td *ngIf="challanDetails.transport_name == 'null' || challanDetails.transport_name == '' "><br/></td>

                                                                        <td *ngIf="challanDetails.mobile != 'null' && challanDetails.mobile != '' ">{{ challanDetails.transport_mobile }}</td>
                                                                        <td *ngIf="challanDetails.mobile == 'null' || challanDetails.mobile == '' "><br/></td>

                                                                        <td *ngIf="challanDetails.mobile != 'null' && challanDetails.mobile != '' ">{{challanDetails.vehicle_no}} </td>
                                                                        <td *ngIf="challanDetails.mobile == 'null' || challanDetails.mobile == '' "><br/></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colspan="4" *ngIf="challanDetails.remarks != 'null' && challanDetails.remarks != '' " style="text-align: left;padding-left: 5px !important">
                                                                            <b>Remarks:</b> {{challanDetails.remarks}}
                                                                        </td>

                                                                        <td colspan="4" *ngIf="challanDetails.remarks == 'null' || challanDetails.remarks == '' " style="text-align: left;padding-left: 5px !important">
                                                                            <b>Remarks:</b> <br/><br/>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </main>

                                                <!-- <footer>
                                                    <div style="padding: 0px 5px 0px 5px;">
                                                        <p style="text-align:right;">
                                                            For <b>{{ currentUser.user.name }}</b>
                                                            <br />
                                                            <br /> Authorised Signatory

                                                        </p>
                                                        <p style="text-align: center"> *This is a Computer Generated Challan </p>
                                                    </div>
                                                </footer> -->
                                            </body>

                                            </html>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" (click)="modal.close('Accept click')" rippleEffect>
                                            Close
                                        </button>
                                        <button type="button" class="btn btn-success" printSectionId="print-section" ngxPrint><i class="fa fa-print"></i> Print</button>
                                    </div>
                                </ng-template>
                                <!-- / Modal -->

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>

<!-- New User Sidebar -->
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="new-sidebar" overlayClass="modal-backdrop">
    <div class="slideout-content">
        <!-- Modal to add new starts-->
        <div class="modalsd modal-slide-in sdfade new-modal" id="modals-slide-in">
            <div class="modal-dialog">
                <form class="add-new modal-content pt-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="toggleSidebar('new-sidebar')">
                      ×
                    </button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title" id="exampleModalLabel">Pending Delivery Challan</h5>
                    </div>
                    <div class="modal-body flex-grow-1 ">

                        <div class="row" *ngIf="!loadingPaddingOrderWiseDealer">
                            <div class="d-flex align-items-center  p-50 border-bottom w-100" *ngFor="let paddingOrderDealer of paddingOrderWiseDealerData; let ndx = index">
                                <div class="avatar mr-1 ml-0 bg-light-success">
                                    <div class="avatar-content text-uppercase ">{{ paddingOrderDealer.enterprise_name | initials }}</div>
                                </div>
                                <div class="cell-line-height w-100 line-height" routerLink="/delivery-challan/add/{{ paddingOrderDealer.id }}">
                                    <a routerLink="/delivery-challan/add/{{ paddingOrderDealer.id }}" class="font-medium-1 d-block"><span class="font-weight-bold text-capitalize">{{ paddingOrderDealer.enterprise_name }}</span></a>
                                    <span class="btn btn-primary btn-sm btn-plush" routerLink="/delivery-challan/add/{{ paddingOrderDealer.id }}"><i data-feather="plus" [class]="'font-weight-bolder'"></i></span>
                                    <span class="font-small-2 text-capitalize"> {{ paddingOrderDealer.owner_name }} {{ paddingOrderDealer.mobile }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="!loadingPaddingOrderWiseDealer && paddingOrderWiseDealerData?.length == 0">
                            <div class="d-flex align-items-center  p-50 border-bottom">
                                No Data!
                            </div>
                        </div>
                        <div class="row" *ngIf="loadingPaddingOrderWiseDealer">
                            <div class="text-center">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div>
                                    Please Wait...
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </div>
        <!-- Modal to add new Ends-->
    </div>
</core-sidebar>
<!--/ New User Sidebar -->