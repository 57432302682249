import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })

export class DashboadService {
  /**
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(): Observable<any> {
    const userDetail = JSON.parse(localStorage.getItem("currentUser"));
    return this._http
      .post(`${environment.apiUrl}dashboard`, {})
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getPendingOrder(start, length): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}dashboard-pending-order`, { start, length })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getStockAlertList(start, length): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}dashboard-stock-alert`, { start, length })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  getOrdersList(start, length): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}dashboard-last-order`, { start, length })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  getCustOutstandings(start, length): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-outstanding`, { start, length })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
