import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
const headers = new HttpHeaders({ 'Content-Type': 'application/json', responseType: 'blob' });

@Injectable({
  providedIn: 'root'
})
export class DeliveryChallanService {

  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(start, length,status:any = 0): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-challan-list`, { start, length,status })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  delete(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-delivery-challan`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  dealerSearch(searchText: string): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}search-dealer`, { searchText })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getDeliveryOrder(id: string): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}get-delivery-order/` + id)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  add(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delivery-challan-store`, formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getChallanDetails(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}view-challan-details`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  downloadPdf(id: string): Observable<Blob> {
    return this._http.post<Blob>(`${environment.apiUrl}get-challan-pdf`, { id }, {
      headers: headers, responseType:
        'blob' as 'json'
    });
  }


  loadOutStandingReport(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}outstanding-report`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  getpaddingOrderWiseDealerList(): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}padding-order-wise-dealer`, {})
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  changeStatus(id: any, status: any,remark:any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}change-challan-status`, { status,id,remark })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  updateTransportDetails(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}update-transport-details`, formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getTransportsDropDown(): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}transport-dropdwon`, { })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
