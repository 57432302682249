<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <form name="form" [formGroup]="newForm" autocomplete="off">
            <section id="home-page">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Orders Add</h4>
                                <a routerLink="/orders"><span class="btn btn-outline-dark btn-sm pull-right" rippleEffect><span [data-feather]="'chevron-left'"></span> Back</span></a>
                            </div>
                            <div class="card-body p-0 pb-1">
                                <div class="row m-0">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label class="form-label">Customer Name<span class="text-danger">*</span></label>
                                            <ng-autocomplete #ngAutoCompleteStatic [data]="allDealer" name="dealers" [initialValue]="" [minQueryLength]="3" placeholder="Search Customers Min 3 Characters " [searchKeyword]="keyword" historyIdentifier="allDealer" [historyHeading]="historyHeading"
                                                [historyListMaxNumber]="0" notFoundText="Not found" (selected)='selectEvent($event)' (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplateStatic" [isLoading]="isLoading"
                                                [notFoundTemplate]="notFoundTemplate" (inputCleared)="closedStatic()">
                                            </ng-autocomplete>
                                            <ng-template #itemTemplateStatic let-item>
                                                <a [innerHTML]="item.name"></a>
                                            </ng-template>
                                            <ng-template #notFoundTemplate let-notFound>
                                                <div [innerHTML]="notFound"></div>
                                            </ng-template>
                                        </div>
                                        <div class="form-group">
                                            <label for="register-name" class="form-label">P.O. Number<span class="text-danger">*</span></label>
                                            <input type="text" class="form-control" placeholder="P.O. Number" formControlName="poNumber" id="poNumber" name="poNumber" />
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">Search Products<span class="text-danger">*</span></label>
                                            <ng-autocomplete #auto #ngAutoCompleteStatic [disabled]="dealerSelected" [data]="allProduct" name="products" [initialValue]="" [minQueryLength]="3" placeholder="Search Product, Min. 3 Characters" [searchKeyword]="keywordProduct" historyIdentifier="allProduct"
                                                [historyHeading]="historyHeading" [historyListMaxNumber]="0" notFoundText="Not found" (selected)='selectEventProduct($event)' (inputChanged)='onChangeSearchProduct($event)' (inputFocused)='onFocusedProduct($event)'
                                                [itemTemplate]="itemTemplateStaticProduct" [isLoading]="isLoadingProduct" [notFoundTemplate]="notFoundTemplateProduct">
                                            </ng-autocomplete>
                                            <ng-template #itemTemplateStaticProduct let-items>
                                                <a [innerHTML]="items.full_name"></a>
                                            </ng-template>
                                            <ng-template #notFoundTemplateProduct let-notFound>
                                                <div [innerHTML]="notFound"></div>
                                            </ng-template>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label for="remarks" class="form-label">Remarks</label>
                                            <textarea formControlName="remarks" class="form-control" rows="4" id="remarks" placeholder="Remark If Any"></textarea>
                                        </div>
                                        <div class="row  pt-2 text-right ">
                                            <div class="col-6 col-md-12 form-group text-right">
                                                <span *ngIf="total_qty > 0"> Total Qty : {{total_qty}}</span>
                                            </div>
                                            <div class="col-6 col-md-12 form-group text-right">
                                                <button class="btn btn-primary" [disabled]="loading" type="button" (click)="storeDealerOrder()" *ngIf="total_qty > 0">
                                                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>  Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </form>
        <div class="ecommerce-application" *ngIf="order_details?.length != 0">
            <section>
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12 col-12">
                                <div class="table-responsive">
                                    <table class="table table-striped table-hover">
                                        <thead class="bg-gray-300">
                                            <tr class="text-center">
                                                <th width="2%">#</th>
                                                <th width="20%">Product</th>
                                                <th *ngIf="isCheckedColor" width="10%">Color</th>
                                                <th *ngIf="isCheckedSize" width="10%">Size</th>
                                                <th *ngIf="isCheckedUnit" width="10%">Unit</th>
                                                <th width="10%">Packing type</th>
                                                <th width="10%">Price</th>
                                                <th width="20%">Qty</th>
                                                <th width="3%" class="text-center">
                                                    <span [data-feather]="'trash-2'"></span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="text-center">
                                            <tr *ngFor="let detail of order_details; let ndx = index">
                                                <td>{{ndx+1}}</td>
                                                <td>
                                                    <div style="width:45px;float:left;">
                                                        <img src="assets/images/no-img.png" *ngIf="detail.product_photo == ''" height="40" width="40" alt="IMG" class="rounded mr-75">
                                                        <img [src]="detail.product_photo" *ngIf="detail.product_photo != ''" height="40" width="40" alt="IMG" class="rounded mr-75">
                                                    </div>
                                                    <div style="float:left;">
                                                        <b>{{detail.product_code}}</b>
                                                    </div>
                                                    <span *ngIf="detail.category_name != ''">{{detail.category_name}}<br/></span>
                                                    <span class="mb-1">{{detail.product_name}}</span><br/>
                                                </td>
                                                <td *ngIf="detail.is_color == 1">
                                                    <ng-select (change)="onChangeColorVariant($event, detail.id)" [items]="detail.colorList" [(ngModel)]="isSelectedColor" [clearable]="false" [loading]="isLoadingColorList" bindValue="id" bindLabel="name"></ng-select>
                                                </td>
                                                <td *ngIf="detail.is_size == 1">
                                                    <ng-select (change)="onChangeSizeVariant($event, detail.id)" [items]="detail.sizeList" [(ngModel)]="isSelectedSize" [clearable]="false" [loading]="isLoadingSizeList" bindValue="id" bindLabel="name"></ng-select>
                                                </td>
                                                <td *ngIf="detail.is_unit == 1">
                                                    <ng-select (change)="onChangeUnitVariant($event, detail.id)" [items]="detail.unitList" [(ngModel)]="isSelectedUnit" [clearable]="false" [loading]="isLoadingUnitList" bindValue="id" bindLabel="name"></ng-select>
                                                </td>
                                                <td>
                                                    <span *ngIf="detail.packing_qty> 0 || detail.master_packing_qty > 0">
                                                            <span *ngIf="detail.packing_qty > 0">P.Qty : {{detail.packing_qty}}</span> <br>
                                                    <span *ngIf="detail.master_packing_qty > 0">Master P.Qty : {{detail.master_packing_qty}}</span>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span *ngIf="detail.sales_price != '' && detail.sales_price != 'null' && detail.sales_price > 0">&nbsp; &nbsp; 
                                                            <b *ngIf="detail.discount != '' && detail.discount != 'null' && detail.discount > 0"><del class="text-danger">₹ {{detail.sales_price.toFixed(2) }}</del></b>
                                                        </span>
                                                    <span>
                                                            <b *ngIf="detail.discount == '0'">₹ {{detail.sales_price.toFixed(2) }}</b>
                                                        </span>
                                                    <span *ngIf="detail.discount != '' && detail.discount != 'null' && detail.discount > 0">
                                                           <br> <b>₹ {{detail.discount.toFixed(2) }} </b>
                                                        </span>
                                                </td>
                                                <td>
                                                    <core-touchspin *ngIf="detail.min_order_qty > 0" [numberValue]="detail.min_order_qty" size="sm" [stepValue]="detail.min_order_qty" [minValue]="detail.min_order_qty" [maxValue]="1000" (onChange)="countChange($event,detail.id)"></core-touchspin>
                                                    <core-touchspin *ngIf="detail.min_order_qty == 0" [numberValue]="1" size="sm" [stepValue]="1" [minValue]="1" [maxValue]="1000" (onChange)="countChange($event,detail.id)"></core-touchspin>
                                                </td>
                                                <td>
                                                    <button type="button" (click)="productDelete(detail.id)" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Delete" class="btn btn-icon btn-icon rounded-circle btn-danger" rippleEffect>
                                                            <span [data-feather]="'x-circle'"></span> 
                                                        </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!--<section *ngIf="order_details?.length != 0">
                     <div class="col-md-6 col-xs-12" *ngFor="let detail of order_details; let ndx = index">
                        <div class="card">
                            <div class="card-body p-1">
                                <div class="row">
                                    <div class="col-md-3 col-3">
                                        <img class="img-fluid card-img-top m-auto" *ngIf="detail.product_photo != ''" src="{{detail.product_photo}}" alt="img-placeholder" style="max-width: 100px;" />
                                        <img class="img-fluid card-img-top m-auto" *ngIf="detail.product_photo == ''" src="assets/images/no-img.png" alt="img-placeholder" style="max-width: 100px;" />
                                    </div>
                                    <div class="col-md-6 col-9">
                                        <span *ngIf="detail.category_name != ''">{{detail.category_name}}<br/></span>
                                        <span class="mb-1">{{detail.product_code}} {{detail.product_name}}</span><br/>
                                        <div *ngIf="detail.is_color == 1">
                                            <b>Color:</b>
                                            <div class="d-flex align-items-center" *ngIf="detail.colorList?.length != 0">
                                                <div *ngFor="let dv of detail.colorList; let coi = index">
                                                    <div class="custom-control custom-control-primary custom-radio mb-1 mr-1" >
                                                        <input type="radio" id="color{{coi}}" name="color" class="custom-control-input" [checked]=" coi== 0 ? true : false" (change)="onChangeColorVariant(dv.color, detail.id)"/>
                                                        <label class="custom-control-label" for="color{{coi}}">{{dv.color}}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="detail.is_size == 1">
                                            <b>Size:</b>
                                            <div class="d-flex align-items-center" *ngIf="detail.sizeList?.length != 0">
                                                <div *ngFor="let sv of detail.sizeList; let zi = index">
                                                    <div class="custom-control custom-control-info custom-radio mb-1 mr-1" >
                                                        <input type="radio" id="size{{zi}}" name="size" class="custom-control-input" [checked]=" zi== 0 ? true : false" (click)="onChangeSizeVariant(sv.size, detail.id)"/>
                                                        <label class="custom-control-label" for="size{{zi}}">{{sv.size}}</label>
                                                    </div>
                                                     <button type="button" class="btn btn-sm  mb-1 mr-1"  [ngClass]="isCheckSize == true ? 'btn-primary' : 'btn-outline-primary'" (click)="onChangeSizeVariant(sv.size, detail.id)" rippleEffect>{{sv.size}}</button> 
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="detail.is_unit == 1">
                                            <b>Unit:</b>
                                            <div class="d-flex align-items-center" *ngIf="detail.unitList?.length != 0">
                                                <div *ngFor="let uv of detail.unitList; let ui = index">
                                                    <div class="custom-control custom-control-warning  custom-radio mb-1 mr-1" >
                                                        <input type="radio" id="unit{{ui}}" name="unit" class="custom-control-input" [checked]=" ui== 0 ? true : false" (click)="onChangeUnitVariant(uv.unit, detail.id)"/>
                                                        <label class="custom-control-label" for="unit{{ui}}">{{uv.unit}}</label>
                                                    </div>
                                                     <button type="button" class="btn btn-sm btn-outline-primary mb-1 mr-1" (click)="onChangeUnitVariant(uv.unit, detail.id)" rippleEffect>{{uv.unit}}</button> 
                                                </div>
                                            </div>
                                        </div>
                                        <span *ngIf="detail.packing_qty> 0 || detail.master_packing_qty > 0">
                                            <span *ngIf="detail.packing_qty > 0">Packing Qty : {{detail.packing_qty}}</span>
                                            <span *ngIf="detail.master_packing_qty > 0">, &nbsp; Master Packing Qty : {{detail.master_packing_qty}}</span>
                                            <br/>
                                        </span>
                                        <span *ngIf="detail.sales_price != '' && detail.sales_price != 'null' && detail.sales_price > 0">&nbsp; &nbsp; 
                                            <b *ngIf="detail.discount != '' && detail.discount != 'null' && detail.discount > 0"><del class="text-danger">₹ {{detail.sales_price.toFixed(2) }}</del></b>
                                        </span>
                                        <span>
                                            <b *ngIf="detail.discount == '0'">₹ {{detail.sales_price.toFixed(2) }}</b>
                                        </span>
                                        <span *ngIf="detail.discount != '' && detail.discount != 'null' && detail.discount > 0">
                                            <b>₹ {{detail.discount.toFixed(2) }} </b>
                                        </span>
                                        <br/>
                                        <div class="badge badge-light-danger d-none d-sm-inline-block" (click)="productDelete(detail.id)" rippleEffect><i class="fa mr-25 fa-trash"></i> Remove</div>
                                    </div>
                                    <div class="col-md-3 col-12 text-right d-none d-sm-inline-block">
                                        <core-touchspin *ngIf="detail.min_order_qty > 0" [numberValue]="detail.min_order_qty" size="sm" [stepValue]="detail.min_order_qty" [minValue]="detail.min_order_qty" [maxValue]="1000" (onChange)="countChange($event,detail.id)"></core-touchspin>
                                        <core-touchspin *ngIf="detail.min_order_qty == 0" [numberValue]="1" size="sm" [stepValue]="1" [minValue]="1" [maxValue]="1000" (onChange)="countChange($event,detail.id)"></core-touchspin>
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-6 text-center pt-50 d-sm-none d-inline-block">
                                        <div class="badge badge-light-danger" (click)="productDelete(detail.id)" rippleEffect><i class="fa mr-25 fa-trash"></i> Remove</div>
                                    </div>
                                    <div class="col-6 text-right  d-sm-none d-inline-block">
                                        <core-touchspin *ngIf="detail.min_order_qty > 0" [numberValue]="detail.min_order_qty" size="sm" [stepValue]="detail.min_order_qty" [minValue]="detail.min_order_qty" [maxValue]="1000" (onChange)="countChange($event,detail.id)"></core-touchspin>
                                        <core-touchspin *ngIf="detail.min_order_qty == 0" [numberValue]="1" size="sm" [stepValue]="1" [minValue]="1" [maxValue]="1000" (onChange)="countChange($event,detail.id)"></core-touchspin>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
            <!-- Ecommerce Item -->
            <!-- <div class="ecommerce-application card ecommerce-card" *ngFor="let detail of order_details; let ndx = index">
                        <div class="item-img text-center">
                            <img class="img-fluid card-img-top m-auto" *ngIf="detail.product_photo != ''" src="{{detail.product_photo}}" alt="img-placeholder" style="width: 150px;" />
                            <img class="img-fluid card-img-top m-auto" *ngIf="detail.product_photo == ''" src="assets/images/no-img.png" alt="img-placeholder" style="width: 150px;" />
                        </div>
                        <div class="card-body">
                            <div class="item-wrapper">
                                <div class="item-rating">
                                    {{detail.category_name}}
                                </div>
                                <div class="item-cost">
                                    <h6 class="item-price">₹ {{detail.sales_price}}</h6>
                                </div>
                            </div>
                            <h6 class="item-name">
                                <a class="text-body">{{detail.product_code}} {{detail.product_name}}</a>
                            </h6>
                            <p class="card-text item-description">
                                {{detail.size_title}} {{detail.color_title}}
                            </p>
                        </div>
                        <div class="item-options text-center col p-0">
                            <a class="btn btn-light btn-wishlist text-danger col-6" (click)="productDelete(detail.id)" rippleEffect>
                                <i class="fa mr-25 fa-trash"></i>
                                <span>Remove</span>
                            </a>
                            <div class="item-quantity col-6">
                                <div class="quantity-counter-wrapper">
                                    <core-touchspin [numberValue]="1" size="lg" [stepValue]="1" [minValue]="1" [maxValue]="1000" (onChange)="countChange($event,detail.id)"></core-touchspin>
                                </div>
                            </div>
                        </div>
                    </div> -->
            <!--/ Ecommerce Item 
                </section>-->
            <section>
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6 text-left">
                                <button class="btn btn-danger mr-1" type="button" (click)="removeAll()" *ngIf="total_qty > 0">Clear All</button>
                            </div>
                            <div class="col-6 text-right">
                                <span class="mr-1" *ngIf="total_qty > 0"> Total Qty : {{total_qty}}</span>
                                <button class="btn btn-primary" type="button" [disabled]="loading" (click)="storeDealerOrder()" *ngIf="total_qty > 0">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Submit
                                    </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>