<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title text-bold">Customers List</h4>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-3 col-3">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                            <select class="form-control mx-25" [(ngModel)]="lengths"
                                                (change)="loadPageLenghs($event)">
                                                <option value="15">15</option>
                                                <option value="30">30</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-6 d-flex ">
                                    <form class="modal-content pt-0 border border-0" [formGroup]="filterFormGenral" (ngSubmit)="onSubmitFilterGenral()" autocomplete="off">
                                        <div class="d-flex align-items-end justify-content-end pr-1 pb-1 pb-md-0">
                                            <input type="text" formControlName="searchText" class="form-control" style="border-radius: 10px 0px 0px 10px;" placeholder="Search By Enterprise Name, Owner Name & Phone" />
                                            <button class="btn btn-primary" type="submit" style="border-radius: 0 10px 10px 0;">GO!</button>
                                        </div>
                                    </form>
                                </div>
                                <div class="col-md-3 col-3 d-flex justify-content-start justify-content-md-end">
                                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0 w-100">
                                        <button class="btn btn-primary ml-1" rippleEffect routerLink="/dealers/add">
                                            <i data-feather="plus"
                                                class="d-sm-none d-inline-block mr-0 mr-sm-1"></i><span
                                                class="d-none d-sm-inline-block">Add New</span>
                                        </button>

                                    </div>
                                    <div class="align-items-center justify-content-end pb-1 pb-md-0">
                                        <button class="btn btn-success ml-1" rippleEffect (click)="toggleSidebar('filter-sidebar')">
                                            <i data-feather="filter" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Filter</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 table-responsive">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Enterprise Name</th>
                                            <th>Owner Detail</th>
                                            <th>City / State</th>
                                            <th>Status</th>
                                            <!-- <th>Order Link</th> -->
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="dealersList?.length != 0">
                                        <tr *ngFor="let dealer of dealersList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td>{{ dealer.name }}</td>
                                            <td><span class="font-small-2 text-muted font-small-2 badge badge-light-primary" *ngIf="dealer.owner_name != null">{{ dealer.owner_name }} </span>
                                                <br *ngIf="dealer.owner_name != null" /> {{ dealer.phone }}
                                            </td>
                                            <td>{{ dealer.city_name }} / {{ dealer.state_name }}</td>
                                            <td>
                                                <span class="badge badge-pill badge-light-success mr-1" *ngIf="dealer.status == 0">Active</span>
                                                <span class="badge badge-pill badge-light-warning mr-1" *ngIf="dealer.status == 1">Deactivate</span>
                                                <span class="badge badge-pill badge-light-danger mr-1" *ngIf="dealer.status == 2">Block</span>
                                                <!-- <span class="badge badge-pill badge-light-info mr-1" *ngIf="dealer.status == 3">Guest</span> -->
                                            </td>
                                            <!-- <td *ngIf="dealer.status != 3">
                                                <a href="https://api.whatsapp.com/send?phone=+91{{ dealer.phone }}&text=Dear%20*{{ dealer.name }}*%20,%20Now%20you%20can%20place%20order%20from%20below%20link.%0a%0ahttps://order.digitalorderbook.com/%23/home?key={{ dealer.url }}%0a%0aYou%20can%20check%20order%20history,%20delivery%20order%20and%20product%20list.%0aThank%20You%0a*{{ dealer.manufacturer_name }}*"
                                                    target="_blank" class="btn btn-sm btn-success mr-50 mb-50" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" ngbTooltip="Share Link in whatsapp"> <b><i
                                                            class="fa fa-whatsapp"></i></b></a>
                                                <button type="button" (click)="copyText(dealer.url)" class="btn btn-sm btn-primary mb-50" [container]="body" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" ngbTooltip="Copy Link"><i class="fa fa-copy"></i></button>
                                            </td>
                                            <td *ngIf="dealer.status == 3">
                                                -
                                            </td> -->
                                            <td>

                                                <div ngbDropdown container="body">
                                                    <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                                                        <i data-feather="more-vertical"></i>
                                                    </button>
                                                    <div ngbDropdownMenu>
                                                        <a ngbDropdownItem href="javascript:void(0);" (click)="paymentAdd(dealer.id,ndx)" *ngIf="dealer.status != 3"><i
                                                                class="fa fa-rupee mr-50"></i><span>Add
                                                                Wallet</span></a>
                                                        <a ngbDropdownItem href="javascript:void(0);" (click)="modalOpen(modalBasic,dealer.id)"><i
                                                                data-feather="eye" class="mr-50"></i><span>View
                                                                Details</span></a>
                                                        <a ngbDropdownItem routerLink="/dealers/edit/{{ dealer.id }}"><i
                                                                data-feather="edit-2"
                                                                class="mr-50"></i><span>Edit</span></a>
                                                        <a ngbDropdownItem href="javascript:void(0);" (click)="delete(dealer.id)"><i data-feather="trash"
                                                                class="mr-50"></i><span>Delete</span></a>
                                                    </div>
                                                </div>

                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="dealersList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="7" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="7" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSize > 0" [collectionSize]="collectionSize" [pageSize]="lengths" [(page)]="pageBasicText" (pageChange)="loadPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination>

                                <!-- Modal -->
                                <ng-template #modalBasic let-modal>
                                    <div class="modal-header">
                                        <h4 class="modal-title" id="myModalLabel1" *ngIf="dealerDetails?.length != 0">{{ dealerDetails.name }} </h4>
                                        <h4 class="modal-title" id="myModalLabel1" *ngIf="dealerDetails?.length == 0">
                                            Customer Details</h4>
                                        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body" tabindex="0" ngbAutofocus>
                                        <div class="col" *ngIf="dealerDetails?.length == 0">
                                            <div class="text-center">
                                                <div class="spinner-border" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <div>
                                                    Please Wait...
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="dealerDetails?.length != 0">
                                            <div class="card-developer-meetup">
                                                <div class="card-body">
                                                    <div class="meetup-header d-flex align-items-center">
                                                        <div class="meetup-day">
                                                            <div class="avatar bg-primary avatar-lg">
                                                                <div class="avatar-content text-uppercase">{{ dealerDetails.name | initials }}</div>
                                                            </div>
                                                        </div>
                                                        <div class="my-auto">
                                                            <h4 class="card-title mb-25">{{ dealerDetails.name }}</h4>
                                                            <p class="card-text mb-0">{{ dealerDetails.owner_name }}</p>
                                                        </div>
                                                    </div>
                                                    <div class="media">
                                                        <div class="avatar bg-light-primary rounded mr-1">
                                                            <div class="avatar-content">
                                                                <i data-feather="phone" class="avatar-icon font-medium-3"></i>
                                                            </div>
                                                        </div>
                                                        <div class="media-body">
                                                            <h6 class="mb-0">{{ dealerDetails.phone }}</h6>
                                                            <small *ngIf="dealerDetails.alternate_phone != null">{{
                                                                dealerDetails.alternate_phone }}</small>
                                                            <small *ngIf="dealerDetails.alternate_phone == null"></small>
                                                        </div>
                                                    </div>
                                                    <div class="media" *ngIf="dealerDetails.email != null">
                                                        <div class="avatar bg-light-primary rounded mr-1">
                                                            <div class="avatar-content">
                                                                <i data-feather="mail" class="avatar-icon font-medium-3"></i>
                                                            </div>
                                                        </div>
                                                        <div class="media-body">
                                                            <h6 class="mb-0" style="padding-top:7px;">{{ dealerDetails.email }}</h6>
                                                        </div>
                                                    </div>
                                                    <div class="media" *ngIf="dealerDetails.gst_no != null && dealerDetails.gst_no != ''">
                                                        <div class="avatar bg-light-primary rounded mr-1">
                                                            <div class="avatar-content">
                                                                <i data-feather="percent" class="avatar-icon font-medium-3"></i>
                                                            </div>
                                                        </div>
                                                        <div class="media-body">
                                                            <h6 class="mb-0" style="padding-top:7px;">GST : {{ dealerDetails.gst_no }}</h6>
                                                        </div>
                                                    </div>
                                                    <div class="media">
                                                        <div class="avatar bg-light-primary rounded mr-1">
                                                            <div class="avatar-content">
                                                                <i data-feather="map-pin" class="avatar-icon font-medium-3"></i>
                                                            </div>
                                                        </div>
                                                        <div class="media-body">
                                                            <h6 class="mb-0">
                                                                <span *ngIf="dealerDetails.address_one != null">{{
                                                                    dealerDetails.address_one }}</span>
                                                                <span *ngIf="dealerDetails.address_two != null">, {{
                                                                    dealerDetails.address_two }}</span>
                                                                <span *ngIf="dealerDetails.address_three != null">, {{
                                                                    dealerDetails.address_three }}</span>
                                                            </h6>
                                                            <small>
                                                                <span>{{ dealerDetails.city_name }}</span>
                                                                <span>, {{ dealerDetails.state_name }}</span>
                                                                <span *ngIf="dealerDetails.pincode != null"> - {{
                                                                    dealerDetails.pincode }}</span>
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" (click)="modal.close('Accept click')" rippleEffect>
                                            Close
                                        </button>
                                    </div>
                                </ng-template>
                                <!-- / Modal -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>


<!-- New User Sidebar -->
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="new-sidebar" overlayClass="modal-backdrop">
    <div class="slideout-content">
        <!-- Modal to add new starts-->
        <div class="modalsd modal-slide-in sdfade new-modal" id="modals-slide-in">
            <div class="modal-dialog">
                <form class="add-new modal-content pt-0" [formGroup]="newFormPayment" (ngSubmit)="onSubmitPayment()">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="toggleSidebar('new-sidebar')">
                        ×
                    </button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title" id="exampleModalLabel">Customer Wallet</h5>
                    </div>
                    <div class="modal-body flex-grow-1 ">
                        <div class="bs-stepper-content">
                            <!-- Checkout Place order starts -->
                            <div id="step-cart" class="content">
                                <div id="place-order" class="list-view product-checkout">
                                    <!-- Checkout Place Order Left starts -->
                                    <div class="checkout-items">
                                        <div class="row ecommerce-card">
                                            <div class="item-img col-3 col-lg-2">
                                                <div class="avatar avatar-new bg-primary avatar-lg">
                                                    <div class="avatar-content text-uppercase">{{ delerdetails.name | initials }}</div>
                                                </div>
                                            </div>
                                            <div class="card-body col-9 col-lg-10 pt-0">
                                                <div class="item-name ">
                                                    <h5 class="mb-0 ">
                                                        {{ delerdetails.name }}
                                                    </h5>
                                                    <span class="item-company">{{ delerdetails.phone }}</span>
                                                    <h6>Balance :
                                                        <span *ngIf="delerdetails.balanceType == 'CR'" class="text-success"> ₹ {{ delerdetails.current_balance |
                                                            number:'1.2-2' }} {{ delerdetails.balanceType }}</span>
                                                        <span *ngIf="delerdetails.balanceType == 'DB'" class="text-danger"> ₹ {{ delerdetails.current_balance |
                                                            number:'1.2-2' }} {{ delerdetails.balanceType }}</span>
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="form-label">Payment Choice<span class="text-danger">*</span></label>
                            <select id="payment_choice" formControlName="payment_choice" class="form-control" [ngClass]="{ 'is-invalid': submittedPayment && f.payment_choice.errors }">
                                <option value="" selected>Select Payment Choice</option>
                                <option value="Cash">Cash</option>
                                <option value="Cheque">Cheque</option>
                                <option value="Bank Transfer">Bank Transfer</option>
                                <option value="Bhim UPI">Bhim UPI</option>
                                <option value="G Pay">G Pay</option>
                                <option value="Phone Pay">Phone Pay</option>
                                <option value="Paytm">Paytm</option>
                                <option value="Cashback">Cashback</option>
                                <option value="Other">Other</option>
                            </select>
                            <div *ngIf="submittedPayment && f.payment_choice.errors" class="invalid-feedback">
                                <div *ngIf="f.payment_choice.errors.required">Payment Choice is required</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="form-label">Paying Amount<span class="text-danger">*</span></label>
                            <input type="number" id="paying_amount" formControlName="paying_amount" value="0" placeholder="Paying Amount" class="form-control" [ngClass]="{ 'is-invalid': submittedPayment && f.paying_amount.errors }">
                            <div *ngIf="submittedPayment && f.paying_amount.errors" class="invalid-feedback">
                                <div *ngIf="f.paying_amount.errors.required">Paying Amount is required</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="form-label">Remarks</label>
                            <textarea id="remarks" rows="2" formControlName="remarks" placeholder="Remarks If Any..." class="form-control"></textarea>
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn btn-primary mr-1 " rippleEffect [disabled]="loadingFrmPayment"> <span *ngIf="loadingFrmPayment"
                                    class="spinner-border spinner-border-sm mr-1 "></span>Save</button>
                            <a class="btn btn-secondary" (click)="toggleSidebar('new-sidebar')" rippleEffect>
                                <i data-feather="x" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                <span class="d-none d-sm-inline-block">Cancel</span>
                            </a>
                        </div>


                        <div class="row mt-3">
                            <div class="col-12 p-0 table-responsive">
                                <div class="overlay" *ngIf="progressPaymentTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">

                                    <tbody *ngIf="paymentList?.length != 0">
                                        <tr *ngFor="let payment of paymentList; let ndx = index">
                                            <td style="width:65px;">
                                                <img src="assets/images/payment/phone_pe.png" *ngIf=" payment.account_name == 'Phone Pay'" class="img-fluid" style="width:65px;" />
                                                <img src="assets/images/payment/g_pay.png" *ngIf=" payment.account_name == 'G Pay'" class="img-fluid" style="width:65px;" />
                                                <img src="assets/images/payment/bhim_upi.png" *ngIf=" payment.account_name == 'Bhim UPI'" class="img-fluid" style="width:65px;" />
                                                <img src="assets/images/payment/paytm.png" *ngIf=" payment.account_name == 'Paytm'" class="img-fluid" style="width:65px;" />
                                                <img src="assets/images/payment/bank_transfer.png" *ngIf=" payment.account_name == 'Bank Transfer'" class="img-fluid" style="width:65px;" />
                                                <img src="assets/images/payment/cash.png" *ngIf=" payment.account_name == 'Cash'" class="img-fluid" style="width:65px;" />
                                                <img src="assets/images/payment/rupee.png" *ngIf=" payment.account_name == 'Other'" class="img-fluid" style="width:65px;" />
                                                <img src="assets/images/payment/cheque.png" *ngIf=" payment.account_name == 'Cheque'" class="img-fluid" style="width:65px;" />
                                            </td>

                                            <td> <b> ₹ {{ payment.amount | number:'1.2-2' }} </b> <br /> {{ payment.payment_at }}<br /> {{ payment.remarks }}</td>

                                            <td>
                                                <a class="btn btn-icon btn-sm btn-xs btn-warning d-none" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Edit">
                                                    <i data-feather="edit-2"></i>
                                                </a>
                                                <a class="btn btn-icon btn-sm btn-xs btn-danger" (click)="deletePayment(payment.id)" style="margin-top:5px;" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Delete">
                                                    <i data-feather="trash"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="paymentList?.length == 0">
                                        <tr *ngIf="!loadingPayment">
                                            <td colspan="4" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" class="no-data-available">
                                                <div class="col" *ngIf="loadingPayment">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSizePayment > 5" [collectionSize]="collectionSizePayment" [pageSize]="lengthsPayment" [(page)]="pagePaymentBasicText" (pageChange)="loadPaymentPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- Modal to add new Ends-->
    </div>
</core-sidebar>
<!--/ New User Sidebar -->

<!-- Filter Sidebar -->
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="filter-sidebar" overlayClass="modal-backdrop">
    <div class="slideout-content">
        <!-- Modal to Filter starts-->
        <div class="modalsd modal-slide-in sdfade new-modal" id="modals-slide-in">
            <div class="modal-dialog">
                <form class="modal-content pt-0" [formGroup]="filterForm" (ngSubmit)="onSubmitFilter()" autocomplete="off">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="toggleSidebar('filter-sidebar')">
                        ×
                    </button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title" id="exampleModalLabel">Customer Filter</h5>
                    </div>
                    <div class="modal-body flex-grow-1 ">

                        <div class="form-group">
                            <label for="register-enterprise_name" class="form-label">Enterprise Name</label>
                            <input type="text" formControlName="enterprise_name" class="form-control" placeholder="Enterprise Name" />

                        </div>
                        <div class="form-group">
                            <label for="register-owner_name" class="form-label">Owner Name</label>
                            <input type="text" formControlName="owner_name" class="form-control" placeholder="Owner Name" />
                        </div>
                        <div class="form-group">
                            <label for="register-phone" class="form-label">Phone Number</label>
                            <input type="text" formControlName="phone" class="form-control" placeholder="Phone Number" />

                        </div>

                        <div class="form-group">
                            <label for="register-state_id" class="form-label">Select State<span
                                    class="text-danger">*</span></label>
                            <select formControlName="state_id" class="form-control" (change)="getCity($event)" placeholder="Select State">
                                <option value="" selected disabled>Select State</option>
                                <option *ngFor="let state of stateDropDown" [value]="state.id">{{state.state_name}}
                                </option>
                            </select>

                        </div>
                        <div class="form-group">
                            <label for="register-city_id" class="form-label">Select City<span
                                    class="text-danger">*</span></label>
                            <select formControlName="city_id" class="form-control" placeholder="Select City">
                                <option value="" selected>Select City</option>
                                <option *ngFor="let city of cityDropDown" [value]="city.id">
                                    {{city.city_name}}</option>
                            </select>

                        </div>

                        <div class="form-group">
                            <button type="submit" class="btn btn-primary mr-1 " rippleEffect [disabled]="loadingFilter">
                                <span *ngIf="loadingFilter "
                                    class="spinner-border spinner-border-sm mr-1 "></span>Go</button>
                            <button type="reset" class="btn btn-primary mr-1 " (click)="reloadList()" (click)="toggleSidebar('filter-sidebar')" rippleEffect [disabled]="loadingFilter">
                                <span *ngIf="loading " class="spinner-border spinner-border-sm mr-1 "></span>
                                <i data-feather="refresh-cw" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                <span class="d-none d-sm-inline-block">Reset</span>
                            </button>
                            <a class="btn btn-secondary" (click)="toggleSidebar('filter-sidebar')" rippleEffect>
                                <i data-feather="x" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                <span class="d-none d-sm-inline-block">Cancel</span>
                            </a>
                        </div>

                    </div>
                </form>
            </div>
        </div>
        <!-- Modal to filter Ends-->
    </div>
</core-sidebar>
<!--/ Filter Sidebar -->