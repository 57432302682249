import { Component, OnInit, ViewChild, Injectable } from '@angular/core';
import { ProductInwardService } from 'app/_services/product-inward.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';



 /**
  * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
  */
 @Injectable()
 export class CustomDateParserFormatter extends NgbDateParserFormatter {
   readonly DELIMITER = '/';
 
   parse(value: string): NgbDateStruct | null {
     if (value) {
       const date = value.split(this.DELIMITER);
       return {
         day: parseInt(date[0], 10),
         month: parseInt(date[1], 10),
         year: parseInt(date[2], 10),
       };
     }
     return null;
   }
 
   format(date: NgbDateStruct | null): string {
     return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
   }
 }


@Component({
  selector: 'app-product-inward-add',
  templateUrl: './product-inward-add.component.html',
  styleUrls: ['./product-inward-add.component.scss'],

  providers: [

		{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
	],
})
export class ProductInwardAddComponent implements OnInit {

  @ViewChild('auto') auto: any;
  public placeholder: string = 'Search Product by Name';
  public keyword = 'name';
  public isLoadings: boolean = false;
  public selectBasicLoading = false;
  public isLoadingSubmit: Boolean = false;
  public AisLoading: boolean = false;

  public inwardId: any;
  public date: any;
  public remarks: any;
  // public setQuantity: any;
  public inwardFormSubmit: Boolean = false;
  public addInwardFormSubmit: Boolean = false;
  public isAddMode: Boolean = true;
  public isLoading: Boolean = true;

  public inward_details: any = [];
  public allProduct: any;
  public product: any ;
  public inwardForm: any;
  public addInwardForm: any;


  public today = new Date();
  public getDate = String(this.today. getDate()). padStart(2, '0');
  public getMonth = String(this.today. getMonth() + 1). padStart(2, '0'); //January is 0!
  public getYear = this.today. getFullYear();

  public maxDate = Number(this.getDate);
  public maxMonth = Number(this.getMonth);
  public maxYear = Number(this.getYear);
  public basicDPdata: NgbDateStruct = {day: this.maxDate, month: this.maxMonth, year: this.maxYear};
  
  public warehouseDropDown: any = [];

  constructor(private _fb: FormBuilder, private toastr: ToastrService, private productInwardService: ProductInwardService, private route: ActivatedRoute, private router: Router) { }


  ngOnInit(): void {
    this.getwarehouseDropDown();

    this.inwardForm = this._fb.group({
      warehouse_id: ['', Validators.required],
      date: ['', Validators.required],
      remarks: ['']
    });

    this.addInwardForm = this._fb.group({
      quantity: ['', Validators.required]
    });

    this.inwardId = this.route.snapshot.params['id'];
    this.isAddMode = !this.inwardId;
    this.isLoading = false;
    if (!this.isAddMode) {
      this.Edit(this.inwardId)
    }
  }

  get inwardFormControl() {
    return this.inwardForm.controls;
  }

  get addInwardFormControl() {
    return this.addInwardForm.controls;
  }

  onChangeSearch(value: string) {
    this.AisLoading = true;
    if (value != '') {
      this.productInwardService.productSearch(value).subscribe(
        data => {
          if (data.status) {
            this.AisLoading = false
            this.allProduct = data.product_data;
          }
          else {
            this.toastr.error(data.message, 'Error!');
          }
        },
        err => {
          this.toastr.error(err.message, 'Opps!');
        }
      );
    } else {
      this.toastr.warning('Enter Product Name', 'Warning!');
      setInterval(() => {
        this.AisLoading = false
      }, 3000);
    }
  }

  onFocused(e: any) {
    //console.log(e);
    // do something when input is focused
  }

  selectEvent(item: any) {
    this.product = {};
    if (
      this.inward_details.length > 0 &&
      this.inward_details.some((detail: { product_id: any, product_variant_id :any }) => detail.product_id === item.id && detail.product_variant_id  === item.product_variant_id )) {
      this.toastr.error('Product Already Added', 'Opps!');
    } else {
      this.product.id = 0,
      this.product.product_id = item.id;
      this.product.product_variant_id  = item.product_variant_id ;
      this.product.name = item.name;
      this.product.product_photo = item.product_photo;

    }
  }

  clear(): void {
    this.auto.clear();
  }

  closePanel(): void {
    this.auto.close();
  }

  addInwardSubmit() {
    this.addInwardFormSubmit = true;
    if(this.product != undefined && this.product.product_id != undefined){
      if (
        this.inward_details.length > 0 &&
        this.inward_details.some((detail: { product_id: any, product_variant_id :any }) => detail.product_id ===  this.product.product_id  && detail.product_variant_id  ===  this.product.product_variant_id )) {
        this.toastr.error('Product Already Added', 'Opps!');
      } else {
        if (this.addInwardForm.valid) {
          this.addInwardFormSubmit = false;
          if (this.inward_details.length > 0) {
            this.Last_Detail_id();
          } else if (this.inward_details.length === 0) {
            this.product.detail_id = 1;
          }
          this.product.quantity = this.addInwardForm.controls.quantity.value;
          this.addInwardForm.reset();
          this.inward_details.push(this.product);
          this.clear();
          this.closePanel();
          this.product = {};
        }
      }
    }else{
      this.toastr.warning('Enter Product Name', 'Warning!');
    }
    
  }

  delete_Product_Detail(id: any) {
    for (var i = 0; i < this.inward_details.length; i++) {
      if (id === this.inward_details[i].detail_id) {
        this.inward_details.splice(i, 1);
      }
    }
  }

  Last_Detail_id() {
    this.product.detail_id = 0;
    var len = this.inward_details.length;
    this.product.detail_id = this.inward_details[len - 1].detail_id + 1;
  }

  countSubChange(id:any, event:any) {
    for (var i = 0; i < this.inward_details.length; i++) {
      if (this.inward_details[i].detail_id === id) {
          this.inward_details[i].quantity = event;
      }
    }
  }

  verifiedForm() {
    if (this.inward_details.length <= 0) {
      this.toastr.error('Please Add Product To List !!', 'Opps!');
      return false;
    } else {
      var count = 0;
      for (var i = 0; i < this.inward_details.length; i++) {
        if (
          this.inward_details[i].quantity === "") {
          count += 1;
        }
      }
      if (count > 0) {
        this.toastr.warning('Please Fill All Value', 'Warning!')
        return false;
      } else {
        return true;
      }
    }
  }

  inwardSubmit() {
    let id;
    this.inwardFormSubmit = true;
    if(this.inwardForm.valid) {
      this.isLoadingSubmit = true;
      if(this.verifiedForm()) {
        if (this.inwardId !== undefined) {
          id = this.inwardId;
        }else {
          id = '';
        }
        let require_date = this.inwardForm.controls.date.value['year']+'-'+ this.inwardForm.controls.date.value['month']+'-'+this.inwardForm.controls.date.value['day'];
        this.productInwardService.add(require_date, this.inwardForm.controls.remarks.value, this.inwardForm.controls.warehouse_id.value, this.inward_details, id).subscribe(
          data => {           
            if (data.status) {
              this.isLoadingSubmit = false;
              this.router.navigate(['/products-inward'], { relativeTo: this.route });
              this.toastr.success(data.message, 'Success!');
              this.inwardId = '';
              this.addInwardForm.reset();
            }
            else {
              this.toastr.error(data.errorMessage, 'Error!');
            }
          },
          err => {
            this.toastr.error(err.errorMessage, 'Opps!');
          }
        );
      }else{
        this.isLoadingSubmit = false;
      }
    }else{
      this.isLoadingSubmit = false;
    }
  }

  Edit(id: any) {
    this.isLoading = true;
    this.productInwardService.edit(id).subscribe(
      data => {
        if (data.status) {
          this.isLoading = false;
          const [year, month, day] = data.inward.date.split('-');
          const obj = { year: parseInt(year), month: parseInt(month), day: 
            parseInt(day.split(' ')[0].trim()) };
            
          this.inwardForm.setValue({
            date:  {day: parseInt(day), month: parseInt(month), year: parseInt(year)},
            remarks: data.inward.remarks,
            warehouse_id: data.inward.warehouse_id,
          });

          this.inwardId = data.inward.id,
          this.inward_details = data.inward_details;
        } else {
          this.toastr.error(data.errorMessage, 'Error!');
        }
      },
      err => {
        this.toastr.error(err.errorMessage, 'Opps!');
      }
    );
  }

  getwarehouseDropDown() {
    this.productInwardService.getwarehouseDropDown().subscribe(
      data => {
        if (data.status) {
          this.warehouseDropDown = data.categories;
        }
      });
  }
}
