<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Supplier List</h4>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-6 col-12">
                                    <div class="d-flex justify-content-between align-items-center m-1">
                                        <label class="d-flex align-items-center">Show
                                      <select class="form-control mx-25" [(ngModel)]="lengths" (change)="loadPageLenghs($event)">
                                        <option value="15">15</option>
                                        <option value="30">30</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                      </select>
                                      entries</label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-12 d-flex justify-content-start justify-content-md-end">

                                </div>
                            </div>
                            <div class="col p-0 table-responsive">
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Enterprise Name</th>
                                            <th>Owner Detail</th>
                                            <th>City / State</th>
                                            <th>Status</th>
                                            <th>Register At</th>
                                            <th>Expiry Date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="manufacturersList?.length != 0">
                                        <tr *ngFor="let  manufacturer of manufacturersList; let ndx = index">
                                            <td *ngIf="myDate <= manufacturer.expire_date_original" class="bg-success">{{ndx+page+1}}</td>
                                            <td *ngIf="myDate > manufacturer.expire_date_original" class="bg-danger">{{ndx+page+1}}</td>
                                            <td>{{ manufacturer.name }}</td>
                                            <td>{{ manufacturer.owner_name }} <br /> {{ manufacturer.phone }}</td>
                                            <td>{{ manufacturer.city_name }} / {{ manufacturer.state_name }}</td>
                                            <td *ngIf="myDate <= manufacturer.expire_date_original">
                                                <div ngbDropdown class="btn-group" *ngIf="manufacturer.status == 0">
                                                    <button type="button" class="btn btn-outline-success btn-sm" rippleEffect>Active</button>
                                                    <button ngbDropdownToggle type="button" class="btn btn-outline-success dropdown-toggle-split btn-sm" rippleEffect>
                                                      <span class="sr-only">Toggle Dropdown</span>
                                                    </button>
                                                    <div ngbDropdownMenu>
                                                        <a ngbDropdownItem href="javascript:void(0)" (click)="changeStatus(1,manufacturer.id)">Deactivate</a>
                                                        <a ngbDropdownItem href="javascript:void(0)" (click)="changeStatus(2,manufacturer.id)">Block</a>
                                                    </div>
                                                </div>

                                                <div ngbDropdown class="btn-group" *ngIf="manufacturer.status == 1">
                                                    <button type="button" class="btn btn-outline-warning btn-sm" rippleEffect>Deactivate</button>
                                                    <button ngbDropdownToggle type="button" class="btn btn-outline-warning dropdown-toggle-split btn-sm" rippleEffect>
                                                      <span class="sr-only">Toggle Dropdown</span>
                                                    </button>
                                                    <div ngbDropdownMenu>
                                                        <a ngbDropdownItem href="javascript:void(0)" (click)="changeStatus(0,manufacturer.id)">Active</a>
                                                        <a ngbDropdownItem href="javascript:void(0)" (click)="changeStatus(2,manufacturer.id)">Block</a>
                                                    </div>
                                                </div>

                                                <div ngbDropdown class="btn-group" *ngIf="manufacturer.status == 2">
                                                    <button type="button" class="btn btn-outline-danger btn-sm" rippleEffect>Block</button>
                                                    <button ngbDropdownToggle type="button" class="btn btn-outline-danger dropdown-toggle-split btn-sm" rippleEffect>
                                                      <span class="sr-only">Toggle Dropdown</span>
                                                    </button>
                                                    <div ngbDropdownMenu>
                                                        <a ngbDropdownItem href="javascript:void(0)" (click)="changeStatus(0,manufacturer.id)">Active</a>
                                                        <a ngbDropdownItem href="javascript:void(0)" (click)="changeStatus(1,manufacturer.id)">Deactivate</a>
                                                    </div>
                                                </div>

                                            </td>
                                            <td *ngIf="myDate > manufacturer.expire_date_original" class="text-danger">Deactivated</td>
                                            <td>{{ manufacturer.register_date }}</td>

                                            <td *ngIf="myDate <= manufacturer.expire_date_original" class="text-success"> {{ manufacturer.expire_date }}</td>
                                            <td *ngIf="myDate > manufacturer.expire_date_original" class="text-danger"> {{ manufacturer.expire_date }}</td>

                                            <td>
                                                <a data-toggle="tooltip" class="btn btn-icon btn-sm btn-primary" (click)="modalOpen(modalBasic,manufacturer.id)" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Full Details">
                                                    <i data-feather="eye"></i>
                                                </a>

                                                &nbsp;
                                                <a (click)="delete(manufacturer.id)" class="btn btn-icon btn-sm btn-danger" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Delete">
                                                    <i data-feather="trash"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="manufacturersList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="7" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="7" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSize > 0" [collectionSize]="collectionSize" [pageSize]="lengths" [(page)]="pageBasicText" (pageChange)="loadPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination>

                                <!-- Modal -->
                                <ng-template #modalBasic let-modal>
                                    <div class="modal-header">
                                        <h4 class="modal-title" id="myModalLabel1" *ngIf="manufacturerDetails?.length != 0">{{ manufacturerDetails.name }} </h4>
                                        <h4 class="modal-title" id="myModalLabel1" *ngIf="manufacturerDetails?.length == 0">Supplier Details</h4>
                                        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body" tabindex="0" ngbAutofocus>
                                        <div class="col" *ngIf="manufacturerDetails?.length == 0">
                                            <div class="text-center">
                                                <div class="spinner-border" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <div>
                                                    Please Wait...
                                                </div>
                                            </div>
                                        </div>
                                        <div class="" *ngIf="manufacturerDetails?.length != 0">
                                            <div class="card-developer-meetup">

                                                <div class="card-body">
                                                    <div class="meetup-header d-flex align-items-center">
                                                        <div class="meetup-day">
                                                            <div class="avatar bg-primary avatar-lg">
                                                                <div class="avatar-content">{{ manufacturerDetails.name.substring(0, 1) }}</div>
                                                            </div>
                                                        </div>
                                                        <div class="my-auto">
                                                            <h4 class="card-title mb-25">{{ manufacturerDetails.name }}</h4>
                                                            <p class="card-text mb-0">{{ manufacturerDetails.owner_name }}</p>
                                                        </div>
                                                    </div>
                                                    <div class="media">
                                                        <div class="avatar bg-light-primary rounded mr-1">
                                                            <div class="avatar-content">
                                                                <i data-feather="phone" class="avatar-icon font-medium-3"></i>
                                                            </div>
                                                        </div>
                                                        <div class="media-body">
                                                            <h6 class="mb-0">{{ manufacturerDetails.phone }}</h6>
                                                            <small *ngIf="manufacturerDetails.alternate_phone != null">{{ manufacturerDetails.alternate_phone }}</small>
                                                            <small *ngIf="manufacturerDetails.alternate_phone == null"></small>
                                                        </div>
                                                    </div>
                                                    <div class="media">
                                                        <div class="avatar bg-light-primary rounded mr-1">
                                                            <div class="avatar-content">
                                                                <i data-feather="mail" class="avatar-icon font-medium-3"></i>
                                                            </div>
                                                        </div>
                                                        <div class="media-body">
                                                            <h6 class="mb-0" style="padding-top:7px;">{{ manufacturerDetails.email }}</h6>
                                                        </div>
                                                    </div>
                                                    <div class="media" *ngIf="manufacturerDetails.gst_no != null">
                                                        <div class="avatar bg-light-primary rounded mr-1">
                                                            <div class="avatar-content">
                                                                <i data-feather="percent" class="avatar-icon font-medium-3"></i>
                                                            </div>
                                                        </div>
                                                        <div class="media-body">
                                                            <h6 class="mb-0" style="padding-top:7px;">GST : {{ manufacturerDetails.gst_no }}</h6>
                                                        </div>
                                                    </div>
                                                    <div class="media">
                                                        <div class="avatar bg-light-primary rounded mr-1">
                                                            <div class="avatar-content">
                                                                <i data-feather="map-pin" class="avatar-icon font-medium-3"></i>
                                                            </div>
                                                        </div>
                                                        <div class="media-body">
                                                            <h6 class="mb-0">
                                                                <span *ngIf="manufacturerDetails.address_one != null">{{ manufacturerDetails.address_one }}</span>
                                                                <span *ngIf="manufacturerDetails.address_two != null">, {{ manufacturerDetails.address_two }}</span>
                                                                <span *ngIf="manufacturerDetails.address_three != null">, {{ manufacturerDetails.address_three }}</span>
                                                            </h6>
                                                            <small> 
                                                                <span>{{ manufacturerDetails.city_name }}</span>
                                                                <span>, {{ manufacturerDetails.state_name }}</span>
                                                                <span *ngIf="manufacturerDetails.pincode != null"> - {{ manufacturerDetails.pincode }}</span>
                                                            </small>

                                                        </div>

                                                    </div>

                                                    <div class="media">
                                                        <div class="avatar bg-light-primary rounded mr-1">
                                                            <div class="avatar-content">
                                                                <i data-feather="calendar" class="avatar-icon font-medium-3"></i>
                                                            </div>
                                                        </div>
                                                        <div class="media-body">
                                                            <h6 class="mb-0">R : {{ manufacturerDetails.register_date }}</h6>
                                                            <h6 class="mb-0">E : {{ manufacturerDetails.expire_date }}</h6>
                                                        </div>
                                                    </div>

                                                    <hr class="mb-2" />
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <h6 class="font-weight-bolder">{{ manufacturerDetails.totalCategory }}</h6>
                                                            <h3 class="text-muted  mb-0"><i data-feather="tag"></i></h3>
                                                        </div>
                                                        <div>
                                                            <h6 class="font-weight-bolder">{{ manufacturerDetails.totalProduct }}</h6>
                                                            <h3 class="text-muted  mb-0"><i data-feather="box"></i></h3>
                                                        </div>
                                                        <div>
                                                            <h6 class="font-weight-bolder">{{ manufacturerDetails.totalDealer }}</h6>
                                                            <h3 class="text-muted  mb-0"><i data-feather="users"></i></h3>
                                                        </div>
                                                        <div>
                                                            <h6 class="font-weight-bolder">{{ manufacturerDetails.totalOrder }}</h6>
                                                            <h3 class="text-muted  mb-0"><i data-feather="shopping-cart"></i></h3>
                                                        </div>
                                                        <div>
                                                            <h6 class="font-weight-bolder">{{ manufacturerDetails.totalChallan }}</h6>
                                                            <h3 class="text-muted  mb-0"><i data-feather="truck"></i></h3>
                                                        </div>
                                                        <div>
                                                            <h6 class="font-weight-bolder">{{ manufacturerDetails.total_sms }}</h6>
                                                            <h3 class="text-muted  mb-0"><i data-feather="twitch"></i></h3>
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-primary" (click)="modal.close('Accept click')" rippleEffect>
                                            Close
                                        </button>
                                    </div>
                                </ng-template>
                                <!-- / Modal -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>