import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    translate: 'MENU.HOME',
    type: 'item',
    role: ['Client', 'Admin'],
    icon: 'home',
    url: 'home'
  },

  {
    id: 'master',
    title: 'Master',
    translate: 'MENU.MASTER',
    type: 'collapsible',
    role: ['Client'],
    icon: 'box',
    badge: {
      title: '5',
      translate: 'MENU.MASTER',
      classes: 'badge-light-warning badge-pill'
    },
    children: [
      {
        id: 'categories',
        title: 'Categories',
        translate: 'MENU.CATEGORIES',
        type: 'item',
        role: ['Client'],
        icon: 'tag',
        url: 'categories'
      },
      {
        id: 'products',
        title: 'Products',
        translate: 'MENU.PRODUCTS',
        type: 'item',
        role: ['Client'],
        icon: 'box',
        url: 'products'
      },
      {
        id: 'stock-inward',
        title: 'Stock Inward',
        translate: 'MENU.INWARD',
        type: 'item',
        role: ['Client'],
        icon: 'box',
        url: 'products-inward'
      },
      {
        id: 'warehouse',
        title: 'Warehouse',
        translate: 'MENU.WAREHOUSE',
        type: 'item',
        role: ['Client'],
        icon: 'box',
        url: 'warehouse'
      },
      {
        id: 'transport',
        title: 'Transport',
        translate: 'MENU.TRANSPORT',
        type: 'item',
        role: ['Client'],
        icon: 'box',
        url: 'transport'
      },
      {
        id: 'slider',
        title: 'Slider',
        translate: 'MENU.SLIDER',
        type: 'item',
        role: ['Client'],
        icon: 'box',
        url: 'slider'
      },
      // {
      //   id: 'sales_executive',
      //   title: 'Sales Executive',
      //   translate: 'MENU.EMPLOYEES',
      //   type: 'item',
      //   role: ['Client'],
      //   icon: 'users',
      //   url: 'employees'
      // },
      // {
      //   id: 'price_per_customer',
      //   title: 'Price Per Customers',
      //   translate: 'MENU.PRICEPERCUSTOMER',
      //   type: 'item',
      //   role: ['Client'],
      //   icon: 'list',
      //   url: 'price-per-customer'
      // }
    ]
  },
  {
    id: 'expense_master',
    title: 'Manage Expense',
    translate: 'MENU.EXPENSE',
    type: 'collapsible',
    role: ['Client'],
    icon: 'box',
    badge: {
      title: '2',
      translate: 'MENU.EXPENSE',
      classes: 'badge-light-warning badge-pill'
    },
    children: [
      {
        id: 'expense_category',
        title: 'Expense Category',
        translate: 'MENU.EXPENSE_CATEGORY',
        type: 'item',
        role: ['Client'],
        icon: 'tag',
        url: 'expense-category'
      },
      {
        id: 'expense',
        title: 'Expense',
        translate: 'MENU.EXPENSE',
        type: 'item',
        role: ['Client'],
        icon: 'shopping-bag',
        url: 'expenses'
      }
    ]
  },
  {
    id: 'dealers',
    title: 'Customers',
    translate: 'MENU.DEALERS',
    type: 'item',
    role: ['Client'],
    icon: 'users',
    url: 'dealers'
  },

  
  {
    id: 'orders',
    title: 'Orders',
    translate: 'MENU.ORDERS',
    type: 'item',
    role: ['Client', 'User'],
    icon: 'shopping-cart',
    url: 'orders'
  },

  {
    id: 'delivery_challan',
    title: 'Delivery Challan',
    translate: 'MENU.DELIVERYCHALLAN',
    type: 'collapsible',
    role: ['Client'],
    icon: 'truck',
    badge: {
      title: '2',
      translate: 'MENU.DELIVERYCHALLAN',
      classes: 'badge-light-warning badge-pill'
    },
    children: [

  {
    id: 'delivery_challan',
    title: 'Delivery Challan',
    translate: 'MENU.DELIVERYCHALLAN',
    type: 'item',
    role: ['Client'],
    icon: 'truck',
    url: 'delivery-challan'
  },
  {
    id: 'delivery_challan_reached',
    title: 'Delivery Challan Reached',
    translate: 'MENU.DELIVERYCHALLANREACHED',
    type: 'item',
    role: ['Client'],
    icon: 'truck',
    url: 'delivery-challan-reached'
  }
]
},
 

  {
    id: 'reports',
    title: 'Reports',
    translate: 'MENU.REPORTS',
    type: 'collapsible',
    role: ['Client'],
    icon: 'file',
    badge: {
      title: '3',
      translate: 'MENU.REPORTS',
      classes: 'badge-light-warning badge-pill'
    },
    children: [
      {
        id: 'order_reports',
        title: 'Order Reports',
        translate: 'MENU.ORDER_REPORTS',
        type: 'item',
        icon: 'file',
        url: 'reports'
      },
      {
        id: 'challan_reports',
        title: 'Challan Reports',
        translate: 'MENU.CHALLAN_REPORTS',
        type: 'item',
        icon: 'file',
        url: 'challan-reports'
      },
      {
        id: 'outstanding_report',
        title: 'Customer Wallet',
        translate: 'MENU.OUTSTANDING_REPORT',
        type: 'item',
        icon: 'file',
        url: 'outstanding-report'
      },
      {
        id: 'expense_report',
        title: 'Expense Report',
        translate: 'MENU.EXPENSE_REPORT',
        type: 'item',
        icon: 'file',
        url: 'expense-report'
      }
    ]
  },
  {
    id: 'promocode',
    title: 'Promocode',
    translate: 'MENU.PROMOCODE',
    type: 'item',
    role: ['Client'],
    icon: 'gift',
    url: 'promocode'
  },
  // {
  //   id: 'Supplier',
  //   title: 'Supplier',
  //   translate: 'MENU.MANUFACTURERS',
  //   type: 'item',
  //   role: ['Admin'],
  //   icon: 'users',
  //   url: 'manufacturers'
  // },
  // {
  //   id: 'units',
  //   title: 'Units',
  //   translate: 'MENU.UNIT',
  //   type: 'item',
  //   role: ['Admin'],
  //   icon: 'move',
  //   url: 'units'
  // },

]
