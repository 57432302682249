<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Stock Inward List</h4>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-3 col-6">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                        <select class="form-control mx-25" [(ngModel)]="lengths" (change)="loadPageLengths($event)">
                                            <option value="15">15</option>
                                            <option value="30">30</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                      </label>
                                    </div>
                                </div>
                                <div class="col-md-5 col-6">
                                    <div class="d-flex align-items-center serach justify-content-start justify-content-md-end pr-1 pb-1 pb-md-0 w-100">
                                        <ng-autocomplete #auto #ngAutoCompleteStatic [data]="allProduct" [initialValue]="" [minQueryLength]="2" placeholder="Report By Product Name" [searchKeyword]="keyword" notFoundText="Not found" (selected)='selectEvent($event)' (inputChanged)='onChangeSearch($event)'
                                            [itemTemplate]="itemTemplateStatic" [isLoading]="AisLoading" [notFoundTemplate]="notFoundTemplate" (inputCleared)='closedStatic()'>
                                        </ng-autocomplete>
                                        <ng-template #itemTemplateStatic let-item>
                                            <a [innerHTML]="item.name"></a>
                                        </ng-template>
                                        <ng-template #notFoundTemplate let-notFound>
                                            <div [innerHTML]="notFound"></div>
                                        </ng-template>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12 d-flex justify-content-start justify-content-md-end pb-1">
                                    <div class="align-items-center justify-content-end pb-1 pb-md-0">
                                        <button class="btn btn-primary ml-1 float-right" routerLink="/products-inward/add" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Add New Stock">
                                            <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Add Stock</span>
                                        </button>
                                    </div>
                                    <!-- <div class="align-items-center justify-content-end pb-1 pb-md-0">
                                        <button class="btn btn-success ml-1" rippleEffect (click)="modalOpen(modalBasic)" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Upload Multiple Stock">
                                            <i data-feather="upload" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Excel Upload</span>
                                        </button>
                                    </div> -->
                                </div>
                            </div>
                            <div class="col p-0 table-responsive" *ngIf="listInward">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th> Warehouse name</th>
                                            <th> Date</th>
                                            <th> Batch</th>
                                            <th> Remarks</th>
                                            <th> Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="productsInwardList?.length != 0">
                                        <tr *ngFor="let pi of productsInwardList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td>{{ pi.warehouse_name }}</td>
                                            <td>{{ pi.date }}</td>
                                            <td>{{ pi.batch }}</td>
                                            <td>{{ pi.remarks }}</td>
                                            <td class="mr-1">
                                                <button type="button" routerLink="/products-inward/edit/{{ pi.id }}" class="btn btn-icon btn-sm btn-info mr-1" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Edit">
                                                    <i data-feather="edit-2"></i>
                                                </button>
                                                <button type="button" (click)="deleteInward(pi.id)" class="btn btn-icon btn-sm btn-danger mr-1" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Delete">
                                                    <i data-feather="trash"></i>
                                                </button>
                                                <button type="button" (click)="details(pi.id,modalSM)" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="View" class="btn btn-icon btn-sm btn-warning mr-1" rippleEffect>
                                                    <i data-feather="eye"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="productsInwardList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="5" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="5" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSize > 0" [collectionSize]="collectionSize" [pageSize]="lengths" [(reportPage)]="pageBasicText" (pageChange)="loadPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination>
                            </div>
                            <!---report start--->
                            <div class="col p-0 table-responsive" *ngIf="listInwardReport">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th> Date</th>
                                            <th> Batch</th>
                                            <th> Remarks</th>
                                            <th> Product</th>
                                            <th> Previous Quantity</th>
                                            <th> Quantity</th>
                                            <th> Total Quantity</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="productsInwardReportList?.length != 0">
                                        <tr *ngFor="let pri of productsInwardReportList; let ndx = index">
                                            <td>{{ndx+reportPage+1}}</td>
                                            <td>{{ pri.date }}</td>
                                            <td>{{ pri.batch }}</td>
                                            <td>{{ pri.remarks }}</td>
                                            <td>
                                                <div style="width:45px;float:left;">
                                                    <img src="assets/images/no-img.png" *ngIf="pri.product_photo == ''" height="40" width="40" alt="IMG" class="rounded mr-75">
                                                    <img [src]="pri.product_photo" *ngIf="pri.product_photo != ''" height="40" width="40" alt="IMG" class="rounded mr-75">
                                                </div>
                                                <div style="float:left;">
                                                    <div class="font-small-2 badge badge-light-primary">{{ pri.name }} </div>
                                                </div>
                                            </td>
                                            <td class="text-center">{{ pri.previous_qty}}</td>
                                            <td class="text-center">{{ pri.quantity}}</td>
                                            <td class="text-center">{{ pri.total_qty}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="productsInwardReportList?.length == 0">
                                        <tr *ngIf="!loading" class="text-center">
                                            <td colspan="9" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="9" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="reportCollectionSize > 0" [collectionSize]="reportCollectionSize" [pageSize]="reportLengths" [(page)]="reportPageBasicText" (pageChange)="reportLoadPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination>
                            </div>
                            <!---report end--->
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Modal -->
        <ng-template #modalSM let-modal>
            <div class="overlay" *ngIf="isModelLoading">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="modal-header">
                <h5 class="modal-title w-100" id="myModalLabel160">
                    <span class="float-left"> <b>Batch Number</b> &nbsp;<span  class="badge badge-info">#{{inward?.inward_batch}}</span ></span>
                    <span class="float-right"> <span class="badge badge-glow badge-primary">View Inward Details</span></span>
                </h5>
                <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" tabindex="0" ngbAutofocus>
                <div class="row">
                    <div class="table-responsive m-t-40">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="text-center">#</th>
                                    <th class="text-center">Image</th>
                                    <th class="text-center">Name</th>
                                    <th class="text-center">Previous Quantity</th>
                                    <th class="text-center">Quantity</th>
                                    <th class="text-center">Total Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let ind of inwardDetails; let i = index">
                                    <td class="text-center">{{ i + 1 }}</td>
                                    <td class="text-center">
                                        <img src="assets/images/no-img.png" *ngIf="ind.product_photo == ''" height="40" width="40" alt="IMG" class="rounded mr-75">
                                        <img [src]="ind.product_photo" *ngIf="ind.product_photo != ''" height="40" width="40" alt="IMG" class="rounded mr-75">
                                    </td>
                                    <td class="text-center">{{ ind.name}}</td>
                                    <td class="text-center">{{ ind.previous_qty}}</td>
                                    <td class="text-center">{{ ind.quantity}}</td>
                                    <td class="text-center">{{ ind.total_qty}}</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="inwardDetails?.length == 0">
                                <tr>
                                    <td colspan="4" class="no-data-available">No data!</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </ng-template>
        <!-- / Modal -->
        <!-- Excel Modal -->
        <ng-template #modalBasic let-modal>
            <div class="modal-header">
                <h4 class="modal-title" id="myModalLabel1">Upload Inward Using Excel </h4>
                <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body  overflow-auto" tabindex="0" ngbAutofocus>
                <div class="row">
                    <div class="card-body">
                        <form method="post" [formGroup]="newForm" (ngSubmit)="onSubmit()" autocomplete="off">
                            <fieldset class="form-group">
                                <label for="file-upload-single" class="form-label">Upload Excel File:</label>
                                <div class="custom-file">
                                    <input type="file" formControlName="products" class="custom-file-input" [ngClass]="{ 'is-invalid': submitted && f.products.errors }" (change)="uploadFile($event)" type="file" id="file-upload-single" />
                                    <label class="custom-file-label">Choose file</label>
                                </div>
                                <div *ngIf="submitted && f.products.errors" class="invalid-feedback">
                                    <div *ngIf="f.products.errors.required">Please Upload File</div>
                                </div>
                            </fieldset>
                            <fieldset class="form-group" *ngIf="product_selected_name != ''">
                                <b>File : </b> {{ product_selected_name }} <br/> <b> Size : </b> {{ product_selected_size | number: '.2'}} MB
                            </fieldset>
                            <div class="form-group" *ngIf="error">
                                <ngb-alert [type]="'danger'" [dismissible]="false">
                                    <div class="alert-body">
                                        <p>{{ error }}</p>
                                    </div>
                                </ngb-alert>
                            </div>
                            <div class="form-group">
                                <button type="submit" [disabled]="loadingFrm" class="btn btn-primary" tabindex="14" rippleEffect>
                                    <span *ngIf="loadingFrm" class="spinner-border spinner-border-sm mr-1"></span>
                                    <i data-feather="upload" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                    Upload
                                </button>
                                <button type="button" (click)="download(id)" class="pull-right btn btn-info" rippleEffect>
                                    <i data-feather="download" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                    <span class="d-none d-sm-inline-block">Download </span> Product
                                </button>
                            </div>
                        </form>
                    </div>
                    <div class="card-body">
                        <table class="table table-hover table-responsive table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <td>
                                        <div class="badge badge-light-success">
                                            This Field is Required
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Product code</th>
                                    <td>
                                        <div class="badge badge-light-success">
                                            This Field is Required
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Product Name</th>
                                    <td>
                                        <div class="badge badge-light-success">
                                            This Field is Required
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Quantity</th>
                                    <td>
                                        <div class="badge badge-light-success">
                                            This Field is Required
                                        </div>
                                    </td>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="modal.close('Accept click')" rippleEffect>
                    Close
                </button>
            </div>
        </ng-template>
        <!-- / Modal -->
    </div>
</div>